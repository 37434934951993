import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../components/button";
import Modal from "../components/modal";

const CountdownTimeModal = ({ isOpen, onClose, onSubmit, setCountdownTime, message }) => {
  const [ hour, setHour ] = useState(0);
  const [ minute, setMinute ] = useState(0);
  const [ second, setSecond ] = useState(0);

  const onHour = (event) => {
    console.log(event.target.value);
    setHour(event.target.value);
  };

  const onMinute = (event) => {
    console.log(event.target.value);
    setMinute(event.target.value);
  };

  const onSecond = (event) => {
    console.log(event.target.value);
    setSecond(event.target.value);
  };

  const onHandleCountdown = (event) => {
    setCountdownTime(Number(hour) * 3600 + Number(minute) * 60 + Number(second));
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-1/4">
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">Countdown Time</h2>
      </div>
      {message.length === 0 && <div className="p-4 text-gray-100">
        {/* <InputField id="name" label="Name" ref={register} required /> */}
        <input
          className="text-black"
          value={hour}
          onChange={onHour}
          min={0}
          max={24}
          type="number"
          style={{
            margin: '10px',
            borderRadius: '3px',
          }}
        />:
        <input
          className="text-black"
          value={minute}
          onChange={onMinute}
          min={0}
          max={59}
          type="number"
          style={{
            margin: '10px',
            borderRadius: '3px',
          }}
        />:
        <input
          className="text-black"
          value={second}
          onChange={onSecond}
          min={0}
          max={59}
          type="number"
          style={{
            margin: '10px',
            borderRadius: '3px',
          }}
        />
        <div className="flex flex-row-reverse mt-4">
          <Button
            onClick={onHandleCountdown}
            type="submit"
            className="rounded bg-gray-500 text-gray-100 hover:bg-gray-400 text-lg w-20 h-8"
          >
            Set
          </Button>
        </div>
      </div>}
      {message &&
        <div style={{ minHeight: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p className="text-gray-100" style={{ fontSize: '25px' }}>{message}</p>
        </div>
      }
    </Modal>
  );
};

export default CountdownTimeModal;
