import React, { useContext, useState, useMemo } from "react";
import InviteLink from "./inviteLink";
import Scenes from "./scenes";
import Broadcast from './broadcast';
import VirtualBackgroundModal from "../../features/virtualBackgroundModal";
import { useFirebase } from "../../helpers/hooks/useFirebase";
import { useCollectionQuery } from "../../helpers/hooks/useFirebaseCollection";
import { firebaseUpload } from "../../helpers/firebaseUpload";
import { nanoid } from "nanoid";
import { AuthContext } from "../../helpers/context/authContext";
import { ReactComponent as ArrowDown } from "../../icons/chevron-down.svg";

const HeaderQuickControl = ({conferenceId}) => {
	const [isVirtualBackgroundModal, setIsVirtualBackgroundModal] = useState();

	const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);

	const virtualBackgroundRef = firestore.collection('virtualBackgrounds');

	const [virtualBackgrounds] = useCollectionQuery(virtualBackgroundRef, {
    idField: "id",
  });
  
	const { user } = useContext(AuthContext);

	const addNewVirtualBackground = async ({ img, isCustomRatio }) => {
    const file = img?.[0];
		if (!file) return;

    const val = await firebaseUpload(firebase)({
      name: file.name,
      file,
    });

    const url = await val.ref.getDownloadURL();
		const id = nanoid();

    virtualBackgroundRef.doc(id).set({
      id,
      userId: user?.uid,
			url,
    });
  };

	const deleteVirtualBackground = async  (id) => {
		virtualBackgroundRef.doc(id).delete();
	};

	const handleVirtualBackground = (url) => {
		// virtualBackground(url);
		setIsVirtualBackgroundModal(false);
	};
	return (
		<div className="flex w-full justify-between">
			<VirtualBackgroundModal
				isOpen={isVirtualBackgroundModal}
				onOk={handleVirtualBackground}
				onCancel={() => setIsVirtualBackgroundModal(false)}
				addBackground={addNewVirtualBackground}
				deleteBackground={deleteVirtualBackground}
				virtualBackgrounds={virtualBackgrounds}
			/>
			<div className="flex items-center ">
				<InviteLink className="text-gray-100"/>
				{/* <AudioChannel className="text-gray-100" /> */}
				<button
					className="pl-2 pr-2 ml-2 border-none custom-bg-1 text-gray-100 flex items-center hover:custom-bg-1"
					onClick={() => setIsVirtualBackgroundModal(true)}
				>
					Virtual Background
					<ArrowDown className="text-gray-100 w-4 h-4 -mb-1"/>
				</button>
			</div>
			
			<Broadcast conferenceId={conferenceId} />
			
			<div className="flex">
				<Scenes conferenceId={conferenceId}/>
			</div>
		</div>
	)
};

export default HeaderQuickControl;
