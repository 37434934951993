import React, { useContext, useEffect, useState } from "react";
import Video from "../components/rtc/video";
import VideoFeed from "../components/rtc/videoFeed";
import VideoContainer from "../components/videoContainer";
import { AuthContext } from "../helpers/context/authContext";
import { LocalTracksContext } from "../helpers/context/localTracksContext";
import { MediaDeviceContext } from "../helpers/context/mediaDeviceContext";
import { makeLocalTracks } from "../helpers/context/localTracksContext";

const LocalVideoFeed = ({
  mainHost,
  localParticipant,
  onAction,
  onActionText = "Go Live",
  className,
  width,
  height,
  disableAudio,
  z,
  showAction = false,
  muted = false,
  onMute,
  ...props
}) => {
  const { user } = useContext(AuthContext);
  const {
    localTracks,
    acquiringTracks,
    setLocalTracks,
    localScreenShare,
    setLocalScreenShare,
    isSharingIntent,
    setIntent,
    rebuildTwilio
  } = useContext(LocalTracksContext);
  const [localMuted, setLocalMuted] = useState(muted);

  useEffect(() => {
    if (localTracks) {
      localTracks.forEach((track) => {
        if (track && track.kind === "audio") {
          if (track.hasOwnProperty('isEnabled')) {
            if (muted && track.isEnabled) {
              track.disable();
            }

            if (!muted && !track.isEnabled) {
              track.enable();
            }
          } else {
            track.enabled = !track.enabled;
          }
        }
      });
    }
    setLocalMuted(muted);
  }, [muted, localTracks]);

  const stopSharing = () => {
    rebuildTwilio();
    setIntent(false);
    setLocalScreenShare(false);
  };

  useEffect(() => {
    if (localTracks) {
      if (localScreenShare && isSharingIntent) {
        let isShared = false;
        navigator.mediaDevices.getDisplayMedia({
          video: true,
        })
          .then(async (screenMedia) => {
            isShared = true;
            
            localTracks.forEach((t) => {
              t && localParticipant?.unpublishTrack(t);
            });

            const audioStream = await makeLocalTracks({audio: true, video: false});
            let newTracks = [screenMedia.getVideoTracks()[0], audioStream?.[0]];

            newTracks[0].addEventListener('ended', () => {
              localParticipant.unpublishTrack(newTracks[0]);
              stopSharing();
            });

            setLocalTracks(newTracks);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIntent(false);
            if (!isShared) {
              setLocalTracks(localTracks);
              setLocalScreenShare(false);
            }
          });
      } else if (!localScreenShare && isSharingIntent) {
        localTracks.forEach((t) => {
          if (localParticipant && t && t.kind === 'video') {
            t.stop();
            localParticipant.unpublishTrack(t);
          }
        });
        stopSharing();
      }
    }
  }, [localScreenShare]);

  useEffect(() => {
    console.log('local track changed');
  }, [localTracks]);

  const handleMuteToggle = (event) => {
    localTracks && localTracks.forEach((track) => {
      if (track && track.kind === "audio") {
        if (track.hasOwnProperty('isEnabled')) {
          track.isEnabled ? track.disable() : track.enable();
        } else {
          track.enabled = !track.enabled;
        }
      }
    });
    const selfIsMuted = 
      localTracks && localTracks
        .filter((track) => track?.kind === "audio")
        .filter((track) => track && !(track.hasOwnProperty('isEnabled') ? track.isEnabled : track.enabled)).length > 0
        
    onMute();

    setLocalMuted(selfIsMuted);
    event.stopPropagation();
  };

  const handleScreenToggle = (event) => {
    setLocalScreenShare(!localScreenShare);
    setIntent(true);
    event.stopPropagation();
  };

  if (acquiringTracks) return <div className="loader">&nbsp;</div>;

  return (
    <VideoFeed
      onAction={onAction}
      onActionText={onActionText}
      className={className}
      key={user?.uid}
      showAction={showAction}
      {...props}
    >
      <VideoContainer
        mainHost={mainHost}
        isLocal
        onMute={handleMuteToggle}
        muted={localMuted}
        shared={localScreenShare}
        onScreenShare={handleScreenToggle}
        showAdminCommands={showAction}
        className="h-full"
        // style={{ height: "100%" }}
        width={width}
        height={height}
        identity={localParticipant && localParticipant.identity}
      >
        {localTracks && (
          <Video
            width={width ? width : height / 1080 * 1920}
            height={height}
            loading={acquiringTracks}
            tracks={localTracks}
            className="h-full object-cover"
            style={{zIndex: z}}
            disableAudio={disableAudio}
            isLocal
          />
        )}
      </VideoContainer>
    </VideoFeed>
  );
};

export default LocalVideoFeed;
