import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../button";
import InputField from "../forms/inputField";
import Modal from "../modal";

const LoadLayoutModal = ({ isOpen, onClose, layouts, onSubmit }) => {
  const [layout, setLayout] = useState();

	const handleSubmit = (e) => {
		if (!layout) return;
		onSubmit({id: layout.id})
	};

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-1/4">
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">Load Layout</h2>
      </div>
			<div className="p-2">
				<ul className="border border-gray-300 p-2 rounded-md">
					{layouts && layouts.map((_layout, idx) => (
						<li
							key={idx}
							className="hover:bg-blue-200 hover:text-gray-500 rounded pl-1 cursor-pointer text-gray-300"
							onClick={() => setLayout(_layout)}
						>
							{_layout.name}
						</li>
					))}
				</ul>
			</div>
			<div className="p-2">
				<label className="font-bold text-gray-300">Selected Layout:</label>
				<label className="ml-2 text-gray-300">{layout?.name}</label>
				<Button className="float-right mb-2 bg-gray-500 hover:bg-gray-400 text-gray-100" type="submit" onClick={handleSubmit}>Load</Button>
			</div>
    </Modal>
  );
};

export default LoadLayoutModal;
