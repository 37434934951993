import React from 'react';
import styled from "@emotion/styled";

const Container = styled.div`
  position: relative;
  display: inline-block;

  .action {
    display: none;
  }

  &:hover {
    .action {
      display: flex;
    }
  }
`;

const VideoFeed = ({
  children,
  className,
  onAction,
  onActionText = "Go Live",
  showAction = false,
  ...props
}) => {
  return (
    <Container className={className} {...props}>
      {showAction && (
        <div key={props.keyIdx} className="action rounded-md absolute w-full h-full z-20 items-center justify-center flex">
          <div className="rounded-md absolute w-full h-full z-30 bg-yellow-500 opacity-50" />
          <button
            className="bg-white hover:bg-gray-100 rounded-md shadow-md py-1 px-3 z-40 font-medium text-gray-800"
            onClick={onAction}
          >
            {onActionText}
          </button>
        </div>
      )}
      {children}
    </Container>
  );
};

export default VideoFeed;
