import React, { useContext, useMemo, useRef, useState, useEffect } from "react";
import Button from '../button';
import useResizeObserver from "use-resize-observer";
import Helmet from "react-helmet";
import LayerEditorControls from "../../features/layerEditorControls";
import AttendeesRoom from "../../features/attendeesRoom";
import ChatRoom from "../../features/chatRoom";
import { AuthContext } from "../../helpers/context/authContext";
import { useConferenceCall } from "../../helpers/hooks/useConferenceCall";
import { useFirebase } from "../../helpers/hooks/useFirebase";
import { useCollectionQuery } from "../../helpers/hooks/useFirebaseCollection";
import { useDocumentQuery } from "../../helpers/hooks/useFirebaseDocument";
import { useLayerEditorContext } from "../../helpers/hooks/useLayerEditorContext";
import { useHistory } from "react-router-dom";
import View from "./view";
import Edit from "./edit";
import Control from "./control";
import { ReactComponent as ArrowDrag } from "../../icons/chevron-down.svg";


const Teleprompter = ({
	isHost,
	conferenceId,
	isTeleprompter,
	setIsTeleprompter,
	parentRef,
}) => {
	const style = isTeleprompter ? {
			transform: 'translate(0px, 0px)',
			transition: 'transform 0.5s'
		} : {
			transform: 'translate(-110%, 0px)',
			transition: 'transform 0.5s'
		};
	const btnStyle = isTeleprompter ?
    {
      transform : `translate(${parentRef.current?.offsetWidth - 62}px, -18px)`,
      transition: 'all 0.5s',
    } : {
      transform: 'translate(-9px, -18px)',
      transition: 'all 0.5s'
    };
	const [currentTab, setCurrentTab] = useState('view');
	const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);
	const docRef = firestore.collection("conferences").doc(conferenceId);
	const editTextRef = useRef();
  
  const [conference, loading] = useDocumentQuery(docRef);

  const { user } = useContext(AuthContext);
	useEffect(() => {
		if (user?.isAnonymous) {
			setCurrentTab('view');
		}
	}, [isHost, user]);

	useEffect(() => {
		if (conference?.teleprompter?.status) {
			setIsTeleprompter(conference?.teleprompter?.status?.isOpen);
		}
	}, [conference?.teleprompter, setIsTeleprompter]);

	const handleSave = (e) => {
		docRef.update({
			teleprompter: {
				...conference?.teleprompter,
				text: editTextRef.current.value,
			}
		});
	};

	const handleCancel = (e) => {
		editTextRef.current.value = conference?.teleprompter?.text;
	};

	const settleCurrentParagraph = (idx = 0) => {
		docRef.update({
			teleprompter: {
				...conference?.teleprompter,
				currentParagraph: idx,
			}
		});
	};

	const updateInterval = (interval) => {
		docRef.update({
			teleprompter: {
				...conference?.teleprompter,
				interval
			}
		});
	};

	const updateStatus = (status) => {
		docRef.update({
			teleprompter: {
				...conference?.teleprompter,
				status,
			}
		});
	};

  return (
		<>
			{/* {isHost &&
				<Button
					className="w-12 h-8"
					style={btnStyle}
					onClick={() => {
						updateStatus({
							...conference?.teleprompter?.status,
							isOpen: !isTeleprompter
						})
						setIsTeleprompter(!isTeleprompter);
					}}
				>
					{!isTeleprompter && <ArrowDrag className="w-6 h-6" style={{	transform: 'rotate(-90deg)' }} /> }
					{isTeleprompter && <ArrowDrag className="w-6 h-6" style={{	transform: 'rotate(90deg)' }} /> }
				</Button>
			} */}
			<div
				className="h-full"
				// style={style}
			>
				{/* <Button
					className="w-12 h-8 absolute right-0 top-0"
					onClick={() => {
						setIsTeleprompter(false);
					}}
				>
					<ArrowDrag className="w-6 h-6" style={{	transform: 'rotate(90deg)' }} />
				</Button> */}
				{isHost && <div className="flex" style={{ transform: 'translateY(2px)' }}>
					<div
						onClick={() => setCurrentTab('view')}
						className={`cursor-pointer rounded-t-md pt-1 w-16 h-8 text-lg text-white text-center border border-green-400 mr-1 ml-2 text-sm flex items-center justify-center
						${currentTab === 'view' ? 'bg-green-400 z-20 border-b border-white' : 'custom-bg-2 z-10'}`}
					>
						View
					</div>
					<div
						onClick={() => setCurrentTab('edit')}
						className={`${currentTab === 'edit' ? 'bg-green-400 z-20' : 'custom-bg-2 z-10'} 
							cursor-pointer rounded-t-md pt-1 w-16 h-8 text-lg text-center border border-green-400 mr-1 text-white text-sm flex items-center justify-center`}
						style={currentTab === 'edit' ? { borderBottom: 'white', borderBottomLeftRadius: '2px', borderBottomRightRadius: '2px' } : {}}
					>
						Edit
					</div>
					<div
						onClick={() => setCurrentTab('control')}
						className={`${currentTab === 'control' ? 'bg-green-400 z-20' : 'custom-bg-2 z-10'}
						cursor-pointer rounded-t-md pt-1 w-16 h-8 text-lg text-center border border-green-400 text-white text-sm flex items-center justify-center`}
						style={currentTab === 'control' ? { borderBottom: 'white', borderBottomLeftRadius: '2px', borderBottomRightRadius: '2px' } : {}}
					>
						Control
					</div>
				</div>}
				<div
					className={`p-4 w-full bg-green-400 ${isHost?"border border-gray-600":"mt-2"} rounded `}
					style={isHost ? { height: 'calc(100% - 40px)'} : { height: 'calc(100% - 30px)' }}
				>
					{currentTab === 'view' &&
						<View
							isHost={isHost}
							teleprompter={conference?.teleprompter}
							updateStatus={updateStatus}
							updateInterval={updateInterval}
							isOpen={isTeleprompter}
						/>
					}
					{currentTab === 'edit' && 
						<Edit
							isOpen={isTeleprompter}
							teleprompter={conference?.teleprompter} editTextRef={editTextRef} handleSave={handleSave} handleCancel={handleCancel}
						/>
					}
					{currentTab === 'control' &&
						<Control
							isOpen={isTeleprompter}
							isHost={isHost}
							teleprompter={conference?.teleprompter}
							settleCurrentParagraph={settleCurrentParagraph}
							updateStatus={updateStatus}
							updateInterval={updateInterval}
						/>
					}
				</div>
			
			</div>
		</>
	)
};

export default Teleprompter;
