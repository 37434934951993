import styled from "@emotion/styled";
import { fromUnixTime } from "date-fns";
import { nanoid } from "nanoid";
import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import useResizeObserver from "use-resize-observer";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { Tab as ReactTab, TabList, TabPanel, Tabs } from "react-tabs";
import Layout from "../components/layout/index.js";
import AddAllGuests from "../features/addAllGuests";
import AttendeesRoom from "../features/attendeesRoom";
import HeaderQuickControl from "../components/headerQuickControl/index.js";
import CreateConferenceModal from "../features/createConferenceModal";
import LayerEditorControls from "../features/layerEditorControls";
import RoomLock from "../features/roomLock";
import StreamEditor from "../features/streamEditor";
import CountdownModal from "../features/countdownTimeModal";
import { AuthContext } from "../helpers/context/authContext";
import { useConferenceCall } from "../helpers/hooks/useConferenceCall";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { useDocumentQuery } from "../helpers/hooks/useFirebaseDocument";
import { usePresence, useSelfPresence } from "../helpers/hooks/usePresence";
import { ReactComponent as CogIcon } from "../icons/cog.svg";
import { ReactComponent as PencilIcon } from "../icons/pencil.svg";
import { ReactComponent as LockIcon } from "../icons/lock-closed.svg";
import { ReactComponent as UnLockIcon } from "../icons/lock-open.svg";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
import { useAudioChannelContext } from "../helpers/hooks/useAudioChannelContext";
import {  useHistory } from "react-router-dom";
import { deleteFile, firebaseUpload } from "../helpers/firebaseUpload";
import Teleprompter from "../components/teleprompter/index";
import { ReactComponent as ArrowDrag } from "../icons/chevron-down.svg";
import { ReactComponent as TeleIcon} from "../icons/teleprompter-1.svg";
import Button from "../components/button";
import DropDownMenu from "../components/dropDownMenu";
// import { BroadcastProvider, BroadcastProvider1, BroadcastContext } from "../helpers/context/broadcastingContext.js";

const StyledTabPanelContainer = styled.div`
  .tab-panel {
    display: none !important;
  }

  .react-tabs__tab-panel--selected {
    display: block !important;
  }
`;

const StyledTabList = styled(TabList)`
  li {
    color: #d1d5db;
    border-bottom: 3px solid transparent;
  }

  li:hover {
    color: #4b5563;
  }

  .react-tabs__tab--selected {
    color: #374151;
    border-bottom: 3px solid #fcd34d;
  }
`;

StyledTabList.tabsRole = "TabList";

const StreamEditorHolder = ({
  room,
  conference,
  user,
  scenes,
  streamParticipants,
  sessions,
  mySession,
  currentState,
  conferenceId,
  countdown,
  onOpenCountdown,
  stageHolderRef
}) => {
  const ref = useRef();
  const { width, height } = useResizeObserver({ ref });
  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);

  const uploadRecord = async (recordUrl, fileName, setProgress) => {
    const metadata = {
      contentType: "video/mp4"
    };
    
    const fileRef = firebase.storage().ref().child(`${new Date().getTime()}-${fileName}`);
    const task = fileRef.put(recordUrl, metadata);
    const _promise = new Promise((resolve, reject) => {
      task.on('state_changed' , 

        function progress(snapshot){
          let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(percentage);
        },
        function error(err){

        },
        async function complete(data){
          const url = await fileRef.getDownloadURL();
          setProgress(0);
          resolve(url);
        }
      );
    })
    let res;
    await _promise.then(data => res = data);
    
    // const uploadPromise = () => new Promise((resolve, reject) => {
    //   firebase.storage().ref().child(`${new Date().getTime()}-${fileName}`).put(recordUrl, metadata).then(resolve, reject)
    // });

    // const val = await uploadPromise();

    // const url = await val.ref.getDownloadURL();
    const url = res;
    
    const recordingRef = firestore.collection("conferences").doc(conferenceId).collection('recordings');
    const id = nanoid();
    recordingRef.doc(id).set({
      id,
      name: fileName,
      url,
      createdAt: new Date().getTime(),
      description: ''
    });
  };

  return (
    <div
      ref={ref}
      className="flex-grow overflow-y-hidden mb-5 flex flex-col justify-center items-center"
      // style={{ height: "60vh" }}
      style={{ width: '100%', height: 'calc(100vh - 280px)' }}
    >
      <StreamEditor
        room={room}
        parentWidth={width}
        parentHeight={height}
        key={conference?.activeScene}
        scenes={scenes}
        activeScene={conference?.activeScene}
        participants={streamParticipants}
        renderLocalParticipant={currentState ? (mySession?.editingLive) : (mySession?.live)}
        // color={conference?.background?.color}
        // backgroundImage={conference?.background?.image}
        color={conference?.activeScene?.background?.color}
        backgroundImage={conference?.activeScene?.background?.image}
        sessions={sessions}
        uploadRecord={uploadRecord}
        countdown={countdown}
        onOpenCountdown={onOpenCountdown}
        stageHolderRef={stageHolderRef}
      />
    </div>
  )
}

const timeFormat = (time) => {
  let h = `${Math.floor(time / 3600)}`;
  if (h.length == 1) h = `0${h}`;
  time = time % 3600;
  let m = `${Math.floor(time / 60)}`;
  if (m.length == 1) m = `0${m}`;
  let s = `${time % 60}`;
  if (s.length == 1) s = `0${s}`;
  return `${h} : ${m} : ${s}`;
};

/**
 * Conference is the chief component responsible for rendering the Conference view. There
 * are two primary end-users for this view. Viewers, as in anonymous users, and Producers,
 * the people running the conference.
 */
const Producer = () => {
  const [editConferenceModal, setEditConferenceModal] = useState(false);
  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);
  const db = useMemo(() => firebase.database(), [firebase]);

  const { conferenceId } = useParams();
  const { setConferenceId, currentAudioChannel, setCurrentAudioChannel } = useAudioChannelContext();
  const { user, hostStatus, setMainHost, initHost, setHost, auth } = useContext(AuthContext);
  const { currentState, sceneLocked, setSceneLock, setActiveLayer, isTeleprompter, setIsTeleprompter } = useLayerEditorContext();
  // const { conferenceIdRef } = useContext(BroadcastContext);
  const history = useHistory();
  if (!user || user?.isAnonymous) window.location.href = "/";
  const [ countdownTime, setCountdownTime ] = useState(-1);
  const [ isOpenCountTime, setOpenCountTime ] = useState(false);
  const [ countdownMessage, setCountdownMessage] = useState('');
  const [ countdown, setCountdown ] = useState('HH : MM : SS');
  const dbRef = db.ref(`${conferenceId}/status`);
  const [attendeeHeight, setAttendeeHeight] = useState();
  const attendeeRef = useRef();
  const teleprompterRef = useRef();
  const stageHolderRef = useRef();
  const recordingRef = firestore.collection("conferences").doc(conferenceId).collection('recordings');

  useEffect(() => {
    setConferenceId(conferenceId);
  }, [conferenceId, setConferenceId]);
  
  const users = usePresence({
    firebase,
    user: {
      uid: "restreamer",
      displayName: "restreamer",
    },
    confId: conferenceId,
  });

  useEffect(() => {
    if (attendeeRef.current)
      setAttendeeHeight(attendeeRef.current.offsetHeight);
  }, [/*attendeeRef, attendeeRef.current*/]);

  useEffect(() => {
    dbRef.once('value', async (data) => {
      let temp = {};
      for (let key in data.val()) {
        if (key === undefined || key == null) continue;
        temp[key] = !!(data.val()[key].host || data.val()[key].mainHost);
      }
      await setHost(temp);
    });
    
    return () => {

    }
  }, [dbRef, setHost]);
  

  useEffect(() => {
    initHost(conferenceId);
    if( conferenceId && user && setMainHost) {
      setMainHost(conferenceId, user?.uid);
    }
  }, [conferenceId, user]);

  useEffect(() => {
    if (countdownTime === 0) {
      setCountdownMessage("Time is up!");
      setOpenCountTime(true);
    }
    if (countdownTime >=0) {
      setCountdown(timeFormat(countdownTime));
      setTimeout(() => {
        setCountdownTime(countdownTime - 1);
      }, 1000);
    }
  }, [countdownTime]);

  useSelfPresence({ firebase, confId: conferenceId, user, currentState });
  // Create a presence with the real-time datastore. This is important and will be used to manage
  // join requests later on.
  const userSessions = usePresence({ firebase, user, confId: conferenceId });

  // Join the conference call
  const {
    room,
    waitingRoomParticipants,
    streamParticipants,
  } = useConferenceCall({ conferenceId, user, sessions: userSessions, currentState });

  const mySession = userSessions?.[user?.uid];

  const docRef = firestore.collection("conferences").doc(conferenceId);
  
  const [conference, loading] = useDocumentQuery(docRef);

  const collectionRef = docRef.collection("scenes");

  const [scenes, scenesLoading] = useCollectionQuery(collectionRef, {
    idField: "id",
  });
  
  const setActiveScene = (id) => {
    docRef.update({
      activeScene: id,
    });
  };

  const createNewScene = ({ switchOnCreate = false, name = "", isEditingScene = false }) => {

    if (isEditingScene) {
      collectionRef.doc('editing_scene').set({
        name,
        layers: [],
      });
      return 'editing_scene';
    }

    const id = nanoid();

    collectionRef.doc(id).set({
      name,
      layers: [],
    });

    if (switchOnCreate) {
      setActiveScene(id);
    }

    return id;
  };

  const updateConference = (vals) => {
    vals.scheduledDate = vals.scheduledDate?.toDate?.() ?? vals.scheduledDate;

    docRef.update(vals);

    setEditConferenceModal(false);
  };

  useEffect(() => {
    if (conference && !conference?.activeScene && !scenesLoading) {
      if (!scenes?.length) {
        createNewScene({ switchOnCreate: true, name: "screen-1" });
        createNewScene({ isEditingScene: true, name: 'editingScene' });
      } else {
        setActiveScene(scenes[0].id);
      }
    }
  }, [conference, scenesLoading]);

  if (loading || scenesLoading) {
    return <div className="loader">&nbsp;</div>;
  }

  const onOpenCountdown = (event) => {
    setOpenCountTime(true);
  };

  const onCloseCountDown = (event) => {
    setCountdownMessage('');
    setOpenCountTime(false);
  }

  const saveRecording = async (file) => {
    const val = await firebaseUpload(firebase)({
      name: file.name,
      file,
    });

    const url = await val.ref.getDownloadURL();
  };

  const btnStyle = isTeleprompter ?
    {
      transform : `translate(0px, 0px)`,
      width: '20px',
      height: '30px',
      padding: '0px',
      // transition: 'all 0.5s',
    } : {
      transform: 'translate(40px, 0px)',
      width: '40px',
      height: '50px',
      padding: '0',
      // transition: 'all 0.5s'
    };
  const teleprompterStyle = isTeleprompter ? {
      zIndex: 2,
      height: `calc(100vh - 205px)`,
			transform: 'translate(0px, 0px)',
			transition: 'transform 0.5s'
		} : {
      zIndex: 2,
      height: `calc(100vh - 205px)`,
			transform: 'translate(-100%, 0px)',
			transition: 'transform 0.5s'
		};

  const canvasStyle = isTeleprompter ? {
      // background: '#e5e5e5',
      left: '16.7vw',
      width: 'calc(66.7vw - 0px)',
      transition: 'all 0.5s',
    } : {
      // background: '#e5e5e5',
      left: '0px',
      width: 'calc(83.3vw - 0px)',
      transition: 'all 0.5s',
    };

  return (
    <Layout
      titleBarContent={
        <div className="flex items-center gap-3 height-2.5">
          <h1 className="text-xl font-extrabold text-gray-100 tracking-loose" style={{minWidth: '100px'}}>
            {conference?.name}
          </h1>
          <CogIcon
            className="h-5 w-5 text-gray-200 hover:text-gray-500 cursor-pointer"
            onClick={() => setEditConferenceModal(true)}
          />
          {/* <div className="flex gap-2 bg-gray-50 p-2 items-center rounded-lg">
            <p className="font-medium">Invite:</p>
            <CopyField value={window.location.href.replace("/producer", "")} />
          </div> */}
          <HeaderQuickControl conferenceId={conferenceId}/>
        </div>
      }
    >
      <Helmet>
        <title>Conference Cloud - Producer</title>
      </Helmet>
      {editConferenceModal && (
        <CreateConferenceModal
          title="Edit Conference"
          isOpen={true}
          onClose={() => setEditConferenceModal(false)}
          onSubmit={updateConference}
          name={conference?.name}
          rtmpUrl={conference?.rtmpUrl}
          scheduledDate={
            conference?.scheduledDate?.seconds
              ? fromUnixTime(conference.scheduledDate.seconds)
              : new Date()
          }
        />
      )}
      <CountdownModal
        isOpen={isOpenCountTime}
        onClose={onCloseCountDown}
        setCountdownTime={setCountdownTime}
        message={countdownMessage}
      />
    
      <div className="w-full flex relative h-full custom-bg-2 custom-shadow ">
        <div
          ref={teleprompterRef}
          className="w-2/12 pt-5 pl-3 pr-3 relative"
          style={teleprompterStyle}
        >
          
          <button
            className={` h-10 absolute right-0 rounded p-2 py-2 ${isTeleprompter ? "top-14 bg-green-400 border-none": "top-0 w-12 bg-green-500"} `}
            style={btnStyle}
            onClick={() => {
              docRef.update({
                teleprompter: {
                  ...conference?.teleprompter,
                  status: {
                    ...conference?.teleprompter?.status,
                    isOpen: !isTeleprompter
                  },
                }
              });
              setIsTeleprompter(!isTeleprompter);
            }}
          >
            
            {!isTeleprompter && <>
                <TeleIcon className="w-10 h-10"/>
                {/* <ArrowDrag className="w-6 h-6" style={{	transform: 'rotate(-90deg)' }} /> */}
              </>
            }
            {isTeleprompter && <ArrowDrag className="w-6 h-6" style={{	transform: 'rotate(90deg)' }} /> }
          </button>
        
          <Teleprompter
            isHost={hostStatus[user?.uid]}
            conferenceId={conferenceId}
            isTeleprompter={isTeleprompter}
            setIsTeleprompter={setIsTeleprompter}
            parentRef={teleprompterRef}
          />
        </div>
        <div ref={stageHolderRef} className="p-5 pb-0 flex flex-col gap-2 absolute h-full" style={canvasStyle}>
          <StreamEditorHolder
            room={room}
            conference={conference}
            user={user}
            scenes={scenes.filter((scene) => scene.id != 'editing_scene')}
            streamParticipants={streamParticipants}
            mySession={mySession}
            sessions={userSessions}
            currentState={currentState}
            conferenceId={conferenceId}
            countdown={countdown}
            onOpenCountdown={onOpenCountdown}
            stageHolderRef={stageHolderRef}
          />
        </div>

        <LayerEditorControls room={room} conference={conference} conferenceId={conferenceId} docRef={docRef} />
      </div>
      <div
        className=" custom-bg-3 shadow-lg rounded-sm flex flex-col overflow-x-auto p-1 absolute left-0 bottom-0 w-full z-3000"
        style={{height: '160px'}}
      >
        <div className="flex justify-between">
          <h3 className="text-gray-300 font-extrabold text-xl leading-none ml-px pt-1">
            Waiting Room
          </h3>

          <div className="flex gap-2 items-center">
          
            <div className="text-lg flex items-center mb-1">
              <button
                className={`${currentAudioChannel === 'Cue' ? "bg-red-500 hover:bg-red-400" : "bg-green-500 hover:bg-green-400"} text-gray-700 rounded px-2`}
                onClick={(e) => {
                  if (currentAudioChannel === 'Cue') {
                    setCurrentAudioChannel('On Stage');
                  } else {
                    setCurrentAudioChannel('Cue');
                  }
                }}
              >
                Cue
              </button>
              <RoomLock />
              <DropDownMenu
                className="flex justify-between px-1 custom-bg-3 border-none text-sm text-gray-100 items-center"
                childCls="custom-bg-2 shadow-lg rounded"
                // icon="screen"
                text={'Max Guests'}
              >
                <DropDownMenu.MenuItem
                  className="text-gray-100 -mt-2"
                >
                  <input
                    name="maxParticipants"
                    type="number"
                    className="h-8 rounded-lg mb-px text-black"
                    onClick={e => e.stopPropagation()}
                  />
                </DropDownMenu.MenuItem>
              </DropDownMenu>
            </div>
            <AddAllGuests />
          </div>
        </div>

        <div
          ref={attendeeRef}
          className="overflow-x-scroll w-full custom-bg-2 rounded scrollbar-1"
          style={{ height: 'calc(100% - 35px) '}}
        >
          <AttendeesRoom
            room={room}
            height={attendeeHeight}
            localParticipant={room && room.localParticipant}
            producer={!user?.isAnonymous}
            sessions={userSessions}
            participants={waitingRoomParticipants}
            streamParticipants={streamParticipants}
            // userIsLive={currentState ? (mySession?.editingLive) : (mySession?.live)}
            userIsLive={mySession?.live}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Producer;
