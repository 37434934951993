import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { ReactComponent as CheckCircleIcon } from "../icons/check-circle.svg";
import { ReactComponent as XCircleIcon } from "../icons/x-circle.svg";

const EntryQueue = ({ className, children }) => {
  const { conferenceId } = useParams();
  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);

  const queueRef = firestore
    .collection("conferences")
    .doc(conferenceId)
    .collection("participants")
    .where("canJoin", "==", false)
    .where("deny", "==", false);

  const [_data, loading] = useCollectionQuery(queueRef, { idField: "id" });
  const data = _data?.filter(_ => _.actionTime > 0);

  const handleApprove = (id) => {
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    firestore
      .collection("conferences")
      .doc(conferenceId)
      .collection("participants")
      .doc(id)
      .update({
        canJoin: true,
        actionTime: new Date().getTime() + offset,
      });
  };

  const handleDeny = (id) => {
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    firestore
      .collection("conferences")
      .doc(conferenceId)
      .collection("participants")
      .doc(id)
      .update({
        canJoin: false,
        deny: true,
        actionTime: new Date().getTime() + offset,
      });
  };

  if (loading) return <div className="loader">&nbsp;</div>;

  if ((!data || data.length <= 0) && children) {
    return children;
  } else if (!data && !children) {
    return (<></>);
  }

  return data?.map((req, idx) => (
    <div
      key={idx}
      className={`${
        className ? className : ""
      } bg-white hover:bg-gray-50 rounded-md border border-gray-200 p-2 m-1 flex flex-col justify-center items-center`}
    >
      <div className="flex flex-col items-center text-gray-700">
        <h5 className="font-bold text-xl">{req.name},</h5>
        <p>would like to join.</p>
      </div>
      <div className="flex gap-2">
        <button
          onClick={() => handleApprove(req.id)}
          className="text-gray-700 hover:text-green-500"
        >
          <CheckCircleIcon className="h-7 w-7" />
        </button>
        <button
          onClick={() => handleDeny(req.id)}
          className="text-gray-700 hover:text-red-500"
        >
          <XCircleIcon className="h-7 w-7" />
        </button>
      </div>
    </div>
  ));
};

export default EntryQueue;
