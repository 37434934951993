import React from 'react';
import { useMemo, useReducer } from "react";

export const ERROR_TYPE = "error";
export const RESET_TYPE = "reset";
export const VALUE_TYPE = "value";

export const handleErrorType = (state, action) => ({
  ...state,
  error: action.error,
  loading: false,
  value: undefined,
});

export const handleValueType = (state, action) => ({
  ...state,
  error: undefined,
  loading: false,
  value: action.value,
});

export const handleResetType = (_, action) => getDefaultState(action.value);

export const getDefaultState = (value) => ({
  loading: value === undefined || value === null,
  value,
});

const HANDLERS = {
  error: handleErrorType,
  reset: handleResetType,
  value: handleValueType,
};

export const reducer = (state, action) => {
  const handler = HANDLERS[action.type];

  if (handler) {
    return handler(state, action);
  }

  return state;
};

export const useLoading = (getDefaultValue) => {
  const value = useMemo(() => getDefaultValue?.(), [getDefaultValue]);
  const [state, dispatch] = useReducer(reducer, getDefaultState(value));

  const reset = () => {
    dispatch({ type: RESET_TYPE, value });
  };

  const setError = (error) => {
    dispatch({ type: ERROR_TYPE, error });
  };

  const setValue = (value) => {
    dispatch({ type: VALUE_TYPE, value });
  };

  return {
    error: state.error,
    loading: state.loading,
    value: state.value,
    reset,
    setError,
    setValue,
  };
};
