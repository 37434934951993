import { Resizable } from "re-resizable";
import React from "react";
import { install } from "resize-observer";
import ParticipantVideoFeed from "./participantVideoFeed";
import Draggable from './draggable';
import Button from "../components/button";
import { ReactComponent as SortAscendingIcon } from "../icons/sort-ascending.svg";
import { ReactComponent as SortDescendingIcon } from "../icons/sort-descending.svg";
import { useAudioChannelContext } from "../helpers/hooks/useAudioChannelContext";
// TODO
// - Ratio resize?
install();

const ratio = 1920 / 1080;

const scaleDimensions = ({ viewWidth, viewHeight, width, height }) => {
  const widthPercentage = viewWidth / 1920;
  const heightPercentage = viewHeight / 1080;
  // const computedWidth = widthPercentage * (width > 0 ? width : 500);
  // const computedHeight = heightPercentage * (height > 0 ? height : 500);
  const computedHeight = heightPercentage * (height > 0 ? height : 500);
  const computedWidth = computedHeight * ratio;

  return {
    width: computedWidth,
    height: computedHeight,
  };
};

const deScaleDimensions = ({ viewWidth, viewHeight, width, height }) => {
  const widthPercentage = viewWidth / 1920;
  const heightPercentage = viewHeight / 1080;

  // const computedWidth = width / widthPercentage;
  // const computedHeight = height / heightPercentage;
  const computedHeight = height / heightPercentage;
  const computedWidth = computedHeight * ratio;

  return {
    width: computedWidth,
    height: computedHeight,
  };
};

const StreamLayer = ({
  onDrag,
  participant,
  x = 0,
  y = 0,
  z = 0,
  id,
  onRemove,
  currentAudioChannel,
  height: givenHeight,
  width: givenWidth,
  editable,
  onResize,
  selected,
  hovered,
  onClick,
  viewWidth,
  viewHeight,
  mute,
  streamer,
  sessions,
  handleBringBack,
  handleBringForward,
  isBeingReposition,
  setIsBeingReposition,
  zoneIndex,
  hoveredLayer,
  zone,
}) => {
  const { width, height } = scaleDimensions({
    viewWidth,
    viewHeight,
    width: givenWidth,
    height: givenHeight,
  });
  const isInHoveredLayer = () => {
		if (z > hoveredLayer?.z && hoveredLayer?.zone === zone) {
		  if (hoveredLayer?.x < x && hoveredLayer?.x + hoveredLayer?.width > x
			&& hoveredLayer?.y < y && hoveredLayer?.y + hoveredLayer?.height > y) {
			  return true;
			}
		  if (hoveredLayer?.x < x + givenWidth && hoveredLayer?.x + hoveredLayer?.width > x + givenWidth
			&& hoveredLayer?.y < y && hoveredLayer?.y + hoveredLayer?.height > y) {
			  return true;
			}
		  if (hoveredLayer?.x < x && hoveredLayer?.x + hoveredLayer?.width > x
			&& hoveredLayer?.y < y + givenHeight && hoveredLayer?.y + hoveredLayer?.height > y + givenHeight) {
			  return true;
			}
		  if (hoveredLayer?.x < x + givenWidth && hoveredLayer?.x + hoveredLayer?.width > x + givenWidth
			&& hoveredLayer?.y < y + givenHeight && hoveredLayer?.y + hoveredLayer?.height > y + givenHeight) {
			  return true;
			}
		}
		return false;
	  };
     
  const baseZ = isInHoveredLayer() ? 1500 : 0;
  console.log(streamer, currentAudioChannel);
  
  return (
    <Draggable
      id={id}
      onDrag={onDrag(id)}
      x={x}
      y={y}
      disabled={!editable}
      viewHeight={viewHeight}
      viewWidth={viewWidth}
      setIsBeingReposition={setIsBeingReposition}
    >
      <Resizable
        style={{
          position: "absolute",
          zIndex: isBeingReposition === id ? 2500 : hovered ? 2000 : selected ? 1500 + baseZ :  isInHoveredLayer() ? 2000 + z : 250 * zoneIndex + z,
        }}
        bounds="parent"
        handleClasses="handle"
        handleWrapperClass="re-handle"
        className="inline-block absolute"
        lockAspectRatio
        enable={{
          top: false,
          right: true && editable,
          bottom: true && editable,
          bottomLeft: false,
          bottomRight: true && editable,
          topRight: false,
          topLeft: false,
        }}
        size={{ width, height }}
        onResizeStart={(e) => {
          e.stopPropagation();
          setIsBeingReposition(id);
        }}
        onClick={onClick}
        onResizeStop={(e, direction, ref, d) => {
          const deScale = deScaleDimensions({
            viewWidth,
            viewHeight,
            width: width + d.width,
            height: height + d.height,
          });
          onResize({
            id,
            width: deScale.width,
            height: deScale.height,
          });
          setIsBeingReposition(null);
        }}
      >
        <ParticipantVideoFeed
          key={participant.sid}
          participant={participant}
          onActionText="Send to Waiting"
          width={width}
          height={height}
          z={z}
          onAction={onRemove}
          showAction={editable}
          onClose={onRemove}
          disableAudio={streamer && currentAudioChannel === "Cue"}
          className="w-full h-full h-auto cursor-move"
          session={sessions[participant.identity]}
          onMute={() =>
            mute(participant, sessions[participant.identity]?.muted)
          }
        />
        {/* {selected && <div className="absolute flex flex-col top-0 right-0 w-16 h-8 z-1500">
          <div>
            <Button
              className="w-8"
              onClick={(e) => {
                e.stopPropagation();
                handleBringForward({id, z});
              }}
            >
              <SortAscendingIcon className="w-4 h-4" />
            </Button>
            <Button
              className="w-8"
              onClick={(e) => {
                e.stopPropagation();
                handleBringBack({id, z});
              }}
            >
              <SortDescendingIcon className="w-4 h-4" />
            </Button>
          </div>
          <span className="text-red-500 font-bold">level: {z}</span>
        </div>} */}
      </Resizable>
    </Draggable>
  );
};

export default StreamLayer;
