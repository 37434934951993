import React from 'react';
import firebase from "firebase/app";
// import firebase from "firebase";
import { useEffect, useState } from "react";

export const useFirebaseInit = (config) => {
  const [loading, setLoading] = useState(true);
  const [fireInstance, setFireInstance] = useState();

  useEffect(() => {
    const inst = firebase.initializeApp(config);

    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        setFireInstance(inst);
        setLoading(false);
      });

    return () => {
      setFireInstance(undefined);
    };
  }, [config, setLoading, setFireInstance]);

  return [fireInstance, loading];
};
