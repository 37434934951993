import { useEffect, useState } from "react";

export const usePublication = (participant) => {
  const [publications, setPublications] = useState([]);

  useEffect(() => {
    setPublications(Array.from(participant.tracks.values()));

    const addPub = (pub) => {
      setPublications((prev) => [...prev, pub]);
    };
    const removePub = (pub) =>
      setPublications((prev) => prev.filter((p) => p !== pub));

    participant.on("trackPublished", addPub);
    participant.on("trackUnpublished", removePub);

    return () => {
      participant.off("trackPublished", addPub);
      participant.off("trackUnpublished", removePub);
    };
  }, [participant]);

  return publications;
};
