import React, { useState, useRef } from "react";
import { SketchPicker } from 'react-color';
import Button from "../button";
import { deleteFile, firebaseUpload } from "../../helpers/firebaseUpload";

const BackgroundControl = ({
	parentHeight,
	activeScene,
	firebase,
	scenesRef,
}) => {
	const childRef = useRef();
	const [color, setColor] = useState();
	const [image, setImage] = useState();
  const [currentBackground ,setCurrentBackground] = useState();
	const [uploading, setUploading] = useState(false);

	const style = {
		height: `${parentHeight - 220}px`
	}

	const handleChangeColor = (e) => {
		setColor(e.hex);
	};

	const handleSetSetting = async () => {
		let url;
		setUploading(true);
    if (image) {
      const file = image[0];
      try {
        if (activeScene?.background?.image) {
					await deleteFile(firebase)({ name: activeScene?.background?.image });
        }
      } catch (err) {
        console.log(err);
      }
      const val = await firebaseUpload(firebase)({
        name: file.name,
        file,
      });

      url = await val.ref.getDownloadURL();
    }
    setCurrentBackground({
      background: {
        color,
        image: url ?? "",
      },
    });
    scenesRef?.doc(activeScene?.id).update({
      background: {
        color: color ? color : '',
        image: url ?? "",
      },
    });
		setUploading(false);
  };

	return (
		<div
			ref={childRef}
			className="border p-2"
			style={style}
		>
			{/* <h2 className="font-light text-light text-gray-700">
				Background Image
			</h2>
			<div className="w-full border-gray-100 p-1 flex flex-col items-center">
				
				<div className="w-full border rounded">
					<input
						id="img"
						name="img"
						type="file"
						onChange={(event) => setImage(event.target.files)}
					/>
				</div>
			</div> */}
			<h2 className="font-light text-light text-gray-700">
				Background Color
			</h2>
			<div className="flex justify-center items-center p-2">
			<SketchPicker width={"100%"} className="w-full" color={ color } onChangeComplete={ handleChangeColor }/>
			</div>
			<Button
				className="border rounded w-20 float-right"
				onClick={handleSetSetting}
			>Set</Button>
			{uploading && <span>...Updating</span>}
		</div>
	)
};

export default BackgroundControl;
