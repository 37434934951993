import React, { useMemo, useRef, useContext, useEffect, useState } from "react";
import useResizeObserver from "use-resize-observer";
import { useParams } from "react-router-dom";
import StreamEditor from "../features/streamEditor";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { LocalTracksContext } from "../helpers/context/localTracksContext";
import { useDocumentQuery } from "../helpers/hooks/useFirebaseDocument";
import { usePresence, useSelfPresence } from "../helpers/hooks/usePresence";
import { useTwilioVideo } from "../helpers/hooks/useTwilioVideo";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
import { useAudioChannelContext } from "../helpers/hooks/useAudioChannelContext";
import Button from "../components/button";
import { nanoid } from "nanoid";
import UploadingStatusModal from "../features/uploadingStatusModal";
import { ReactComponent as PlayIcon } from "../icons/play1.svg";
import { ReactComponent as StopIcon } from "../icons/stop1.svg";
import { Dropbox } from "dropbox";
import { gapi, loadClientAuth2 } from 'gapi-script';
import isoUpload from '../features/isoUpload/index';
import DropboxSaver from 'react-dropbox-saver';
import loadScript from 'load-script';

const StreamRenderRTMP = () => {
  const { conferenceId } = useParams();
  const [_, participants] = useTwilioVideo({
    roomName: conferenceId,
    identity: "restreamer_rtmp",
    roomParams: {
      video: false,
      audio: false,
    },
  });
  const { broadcastSocket, localRecordingSocket } = useLayerEditorContext();
  const ref = useRef();
  const { width, height } = useResizeObserver({ ref });
  const [onLive, setOnLive] = useState(false);
  const participantStreams = useRef([]);

  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);

  const docRef = firestore.collection("conferences").doc(conferenceId);

  const [conference, loading] = useDocumentQuery(docRef);

  const scenesRef = docRef.collection("scenes");

  const [scenes, scenesLoading] = useCollectionQuery(scenesRef, {
    idField: "id",
  });

  const { closeDeviceSelection } = useContext(LocalTracksContext);

  const { setConferenceId } = useAudioChannelContext();

  useEffect(() => {
    setConferenceId(conferenceId);
  }, [conferenceId, setConferenceId]);
  
  const user = useMemo(
    () => ({
      uid: "restreamer_rtmp",
      displayName: "restreamer_rtmp",
    }),
    []
  );

  useSelfPresence({
    firebase,
    confId: conferenceId,
    user,
  });

  useEffect(() => {
    if (closeDeviceSelection) {
      closeDeviceSelection();
    }
  }, [closeDeviceSelection]);

  useEffect(() => {
    if (conference && !conference?.isBroadcast && broadcastSocket) {
      try {
        broadcastSocket?.disconnect();
      } catch (err) {
        console.log(err);
      }
    }
    if (conference && !conference?.isLocalRecording && localRecordingSocket) {
      try {
        localRecordingSocket?.disconnect();
      } catch (err) {
        console.log(err);
      }
    }
  }, [conference, conference?.isLocalRecording, conference?.isBroadcast, broadcastSocket, localRecordingSocket]);

  const users = usePresence({
    firebase,
    user: {
      uid: "restreamer_rtmp",
      displayName: "restreamer_rtmp",
    },
    confId: conferenceId,
  });
  
  const validParticipants = participants
    .filter((part) => part.tracks.size > 0)
    .filter((participant) => participant.identity in (users ? users : {}))
    .filter((part) => part.identity !== "restreamer_rtmp" && part.identity !== "restreamer_local");

  const streamParticipants = validParticipants.filter((participant) => {
    return users[participant.identity].live;
  });

  if (loading || scenesLoading) return <div className="loader">&nbsp;</div>;

  return (
    <div
      ref={ref}
      className="flex-grow overflow-y-hidden mb-2 flex justify-center items-center h-full w-full"
    >
      <StreamEditor
        key={conference?.activeScene}
        parentWidth={width?width:ref.current?.offsetWidth}
        parentHeight={height?height:ref.current?.offsetHeight}
        // parentWidth={1742}
        // parentHeight={980}
        editable={false}
        scenes={scenes}
        sessions={users}
        activeScene={conference?.activeScene}
        participants={streamParticipants}
        streamer={true}
        // color={conference?.background?.color}
        // backgroundImage={conference?.background?.image}
        color={conference?.activeScene?.background?.color}
        backgroundImage={conference?.activeScene?.background?.image}
        isStream={true}
      />
    </div>
  );
};

export default StreamRenderRTMP;
