import React, {useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { install } from "resize-observer";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
import Button from "../components/button";
import Scene from './scene';
import { timeFormat } from "../utils/utils";
import UploadingStatusModal from "./uploadingStatusModal";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { ReactComponent as PencilIcon } from "../icons/pencil.svg";
import { ReactComponent as LockIcon } from "../icons/lock-closed.svg";
import { ReactComponent as UnLockIcon } from "../icons/lock-open.svg";
import { ReactComponent as DesktopMobileIcon } from "../icons/desktop-mobile.svg";
// import { ReactComponent as BackgroundSvg } from "../icons/background.svg";
import { useOutsideClick } from "../helpers/hooks/useOutsideClick";
import impulse from "../assets/impulse.mp4";

// TODO
// - Ratio resize?
install();

// const ratio = 1920 / 1080;
// const ratio = 720 / 1080;

const StreamEditor = ({
  room,
  parentWidth: width = 800,
  parentHeight: height = 600,
  activeScene,
  scenes,
  participants = [],
  renderLocalParticipant = false,
  color,
  backgroundImage,
  sessions,
  uploadRecord,
  streamer=false,
  countdown,
  onOpenCountdown,
  isGuest=false,
  isStream=false,
  isHost=true,
  stageHolderRef
}) => {
  // const [testRecorder, setTestRecorder] = useState();
  // const [isTestRecord, setIsTestRecord] = useState(false);
  // const [testDrawings, setTestDrawings] = useState();
  const ref = useRef();
  const desktopRatio = 1920 / 1080;
  const [ratio, setRatio] = useState(desktopRatio);

  const { 
    activeLayer, setActiveLayer, setSemiActiveLayer, semiActiveLayer, currentState, setEdited, 
    sceneLocked, setSceneLock, activeZone, setActiveZone, curDevice, setCurDevice, layersCollection 
  } = useLayerEditorContext();

  const [screenLocked, setLock] = useState(sceneLocked);
  const activeSceneDoc = scenes?.filter(_scene => _scene.id === activeScene)[0];
  const _backgroundImage = backgroundImage || activeSceneDoc?.background?.image;
  const _backgroundColor = color || activeSceneDoc?.background?.color;

  const [style, setStyle] = useState({
    backgroundColor: _backgroundColor ? _backgroundColor : '#666666',
    backgroundImage: _backgroundImage ? `url(${_backgroundImage})` : 'none',
    width: width < (height - 50) * ratio ? width : (height - 50) * ratio,
    height: (height - 50) < width / ratio ? (height - 50) : width / ratio,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  });
  
  const scene = useMemo(
    () => scenes?.find((scene) => scene.id === activeScene),
    [activeScene, scenes]
  );

  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);
  const { conferenceId } = useParams();
  const collectionRef = firestore.collection("conferences").doc(conferenceId).collection("scenes");
  const layersRef = collectionRef.doc(scene?.id).collection(layersCollection);
  const [layers, layersLoading] = useCollectionQuery(layersRef, {
		idField: "id",
	});

  const zoneLayers = layers?.filter(_ => _?.zone === activeZone);
  const [startTime, setStartTime] = useState(false);
  // const [ctx, setCtx] = useState(); 
  const ctx = useRef();
  const canvas = document.getElementById('canvas');
  const [isUploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  // const [isUserMediaLoading, setIsUserMediaLoading] = useState(false);

  useOutsideClick(ref, () => {
    // setActiveLayer(null);
    setSemiActiveLayer(null);
  });

  const renderUserMedia = (_playMedia) => {
    if ((startTime > 0 && startTime !== Infinity) || _playMedia == undefined || !_playMedia?.state) {
      if (document.getElementById('mediaMP4'))
        document.getElementById('mediaMP4').style.display = 'none';
      return;
    }
    // const document.getElementById('mediaMP4') = document.createElement('video');
    document.getElementById('mediaMP4').src = _playMedia.url;
    document.getElementById('mediaMP4').crossOrigin = 'anonymous';
    document.getElementById('mediaMP4').style.display = 'block';
    document.getElementById('stopButton').style.display = 'block';
    document.getElementById('mediaMP4').style.contain = 'size';
    document.getElementById('mediaMP4').controls = true;
    document.getElementById('mediaMP4').onended = (e) => {
      collectionRef.doc(activeScene).update({
        playVideo: {
          url: '',
          state: false,
          currentTime: 0,
        }
      });
      setStartTime(0);
      document.getElementById('mediaMP4').src = '';
      document.getElementById('mediaMP4').style.display = 'none';
      document.getElementById('stopButton').style.display = 'none'; 
    };
    document.getElementById('mediaMP4').onloadeddata = (e) => {
      e.target.play();
      setStartTime(e.target.duration);
    }
    document.getElementById('mediaMP4').style.zIndex = '1000';
  };
  const isRealShow = useRef();
  const isAttached = useRef();

  const recordedChunks = useRef();

  // const audioCtx = new AudioContext();
  // const destination = audioCtx.createMediaStreamDestination();
  const [LocalMediaRecorder, setLocalMediaRecorder] = useState(null);
  const preOutputStream = useRef();

  const [isShowOverlay, setIsShowOverlay] = useState(activeZone === 'overlay');
  
  useEffect(() => {
    if (canvas) {
      ctx.current = canvas.getContext('2d');
    }
  }, [canvas]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLock(true);
  //     setLock(false);
  //   }, 1000);
  //   return () => {
  //     // setCtx();
  //     ctx.current = null;
  //     // setCanvasTrack();
  //     setStyle();
  //   }
  // }, []);

  useEffect(() => {
    collectionRef.doc(activeScene).get().then(snapshot => {
      renderUserMedia(snapshot.data()?.playVideo);
    });
  }, [scenes]);

  useEffect(() => {
    setLock(sceneLocked);
  }, [sceneLocked]);

  useEffect(() => {
    setStyle({
      backgroundColor: _backgroundColor ? _backgroundColor : '#666666',
      backgroundImage: _backgroundImage ? `url(${_backgroundImage})` : 'none',
      width: width < (height - 50) * ratio ? width : (height - 50) * ratio,
      height: ((height - 50) < width / ratio ? (height - 50) : width / ratio) - 20 * (ratio < 1),
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    });
  }, [width, height, _backgroundColor, _backgroundImage, ratio]);

  const download = async () => {
    var blob = new Blob(recordedChunks.current, {
      type: "video/mp4"
    });
    const fileName = `test-${new Date().getTime()}.mp4`
    
    setUploading(true);
    await uploadRecord(blob, fileName, setProgress);
    setUploading(false);
    // window.URL.revokeObjectURL(url);
    recordedChunks.current = [];
  };

  if (!scene) {
    return null;
  }

  const handleFocus = (e) => {
    if (currentState) setEdited(true);
    // console.log(layers);
    if (e.target.id === 'onstage')
      setActiveLayer(null);
  };

  const compare = (a, b) => {
    if (a.width * a.height > b.width * b.height) return 1;
    if (a.width * a.height < b.width * b.height) return -1;
    if (a.z > b.z) return 1;
    if (a.z < b.z) return -1;
    return 0;
  };

  const handleToggleDevice = (e) => {
    if (ratio > 1) {
      setRatio(820 / 1080);
      setCurDevice('mobile');
    } else {
      setRatio(1920 / 1080);
      setCurDevice('desktop');
    }
  }

  const handleMouseMove = (e) => {
    const recordPlayer = document.getElementById('mediaMP4');
    if (screenLocked || recordPlayer.style.display !== 'none') return;
    const w = Number(ref.current.style.width.replace('px', ''));
    const h = Number(ref.current.style.height.replace('px', ''));
    const pl = stageHolderRef?.current?.offsetLeft;
    const pt = stageHolderRef?.current?.offsetTop;
    const x = (e.clientX - ref.current.offsetLeft - pl) * 1920 / w;
    const y = (e.clientY - ref.current.offsetTop - pt - 58) * 1080 / h;

    let _layers = zoneLayers?.filter(_layer => 
      _layer.x / 100 * 1920 < x
      && _layer.y / 100 * 1080 < y + 10
      && _layer.x / 100 * 1920 + _layer.width + 10 >= x 
      && _layer.y / 100 * 1080 + _layer.height >= y
      && document.getElementById(_layer.id)
      && activeZone === _layer.zone
    );
    _layers = _layers?.sort(compare);
    if (!_layers || _layers.length === 0) {
      setSemiActiveLayer(null);
    } else {
      setSemiActiveLayer(_layers[0].id);
    }
  }

  const handleStopPlaying = (e) => {
    collectionRef.doc(activeScene).update({
      playVideo: {
        url: '',
        state: false,
        currentTime: 0,
      }
    });
    setStartTime(0);
    document.getElementById('mediaMP4').src = '';
    document.getElementById('mediaMP4').style.display = 'none';
    document.getElementById('stopButton').style.display = 'none'; 
  };

  return (
    <>
      {!isStream && 
        <>
          <div 
            className="flex justify-between items-end relative" 
            style={{width: width < (height - 50) * desktopRatio ? width : (height - 50) * desktopRatio}}
          >
              
            {!isHost && <h2 className="text-black font-extrabold text-2xl leading-none ml-px mb-3">
              Conference
            </h2>}
            
            {isHost && 
              <Button 
                className="absolute top-8 -left-8 w-8 px-1 custom-bg-3 border-none text-white
                  hover:text-yellow-500 hover:custom-bg-3 focus:ring-transparent"
                onClick={handleToggleDevice}
              >
                <DesktopMobileIcon className="" />
              </Button>
            }
            
            {isHost && 
              <div className="flex items-center">
                <div className="flex">
                  <Button
                    className={`rounded-t-md ${activeZone === 'background'?'custom-bg-3':'custom-bg-1'} text-gray-100 
                       hover:text-blue-600 border-none mr-2
                      focus:ring-transparent w-28`}
                    onClick={() => {
                      setActiveZone('background');
                      setIsShowOverlay(false);
                      setActiveLayer(null);
                    }}
                  >Background</Button>
                  <Button
                    className={`rounded-t-md ${activeZone === 'participant'?'custom-bg-3':'custom-bg-1'} text-gray-100 
                       hover:text-blue-600 border-none mr-2
                      focus:ring-transparent w-28`}
                    onClick={() => {
                      setActiveZone('participant');
                      setIsShowOverlay(false);
                      setActiveLayer(null);
                    }}
                  >Participants</Button>
                  <Button
                    className={`rounded-t-md ${activeZone === 'foreground'?'custom-bg-3':'custom-bg-1'} text-gray-100 
                       hover:text-blue-600 border-none mr-2
                      focus:ring-transparent w-28`}
                    onClick={() => {
                      setActiveZone('foreground');
                      setIsShowOverlay(false);
                      setActiveLayer(null);
                    }}
                  >Foreground</Button>
                  <Button
                    className={`rounded-t-md ${activeZone === 'overlay'?'custom-bg-3':'custom-bg-1'} text-gray-100 
                       hover:text-blue-600 border-none mr-2
                      focus:ring-transparent w-28`}
                    onClick={() => {
                      setActiveZone('overlay');
                      setIsShowOverlay(true);
                      setActiveLayer(null);
                    }}
                  >Overlay</Button>
                </div>
                <Button
                  className="border-none rounded-md ml-2 h-6 text-gray-100 custom-bg-2 hover:text-blue-600"
                  onClick={() => {
                    setIsShowOverlay(!isShowOverlay)
                  }}
                >{isShowOverlay? 'Hide Overlay' : 'Show Overlay'}</Button>
              </div>
            }
            
          </div>
          {isHost && 
            <div className="flex absolute top-0 right-2">
              <div className="flex items-center text-xl font-bold text-gray-100">
                {countdown}
                <PencilIcon
                  onClick={onOpenCountdown}
                  className="ml-1 h-4 w-4 text-gray-400 hover:text-gray-600"
                />
              </div>
              <Button
                className="flex ml-2 text-lg text-gray-100 rounded border border-gray-500 custom-bg-2 my-1"
                onClick={() => {
                  setSceneLock(!sceneLocked);
                  setActiveLayer(null);
                }}
              >
                {sceneLocked && 
                  <LockIcon
                    className="text-gray-400 cursor-pointer w-6 h-6"
                  />
                }
                {!sceneLocked && 
                  <UnLockIcon
                    className="text-gray-400 cursor-pointer w-6 h-6"
                  />
                }
                Screen {sceneLocked ? 'Unlock' : 'Lock'}
              </Button>
            </div>
          }
        </>
      }
      <div 
        className="bg-gray-500 flex justify-center items-center" 
        style={{
          width: width < (height - 50) * desktopRatio ? width : (height - 50) * desktopRatio,
          height: (height - 50) < width / desktopRatio ? (height - 50) : width / desktopRatio
        }}
      >
        <div
          id="onstage"
          ref={ref}
          style={!isNaN(width) && !isNaN(height) && style ? style : {}}
          className="rounded-sm shadow relative overflow-hidden w-full h-full"
          onClick={handleFocus}
          onMouseMove={handleMouseMove}
          onMouseLeave={() => setSemiActiveLayer(null)}
        >
          {!_backgroundImage && 
            <div className="shape-bg-3"></div>
          }
          <UploadingStatusModal
            isOpen={isUploading}
            caption="Don't leave this page until uploading finished"
            progress={progress}
            onClose={() => setUploading(false)}
          />
          <Scene
            {...scene}
            participants={participants}
            localParticipant={room && room.localParticipant}
            renderLocalParticipant={renderLocalParticipant}
            viewWidth={width < (height - 50) * ratio ? width : (height - 50) * ratio}
            viewHeight={(height - 50) < width / ratio ? (height - 50) : width / ratio}
            sessions={sessions}
            activeScene={activeScene}
            activeLayer={activeLayer}
            setActiveLayer={setActiveLayer}
            semiActiveLayer={semiActiveLayer}
            currentState={currentState}
            screenLocked={screenLocked}
            streamer={streamer}
            isHost={isHost}
            activeZone={activeZone}
            isStream={isStream}
            isShowOverlay={isShowOverlay || !isHost}
          />
          
          <canvas
            id="canvas"
            style={{
              display: 'none',
              // width: `${width < height * ratio ? width : height * ratio}px`,
              // height: `${height < width / ratio ? height : width / ratio}px`
              width: '1920px',
              height: '1080px'
            }}
            // width={ width < height * ratio ? width : height * ratio}
            // height={ height < width / ratio ? height : width / ratio }
            width={1920}
            height={1080}
          ></canvas>
          <video
            id="mediaMP4"
            autoPlay={false}
            style={{position: 'absolute', display: 'none', top: 0, left: 0, width: '100%', height: '100%'}}
          />
          <video
            autoPlay={true}
            loop={true}
            muted={true}
            className="absolute bottom-0 right-0"
            style={{width: '1px', height: '1px'}}
          >
            {/* <source src="https://firebasestorage.googleapis.com/v0/b/conference-cloud-broadcaster.appspot.com/o/1644335271188-SampleVideo_360x240_30mb.mp4?alt=media&token=48ab3579-ccc1-47b7-afd9-7af10d3d1f53"></source> */}
            <source src="https://firebasestorage.googleapis.com/v0/b/conference-cloud-broadcaster.appspot.com/o/impulse.mp4?alt=media&token=67e28c94-d619-48c5-a8d2-982b3c97ecae"></source>
          </video>
          <Button
            id="stopButton"
            onClick={handleStopPlaying}
            style={{
              display: 'none', borderRadius: '50%', width: '50px', height: '50px', position: 'absolute',
              bottom: 0, right: 0, zIndex: 10000001, backgroundColor: 'red', color: 'white'
            }}
          >
            Stop
          </Button>
        </div>
      </div>
    </>
  );
};

export default StreamEditor;
