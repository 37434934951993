export const uploadFile = (ref, file) =>
  new Promise((resolve, reject) => {
    ref.put(file).then(resolve, reject);
  });

export const deleteFile = (firebase) => ({ name }) => {
  const ref = firebase.storage().ref();
  const fileRef = ref.child(name.split('/')[7].split('?')[0]);
  console.log(fileRef);
  return new Promise((resolve, reject) => {
    // firebase.storage().deleteObject(fileRef).then(() => {
    //   console.log('successfully deleted');
    //   resolve();
    // }).catch(err => {
    //   console.log('delete failed', err);
    //   reject();
    // });
    fileRef.delete().then(resolve, reject);
  })
};

export const firebaseUpload = (firebase) => ({ name, file }) => {
  const ref = firebase.storage().ref();
  // const fileRef = ref.child(name);
  const fileRef = ref.child(`${new Date().getTime()}-${name}`);
  return uploadFile(fileRef, file);
};
