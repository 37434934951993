import React from 'react';
import { useState, useEffect } from "react";
import { useTwilioCall } from "./useTwilioCall";

export const useConferenceCall = ({ conferenceId, user, sessions, currentState }) => {
  const twilioParams = {
    roomName: `${conferenceId}`,
    identity: user?.uid,
  };
  const [room, localTracks, participants] = useTwilioCall(twilioParams);
  const validParticipants = participants.filter(
    (participant) => participant.identity in (sessions ? sessions : {})
  );

  const waitingRoomParticipants = validParticipants.filter((participant) => {
    // if (currentState) {
    //   return (
    //     !Boolean(sessions[participant.identity].editingLive) &&
    //     participant.identity !== "restreamer_local" && participant.identity !== "restreamer_rtmp"
    //   );
    // } else {
    //   return (
    //     !Boolean(sessions[participant.identity].live) &&
    //     participant.identity !== "restreamer_local" && participant.identity !== "restreamer_rtmp"
    //   );
    // }
    return (
      !Boolean(sessions[participant.identity].live) &&
      participant.identity !== "restreamer_local" && participant.identity !== "restreamer_rtmp"
    );
  });

  const streamParticipants = validParticipants.filter((participant) => {
    // if (currentState)
    //   return Boolean(sessions[participant.identity].editingLive);
    // else return Boolean(sessions[participant.identity].live);
    return Boolean(sessions[participant.identity].live);
  });

  return {
    room,
    localTracks,
    participants,
    validParticipants,
    waitingRoomParticipants,
    streamParticipants,
  };
};
