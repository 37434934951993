import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import ParticipantView from "../features/participantView";
import RequestToJoin from "../features/requestToJoin";
import { AuthContext } from "../helpers/context/authContext";
import { useCanJoinRoom } from "../helpers/hooks/requests/useCanJoinRoom";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { usePresence, useSelfPresence } from "../helpers/hooks/usePresence";
import { useAudioChannelContext } from "../helpers/hooks/useAudioChannelContext";

const ConferenceView = ({ firebase, conferenceId, user }) => {
  useSelfPresence({ firebase, confId: conferenceId, user });

  // Create a presence with the real-time datastore. This is important and will be used to manage
  // join requests later on.
  const userSessions = usePresence({ firebase, user, confId: conferenceId });

  return (
    <ParticipantView
      userSessions={userSessions}
      conferenceId={conferenceId}
      user={user}
    />
  );
};

/**
 * Conference is the chief component responsible for rendering the Conference view. There
 * are two primary end-users for this view. Viewers, as in anonymous users, and Producers,
 * the people running the conference.
 */
const Conference = () => {
  const firebase = useFirebase();

  const { conferenceId } = useParams();
  const { setConferenceId } = useAudioChannelContext();
  const { auth } = useContext(AuthContext);
  const user = firebase.auth().currentUser;

  useEffect(() => {
    setConferenceId(conferenceId);
  }, [setConferenceId, conferenceId]);

  // Check to see if the user is allowed to join the room. This check persists with anonymous
  // users since the firebase library can fingerprint them most of the time.
  
  const [canJoin] = useCanJoinRoom({ conferenceId, userId: user?.uid });

  if (!user) {
    return <div className="loader">&nsbsp;</div>;
  }

  // If they aren't explicitly allowed to enter yet, show them the request page.
  if (user?.isAnonymous && !canJoin) {
    return <RequestToJoin user={user} auth={auth} />;
  }

  return (
    <ConferenceView
      firebase={firebase}
      user={user}
      conferenceId={conferenceId}
    />
  );
};

export default Conference;