import React, { useContext, useMemo, useRef, useState, useEffect } from "react";
import useResizeObserver from "use-resize-observer";
import Helmet from "react-helmet";
import Layout from "../components/layout/index.js";
import LayerEditorControls from "../features/layerEditorControls";
import AttendeesRoom from "../features/attendeesRoom";
import ChatRoom from "../features/chatRoom";
import { AuthContext } from "../helpers/context/authContext";
import { useConferenceCall } from "../helpers/hooks/useConferenceCall";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { useDocumentQuery } from "../helpers/hooks/useFirebaseDocument";
import StreamEditor from "./streamEditor";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
import { useHistory } from "react-router-dom";
import HeaderQuickControl from "../components/headerQuickControl/index.js";
import Button from "../components/button";
import { ReactComponent as LockIcon } from "../icons/lock-closed.svg";
import { ReactComponent as UnLockIcon } from "../icons/lock-open.svg";
import Teleprompter from "../components/teleprompter/index";
import { ReactComponent as ArrowDrag } from "../icons/chevron-down.svg";
import { ReactComponent as TeleIcon} from "../icons/teleprompter-1.svg";

const StreamEditorHolder = ({
  room,
  conference,
  user,
  scenes,
  streamParticipants,
  mySession,
  sessions,
  currentState,
  isHost,
  stageHolderRef
}) => {
  const ref = useRef();
  const { width, height } = useResizeObserver({ ref });

  return (
    <div
      ref={ref}
      className="rounded-sm flex-grow mb-2 flex flex-col justify-center items-center"
      style={{ width: '100%', height: 'calc(100vh - 280px)' }}
    >
      <StreamEditor
        room={room}
        parentWidth={width}
        parentHeight={height}
        key={conference?.activeScene}
        scenes={scenes}
        activeScene={conference?.activeScene}
        participants={streamParticipants}
        // renderLocalParticipant={currentState ? (mySession?.editingLive) : (mySession?.live)}
        renderLocalParticipant={mySession?.live}
        // color={conference?.background?.color}
        // backgroundImage={conference?.background?.image}
        color={conference?.activeScene?.background?.color}
        backgroundImage={conference?.activeScene?.background?.image}
        sessions={sessions}
        isGuest={true}
        isHost={isHost}
        stageHolderRef={stageHolderRef}
      />
    </div>
  )
}

const ParticipantView = ({ userSessions, conferenceId }) => {
  const { user, hostStatus, initHost, setHost, auth } = useContext(AuthContext);
  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);

  const [attendeeHeight, setAttendeeHeight] = useState();
  const attendeeRef = useRef();
  const teleprompterRef = useRef();
  const stageHolderRef = useRef();
  // useEffect(() => {
  //   if (attendeeRef.current)
  //     setAttendeeHeight(attendeeRef.current.offsetHeight);
  // }, [attendeeRef, attendeeRef.current]);

	const mySession = userSessions?.[user?.uid];

  const docRef = firestore.collection("conferences").doc(conferenceId);

  const [conference, loading] = useDocumentQuery(docRef);

  const collectionRef = docRef.collection("scenes");

  const [scenes, scenesLoading] = useCollectionQuery(collectionRef, {
    idField: "id",
  });

  const { currentState, sceneLocked, setSceneLock, setActiveLayer, isTeleprompter, setIsTeleprompter } = useLayerEditorContext();
  
  const { room, waitingRoomParticipants, streamParticipants } = useConferenceCall({
    conferenceId,
    user,
    sessions: userSessions,
    currentState
  });

  const db = useMemo(() => firebase.database(), [firebase]);
  const dbRef = db.ref(`${conferenceId}/status`);

  useEffect(() => {
    dbRef.once('value', async (data) => {
      let temp = {};
      for (let key in data.val()) {
        if (key === undefined || key === null) continue;
        temp[key] = !!(data.val()[key].host || data.val()[key].mainHost);
      }
      await setHost(temp);
    });
    
    return () => {

    }
  }, [dbRef]);

  useEffect(() => {
    if (conferenceId) initHost(conferenceId);
  }, [conferenceId]);

  const btnStyle = isTeleprompter ?
    {
      transform : `translate(0px, 0px)`,
      width: '20px',
      height: '30px',
      padding: '0px',
      // transition: 'all 0.5s',
    } : {
      transform: 'translate(40px, 0px)',
      width: '40px',
      height: '50px',
      padding: '0',
      // transition: 'all 0.5s'
    };
  const teleprompterStyle = isTeleprompter ? {
      zIndex: 2,
      height: `calc(100vh - 205px)`,
			transform: 'translate(0px, 0px)',
			transition: 'transform 0.5s'
		} : {
      zIndex: 2,
      height: `calc(100vh - 205px)`,
			transform: 'translate(-100%, 0px)',
			transition: 'transform 0.5s'
		};

  const canvasStyle = isTeleprompter ? {
      // background: '#e5e5e5',
      left: '16.7vw',
      width: 'calc(66.7vw - 0px)',
      transition: 'all 0.5s',
    } : {
      // background: '#e5e5e5',
      left: '0px',
      width: 'calc(83.3vw - 0px)',
      transition: 'all 0.5s',
    };

  const roomName = conference?.name ?? "Conference";

  if (loading || scenesLoading) {
    return <div className="loader">&nbsp;</div>;
  }

  return (
    <Layout
      titleBarContent={
        <div className="flex items-center gap-3">
          <h1 className="text-xl font-extrabold text-gray-800 tracking-loose">
            {roomName}
          </h1>
          {hostStatus[user?.uid] && <HeaderQuickControl conferenceId={conferenceId}/>}
        </div>
      }
    >
      <Helmet>
        <title>Conference Cloud - {roomName}</title>
      </Helmet>
      <div className="h-full w-full flex">
        <div
          ref={teleprompterRef}
          className="w-2/12  pl-3 pr-3 relative"
            style={teleprompterStyle}
        >
          {hostStatus[user?.uid] &&
            <button
              className={` h-10 absolute right-0 rounded p-2 py-2 ${isTeleprompter ? "top-9 bg-green-400 border-none": "top-0 w-12 bg-green-500"} `}
              style={btnStyle}
              onClick={() => {
                docRef.update({
                  teleprompter: {
                    ...conference?.teleprompter,
                    status: {
                      isOpen: !isTeleprompter
                    },
                  }
                });
                setIsTeleprompter(!isTeleprompter);
              }}
            >
              {!isTeleprompter && <TeleIcon className="w-10 h-10"/> }
              {isTeleprompter && <ArrowDrag className="w-6 h-6" style={{	transform: 'rotate(90deg)' }} /> }
            </button>
          }
        
          <Teleprompter
            isHost={hostStatus[user?.uid]}
            conferenceId={conferenceId}
            isTeleprompter={isTeleprompter}
            setIsTeleprompter={setIsTeleprompter}
            parentRef={teleprompterRef}
          />
        </div>
        <div ref={stageHolderRef} className=" p-5 flex flex-col gap-2 absolute  custom-bg-2 custom-shadow " style={canvasStyle}>
          
          <StreamEditorHolder
            room={room}
            conference={conference}
            user={user}
            scenes={scenes.filter((scene) => scene.id != 'editing_scene')}
            streamParticipants={streamParticipants}
            mySession={mySession}
            sessions={userSessions}
            currentState={currentState}
            isHost={hostStatus[user?.uid]}
            stageHolderRef={stageHolderRef}
          />
        </div>

        {!hostStatus[user?.uid] &&
          <div
            className="w-2/12 bg-white shadow-lg flex flex-col pt-2 absolute right-0 bottom-0 top-12"
            style={{height: 'calc(100vh - 210px)'}}
          >
            <ChatRoom />
          </div>
        }
        {hostStatus[user?.uid] &&
          <LayerEditorControls conference={conference} conferenceId={conferenceId} docRef={docRef} />
        }
      </div>
      <div
        className="z-3000 custom-bg-3 shadow-lg rounded-sm flex flex-col overflow-x-auto p-1
          absolute bottom-0 left-0 w-5/6 border-none"
        style={{width: '100vw', height: '160px'}}
      >
        <div className="flex justify-between">
          <h3 className="text-gray-300 font-extrabold text-xl leading-none ml-px py-1">
            Waiting Room
          </h3>
        </div>
        <div ref={attendeeRef} className="flex flex-wrap h-9/12 custom-bg-2 rounded scrollbar-1" style={{ height: '85%' }}>
          <AttendeesRoom
            localParticipant={room && room.localParticipant}
            // height={attendeeHeight}
            height={attendeeRef.current?.offsetHeight}
            sessions={userSessions}
            participants={waitingRoomParticipants}
            streamParticipants={streamParticipants}
            // userIsLive={currentState ? (mySession?.editingLive) : (mySession?.live)}
            userIsLive={mySession?.live}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ParticipantView;
