import React from 'react';
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { useFirebase } from "../helpers/hooks/useFirebase";
// import ccLogo from "../images/CC_logo.png";
import ccLogo from "../images/Voodoo Cast_logo_dark mode.svg";

// TODO
// - Register users / user sign up

const Register = ({ redirectToHome = false }) => {
  const storedEmail = window.localStorage.getItem("email");
  const history = useHistory();
  const firebase = useFirebase();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: storedEmail,
    },
  });
  const [error, setError] = useState();
	const [validate, setValidate] = useState(false);

	const handleChange = (e) => {
		const domain = e.target.value.split('@')[1];
		if ((domain) === 'nuvoodoo.com') setValidate(true);
		else setValidate(false);
	}

  const onSubmit = ({ email, password, firstName, lastName }) => {
    if (firebase) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(({ user }) => {
          setError(); // clear error out
					window.localStorage.setItem("email", email);

          firebase
            .firestore()
            .collection("profiles")
            .doc(user.uid)
            .set(
              {
								firstName,
								lastName,
                displayName: `${firstName[0].toUpperCase()}${firstName.substring(1)} ${lastName[0].toUpperCase()}`,
                email: user.email ?? "",
                photoUrl: user.photoUrl ?? "",
              },
              { merge: true }
            )
            .then(() => {
              if (redirectToHome) {
                history.push("/");
              }
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          if (error?.code) {
            switch (error.code) {
              case "auth/wrong-password":
              case "auth/email-already-in-use":
                setError("Email Already in Use");

                break;

              default:
                console.error(error);

                setError("Something went wrong.");
            }
          }
        });
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 shape-bg">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-32 w-auto"
          src={ccLogo}
          alt="Conference Cloud Logo"
        ></img>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
          <h2 className="mb-6 text-center text-3xl font-paytone-one text-gray-900">
            Join our Conference Cloud
          </h2>
          {error && (
            <div className="bg-red-200 border border-red-400 text-red-700 rounded px-2 m-3">
              {error}
            </div>
          )}
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
						<div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <div className="mt-1">
                <input
                  ref={register}
                  id="firstName"
                  name="firstName"
                  type="text"
                  autoComplete="firstName"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm"
                />
              </div>
            </div>

						<div>
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <div className="mt-1">
                <input
                  ref={register}
                  id="lastName"
                  name="lastName"
                  type="text"
                  autoComplete="lastName"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  ref={register}
                  id="email"
                  name="email"
                  type="email"
									onChange={handleChange}
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  ref={register}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <Link
                to="/login"
                className="font-medium blue p-1"
              >
								Already have an account?
              </Link>
            </div>

            <div>
              <button
                type="submit"
								// disabled={!validate}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gellow hover:bg-gellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gellow-500"
								style={!validate ? {cursor: 'not-allowed'}: {cursor: 'pointer'}}
              >
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
