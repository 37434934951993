import React from 'react';
import { useEffect, useState, useMemo, createContext } from "react";
import { useFirebase } from "../hooks/useFirebase";
import { nanoid } from "nanoid";
import { useDocumentQuery } from "../hooks/useFirebaseDocument";

export const AudioChannelContext = createContext({});

export const AudioChannelProvider = ({ children }) => {
  const [audioChannels, setAudioChannels] = useState(['On Stage', 'Back Stage', 'Cue']);
  const firebase = useFirebase();
	const firestore = useMemo(() => firebase.firestore(), [firebase]);
	const [currentConference, setConferenceId] = useState("");
	const [currentAudioChannel, _setCurrentAudioChannel] = useState();

	const db = useMemo(() => firebase.database(), [firebase]);

  const confRef = currentConference ? firestore.collection("conferences").doc(currentConference) : null;
  
	const updateChannel = (channel) => {
		confRef.update({
			currentAudioChannel: channel
		});
	};

  useEffect(() => {
		if (currentConference && confRef) {
			console.log(currentAudioChannel);
			confRef.get().then(snap => {
				const conf = snap.data();
				console.log(conf?.currentAudioChannel ? conf?.currentAudioChannel : 'On Stage', conf);
				_setCurrentAudioChannel(conf?.currentAudioChannel ? conf?.currentAudioChannel : 'On Stage');
			})
		}
  }, [currentConference]);

	const setCurrentAudioChannel = (channel) => {
		confRef.update({ currentAudioChannel: channel });
		_setCurrentAudioChannel(channel);
	};

  return (
    <AudioChannelContext.Provider
      value={{
				currentAudioChannel,
				setCurrentAudioChannel,
				audioChannels,
				setConferenceId
			}}
		>
			{children}
		</AudioChannelContext.Provider>
	);
};
