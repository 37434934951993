import React, { useEffect, useState } from "react";
import { useTrack } from "../../helpers/hooks/useTrack";
import Audio from "./audio";
import Video from "./video";

const Publication = ({ identity, width, height, z, publication, disableAudio = false, isLocalPrivate, isParticipantPrivate }) => {
  const track = useTrack(publication);
  const [style, setStyle] = useState({
    objectFit: "fill"
  });

  useEffect(() => {
    if (width && height) {
      setStyle({
        width: width,
        height: height,
        objectFit: "cover",
        zIndex: z,
      });
    }
  }, [width, height, z]);
  
  // don't render if no track
  if (!track) return null;

  switch (track.kind) {
    case "video":
      return <Video style={style} tracks={track} isRemote={true} />;
    case "audio":
      // if (disableAudio) return null;
      if (isParticipantPrivate && !isLocalPrivate) return null;
      return <Audio identity={identity} tracks={track} disableAudio={disableAudio}/>;
    default:
      return null;
  }
};

export default Publication;
