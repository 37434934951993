import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../helpers/context/authContext";
import { LocalTracksContext } from "../helpers/context/localTracksContext";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import TextLayer from './textLayer1';
import ImageLayer from './imageLayer';
import PPTLayer from './PPTLayer';
import VideoLayer from './videoLayer';
import StreamLayer from './streamLayer';
import LocalStreamLayer from './localStreamLayer';
import TimerLayer from './timerLayer';
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
import { useDocumentQuery } from "../helpers/hooks/useFirebaseDocument";

const Scene = ({
	id,
	participants,
	localParticipant,
	renderLocalParticipant,
	viewWidth,
	viewHeight,
	sessions,
	activeScene,
	activeLayer,
	setActiveLayer,
	semiActiveLayer,
	currentState,
	screenLocked,
	streamer,
	isHost=true,
	isStream=false,
	activeZone,
	isShowOverlay=true,
}) => {
	// START: awful stuff that shouldn't be here but firebase is awful at nested data
	const { user, hostStatus, setHost } = useContext(AuthContext);
	const { conferenceId } = useParams();
	const firebase = useFirebase();
	const firestore = useMemo(() => firebase.firestore(), [firebase]);
	const db = useMemo(() => firebase.database(), [firebase]);
	const docRef = firestore.collection("conferences").doc(conferenceId);
	// const virtualBackgroundRef = firestore.collection('virtualBackgrounds');
	// const [virtualBackgrounds, virtualBackgroundsLoading] = useCollectionQuery(virtualBackgroundRef, {
	//   idField: "id",
	// });
	const [conference, loading1] = useDocumentQuery(docRef);

	const { localTracks } = useContext(LocalTracksContext);
	const [editable, setEditable] = useState(() => {
		if (hostStatus) {
			if (user)
				return !screenLocked && hostStatus[user?.uid];
			else return false;
		} return false;
	});
	const { isBeingReposition, setIsBeingReposition, layersCollection } = useLayerEditorContext();

	useEffect(() => {
		setEditable(() => {
			if (hostStatus) {
				if (user)
					return !screenLocked && hostStatus[user?.uid];
				else return false;
			} return false;
		});
	}, [screenLocked, hostStatus, user, conferenceId]);

	const onSelectClick = (layer) => {
		if (layer.zone !== activeZone) return;
		if (layer.id === activeLayer) {
			setActiveLayer(undefined);
		} else {
			setActiveLayer && setActiveLayer(layer.id);
		}
	};

	const isSelected = (id) => id === activeLayer;

	const participantMap = new Map(
		participants.map((part) => [part.identity, part])
	);

	const goOffstage = (uid) => {
		const ref = db.ref(`${conferenceId}/status/${uid}`);
		
		// if (currentState) {
		// 	ref.update({
		// 		editingLive: false,
		// 	});
		// } else {
		// 	ref.update({
		// 		live: false,
		// 	});
		// }
		ref.update({
			live: false,
		});
	};

	const editingRef = firestore
		.collection("conferences")
		.doc(conferenceId)
		.collection("scenes")
		.doc('editing_scene')
		.collection(layersCollection);
	const activeRef = firestore
		.collection("conferences")
		.doc(conferenceId)
		.collection("scenes")
		.doc(id)
		.collection(layersCollection);
	
	const [ref, setRef] = useState((editable && currentState) ? editingRef : activeRef);

	const [layers, loading] = useCollectionQuery((editable && currentState) ? editingRef : activeRef, { idField: "id" });
	const scenesRef = firestore.collection("conferences").doc(conferenceId).collection("scenes");
	// const customFontsRef = scenesRef.doc(editable && currentState ? 'editing_scene' : id).collection("fonts");
	const customFontsRef = firestore.collection('fonts');
	const [_customFonts, customFontsLoading] = useCollectionQuery(customFontsRef, {
		idField: "id",
	});
	// const customFonts = _customFonts?.filter(font => font.userId === user?.uid);
	const customFonts = _customFonts;
	
	useEffect(() => {
		setRef(firestore
			.collection("conferences")
			.doc(conferenceId)
			.collection("scenes")
			.doc((editable && currentState) ? 'editing_scene' : id)
			.collection(layersCollection));
		firestore
		.collection("conferences")
		.doc(conferenceId).update({
			activeScene: activeScene,
		});
	}, [editable, currentState, layersCollection]);

	const mute = (participant, currentMute) => {
		if (!editable && participant?.identity !== localParticipant?.identity) return;
		db.ref(`${conferenceId}/status/${participant.identity}`).update({
			muted: !currentMute,
		});
	};

	useEffect(() => {
		if (participants && layers && isHost) {
			if (localParticipant) {
				ref.doc(localParticipant.identity).get().then(snap => {
					if (!snap.data()) {
						ref.doc(localParticipant.identity).set({
							id: localParticipant.identity,
							x: 0,
							y: 0,
							z: 0,
							height: 250,
							width: 444,
							type: "stream",
							zone: "participant",
						});
					} else if (!snap.data()?.zone) {
						ref.doc(localParticipant.identity).update({
							zone: "participant"
						});
					}
				});
			}
			participants.forEach((participant) => {
				const hasParticipantLayer = layers.filter(
					(layer) => layer.id === participant.identity
				);

				if (!hasParticipantLayer || hasParticipantLayer.length === 0) {
					ref.doc(participant.identity).set({
						id: participant.identity,
						x: 0,
						y: 0,
						z: 0,
						height: 250,
						width: 444,
						type: "stream",
						zone: "participant",
					});
				} else if (hasParticipantLayer.length > 0 && !hasParticipantLayer[0].zone) {
					ref.doc(participant.identity).update({
						zone: "participant"
					});
				}
			});
		}
	}, [participants, layers, isHost]);

	if (loading) return null;

	const handleDrag = (id) => (_, { x, y }) => {
		if (isBeingReposition !== id) setIsBeingReposition(id);
		try {
			ref.doc(id).update({
				x,
				y,
			});
		} catch (err) {
			console.log(err);
		}
	};

	const handleOnStop = () => {
		setIsBeingReposition(null);
	};

	const handleResize = ({ id, height, width }) => {
		if (isBeingReposition !== id) setIsBeingReposition(id);
		ref.doc(id).update({
			height,
			width,
		});
	};

	const handleBringForward = ({id, z}) => {
		ref.doc(id).update({
			z: z < 30 ? z + 1 : z
		});
	};

	const handleBringBack = ({id, z}) => {
		ref.doc(id).update({
			z: z > 0 ? z - 1 : z
		});
	};

	const handleTextEdit = (id, text) => {
		ref.doc(id).update({
			text,
		});
	};

	const setTime = (id, time, status = 1) => {
		try {
			ref.doc(id).get().then((snap) => {
				if (snap.exists) {
					ref.doc(id).update({
						currentTime: time,
						playStatus: status,
					})
				}
			})
		} catch (err) {
			console.log(err);
		}
	}

	const handleRemove = (id) => {
		ref.doc(id).delete();
	}

	const handlePlay = (id, event) => {
		ref.doc(id).update({
			playStatus: 1,
			currentTime: event.target.currentTime,
		});
	}

	const handlePause = (id, event) => {
		ref.doc(id).update({
			playStatus: 0,
			currentTime: event.target.currentTime,
		});
	}

	const stopTimer = (id) => {
		ref.doc(id).update({
			playStatus: 0,
			currentTime: 0,
		});
	};

	const handleVolumeChange = (id, event) => {
		ref.doc(id).update({
			muted: event.target.muted,
			volume: event.target.volume,
		});
	}

	const zoneNumber = (str) => {
		if (str === 'background')
			return 0;
		else if (str === 'participant')
			return 1;
		else if (str === 'foreground')
			return 2;
		else if (str === 'overlay')
			return 3;
		
		return -1;
	};

	const isVisibleOnZone = (layer) => {
		if (layer.zone === 'overlay')
			return isShowOverlay || isStream;

		if (layer.zone && zoneNumber(layer.zone) <= zoneNumber(activeZone) || !isHost || isStream) {
			return true;
		}

		return false;
	};

	// const kick = (uid) => {
	//   if (!editable) return;

	//   firestore
	//     .collection("conferences")
	//     .doc(conferenceId)
	//     .collection("participants")
	//     .doc(uid)
	//     .update({
	//       canJoin: false,
	//     });
	// };

	return [
		...layers.map((layer, idx) => {
			if (layer.type === "text" && isVisibleOnZone(layer)) {
				return (
					<TextLayer
						key={idx}
						{...layer}
						editable={editable && activeZone === layer.zone}
						onDrag={handleDrag}
						onResize={handleResize}
						selected={isSelected(layer.id)}
						hovered={semiActiveLayer === layer.id && !screenLocked && editable}
						hoveredLayer={layers?. filter(_=> _.id === semiActiveLayer && activeZone === _.zone)?.[0]}
						width={layer.width}
						height={layer.height}
						onClick={() => editable && onSelectClick(layer)}
						onTextEdit={(text) => handleTextEdit(layer.id, text)}
						viewWidth={viewWidth}
						viewHeight={viewHeight}
						x={layer.x}
						y={layer.y}
						customFonts={customFonts}
						onRemove={handleRemove}handleBringForward={handleBringForward}
						handleBringBack={handleBringBack}
						isBeingReposition={isBeingReposition}
						setIsBeingReposition={setIsBeingReposition}
						zoneIndex={zoneNumber(layer?.zone)}
						zone={layer?.zone}
					/>
				);
			}

			if (layer.type === "image" && isVisibleOnZone(layer)) {
				return (
					<ImageLayer
						key={idx}
						{...layer}
						editable={editable && activeZone === layer.zone}
						onDrag={handleDrag}
						onResize={handleResize}
						width={layer.width}
						height={layer.height}
						selected={isSelected(layer.id)}
						hovered={semiActiveLayer === layer.id && !screenLocked && editable}
						hoveredLayer={layers?. filter(_=> _.id === semiActiveLayer && activeZone === _.zone)?.[0]}
						onClick={() => editable && onSelectClick(layer)}
						viewWidth={viewWidth}
						viewHeight={viewHeight}
						x={layer.x}
						y={layer.y}
						isCustomRatio={layer.isCustomRatio}
						handleBringForward={handleBringForward}
						handleBringBack={handleBringBack}
						isBeingReposition={isBeingReposition}
						setIsBeingReposition={setIsBeingReposition}
						zoneIndex={zoneNumber(layer?.zone)}
						zone={layer?.zone}
					/>
				);
			}

			if (layer.type === "PPT" && isVisibleOnZone(layer)) {
				return (
					<PPTLayer
						key={idx}
						{...layer}
						editable={editable && activeZone === layer.zone}
						onDrag={handleDrag}
						onResize={handleResize}
						width={layer.width}
						height={layer.height}
						selected={isSelected(layer.id)}
						hovered={semiActiveLayer === layer.id && !screenLocked && editable}
						hoveredLayer={layers?. filter(_=> _.id === semiActiveLayer && activeZone === _.zone)?.[0]}
						onClick={() => editable && onSelectClick(layer)}
						viewWidth={viewWidth}
						viewHeight={viewHeight}
						x={layer.x}
						y={layer.y}
						isCustomRatio={layer.isCustomRatio}
						handleBringForward={handleBringForward}
						handleBringBack={handleBringBack}
						isBeingReposition={isBeingReposition}
						setIsBeingReposition={setIsBeingReposition}
						zoneIndex={zoneNumber(layer?.zone)}
						zone={layer?.zone}
						currentSlide={layer?.currentSlide}
					/>
				);
			}

			if (layer.type === "video" && isVisibleOnZone(layer)) {
				return (
					<VideoLayer
						key={idx}
						{...layer}
						editable={editable && activeZone === layer.zone}
						onDrag={handleDrag}
						onRemove={handleRemove}
						onResize={handleResize}
						onPlay={handlePlay}
						onPause={handlePause}
						onVolumeChange={handleVolumeChange}
						width={layer.width}
						height={layer.height}
						selected={isSelected(layer.id)}
						hovered={semiActiveLayer === layer.id && !screenLocked && editable}
						hoveredLayer={layers?. filter(_=> _.id === semiActiveLayer && activeZone === _.zone)?.[0]}
						onClick={() => editable && onSelectClick(layer)}
						viewWidth={viewWidth}
						viewHeight={viewHeight}
						x={layer.x}
						y={layer.y}
						isCustomRatio={layer.isCustomRatio}
						handleBringForward={handleBringForward}
						handleBringBack={handleBringBack}
						streamer={streamer}
						isBeingReposition={isBeingReposition}
						setIsBeingReposition={setIsBeingReposition}
						zoneIndex={zoneNumber(layer?.zone)}
						zone={layer?.zone}
					/>
				);
			}

			if (layer.type === "timer" && isVisibleOnZone(layer)) {
				return (
					<TimerLayer
						{...layer}
						editable={editable && activeZone === layer.zone}
						onDrag={handleDrag}
						onStop={handleOnStop}
						key={layer.id}
						onRemove={handleRemove}
						onResize={handleResize}
						width={layer.width}
						height={layer.height}
						selected={isSelected(layer.id)}
						hovered={semiActiveLayer === layer.id && !screenLocked && editable}
						hoveredLayer={layers?. filter(_=> _.id === semiActiveLayer && activeZone === _.zone)?.[0]}
						onClick={() => editable && onSelectClick(layer)}
						viewWidth={viewWidth}
						viewHeight={viewHeight}
						x={layer.x}
						y={layer.y}
						handleBringForward={handleBringForward}
						handleBringBack={handleBringBack}
						setTime={(time) => setTime(layer.id, time)}
						stopTimer={() => stopTimer(layer?.id)}
						endTime={layer.endTime}
						customFonts={customFonts}
						isBeingReposition={isBeingReposition}
						setIsBeingReposition={setIsBeingReposition}
						zoneIndex={zoneNumber(layer?.zone)}
						zone={layer?.zone}
					/>
				);
			}

			if (layer.type === "stream" && participantMap.has(layer.id) && isVisibleOnZone(layer)) {
				const participant = participantMap.get(layer.id);
				return (
					<StreamLayer
						key={idx}
						{...layer}
						onDrag={handleDrag}
						editable={editable && activeZone === layer.zone}
						participant={participant}
						onRemove={() => goOffstage(participant.identity)}
						onResize={handleResize}
						selected={isSelected(layer.id)}
						hovered={semiActiveLayer === layer.id && !screenLocked && editable}
						hoveredLayer={layers?. filter(_=> _.id === semiActiveLayer && activeZone === _.zone)?.[0]}
						onClick={() => editable && onSelectClick(layer)}
						width={layer.width}
						height={layer.height}
						viewWidth={viewWidth}
						viewHeight={viewHeight}
						currentAudioChannel={conference?.currentAudioChannel}
						x={layer.x}
						y={layer.y}
						mute={mute}
						streamer={streamer}
						sessions={sessions}
						handleBringForward={handleBringForward}
						handleBringBack={handleBringBack}
						isBeingReposition={isBeingReposition}
						setIsBeingReposition={setIsBeingReposition}
						zoneIndex={zoneNumber(layer?.zone)}
						zone={layer?.zone}
					/>
				);
			}

			if (renderLocalParticipant) {
				if (layer.type === "stream" && layer.id === user?.uid && isVisibleOnZone(layer)) {
					return (
						<LocalStreamLayer
							key={idx}
							{...layer}
							onDrag={handleDrag}
							editable={editable && activeZone === layer.zone}
							localParticipant={localParticipant}
							onRemove={() => goOffstage(user?.uid)}
							onResize={handleResize}
							selected={isSelected(layer.id)}
							hovered={semiActiveLayer === layer.id && !screenLocked && editable}
							hoveredLayer={layers?. filter(_=> _.id === semiActiveLayer && activeZone === _.zone)?.[0]}
							onClick={() => editable && onSelectClick(layer)}
							width={layer.width}
							height={layer.height}
							x={layer.x}
							y={layer.y}
							streamer={streamer}
							viewWidth={viewWidth}
							viewHeight={viewHeight}
							mute={mute}
							sessions={sessions}
							handleBringForward={handleBringForward}
							handleBringBack={handleBringBack}
							isBeingReposition={isBeingReposition}
							setIsBeingReposition={setIsBeingReposition}
							zoneIndex={zoneNumber(layer?.zone)}
							zone={layer?.zone}
						/>
					);
				}
			}

			return null;
		}),
		// <VideoLayer
		//   videoSrc={process.env.PUBLIC_URL + "/example.MP4"}
		//   width={100}
		//   height={100}
		//   onDrag={() => {}}
		// />,
	];
};

export default Scene;
