import dropboxUpload from './dropboxUpload';
import axios from 'axios';
import { nanoid } from "nanoid";

const isoUpload = ({
	participantStreams,
	isoDestination,
	folder1,
	folder2,
	folder3,
	mainProgress,
	setMainProgress,
	setChildProgress,
	dropboxAccessToken,
	googleAccessToken,
	setISOError,
	gapi,
	loadClientAuth2,
}) => {
	if (isoDestination.kind === 'dropbox') {
		return Array.from(participantStreams).map(_participant => {
			try {
				_participant.recorder.ondataavailable = null;
				_participant.recorder.stop();
			} catch (err) {
				console.log(err);
			}
			var blob = new Blob(_participant.chunks, {
				type: "video/mp4"
			});
			
			const fileName = `${_participant.name}-${new Date().getTime()}.mp4`;
			
			return dropboxUpload({
				file: blob,
				filename: `/${folder1}/${folder2}/${folder3}/${fileName}`, 
				progress: mainProgress,
				setProgress: setMainProgress,
				setChildProgress,
				dropboxAccessToken,
				setISOError
			}).then(() => {
				setMainProgress(mainProgress + 1);
				setChildProgress(0);
			}).catch(err => {
				console.error(err);
				setISOError("Check your Dropbox Access Token or Permission to write file.")
				return Promise.reject();
			});
		});
	} else if (isoDestination.kind === 'google') {
		const googleClientId = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;
		const scopes = 'https://www.googleapis.com/auth/drive.file';
		let gapiClient = loadClientAuth2(gapi, googleClientId, scopes);
		console.log(gapiClient);

		let url = 'https://www.googleapis.com/drive/v3/files';
		let headers = {
				'Authorization': 'Bearer ' + googleAccessToken,
				'Content-Type': 'application/json'
		};

		const listFoldersPromise = new Promise((resolve, reject) => axios.get(url, {
			headers: headers
		}).then(res => {
			console.log(res);
			resolve(res);
		}).catch(err => reject(err)));

		const createFolderPromise = (parent=["root"], folder) => new Promise((resolve, reject) =>{
			listFoldersPromise.then(res => {
				console.log(res);
				const preCreated = res.data.files.filter(_ => _.name === folder);
				if (preCreated && preCreated?.length > 0) {
					resolve(preCreated?.[0].id);
					return;
				}
				axios.get(`${url}/generateIds`, {headers:headers}).then(_ids => {
					console.log('avaiable ids', _ids);
					let fMetadata = {
						'name': folder, 
						'parents': [...parent], 
						'mimeType': 'application/vnd.google-apps.folder',
						'id': _ids.data.ids?.[0],
					};
					fetch(url, {
						method: 'POST',
						headers: headers,
						body: JSON.stringify(fMetadata)
					}).then((res) => {
						// console.log(res);
						// listFoldersPromise.then(_res => {
						// 	console.log(_res, folder);
						// 	const justCreated = _res.data.files.filter(_ => _.name === folder);
						// 	resolve(justCreated?.[0].id);
						// })
						resolve(_ids.data.ids?.[0]);
					})
					.catch(err => reject(err))
				})
				
			})
		})

		return [
			createFolderPromise(['root'], folder1).then((res1) => {
				createFolderPromise([res1], folder2).then((res2) => {
					createFolderPromise([res2], folder3).then((res3) => {
						const _pArray = Array.from(participantStreams).map(_participant => {
							try {
								_participant.recorder.ondataavailable = null;
								_participant.recorder.stop();
							} catch (err) {
								console.log(err);
							}
							var blob = new Blob(_participant.chunks, {
								type: "video/mp4"
							});
							
							const fileName = `${_participant.name}-${new Date().getTime()}.mp4`;
							const metadata = {
								name: `${fileName}`,
								mimeType: "video/mp4",
								parents: [res3]
							};
							
							const formData = new FormData();
							formData.append( "metadata", new Blob( [JSON.stringify( metadata )], {type: "application/json"} ));
							formData.append( "file", blob );
							
							return fetch( "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart", {
								method: "POST",
								headers: new Headers({ "Authorization": 'Bearer ' + googleAccessToken }),
								body: formData
							}).then( function( response ){
								setMainProgress(mainProgress + 1);
								return response.json();
							}).then( function( value ){
								console.log( value );
							});
						});
						const arrayP = new Promise((resolve, reject) => {
							return Promise.all(_pArray).then(resolve).catch(reject);
						});
						return arrayP;
					}).catch(err => Promise.reject(err));
				}).catch(err => Promise.reject(err));
			}).catch(err => Promise.reject(err))
		];
	}
};

export default isoUpload;
