import React, { useState } from "react";
import CopyField from "../../features/copyField";
import {ReactComponent as ChainIcon} from "../../icons/chain.svg";
import {ReactComponent as CloseIcon} from "../../icons/x-circle.svg";

const InviteLink = () => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="relative mr-2">
			<ChainIcon
				className="w-4 h-4 cursor-pointer text-gray-100 hover:text-yellow-400"
				onClick={() => setIsOpen(!isOpen)}
			/>
			{isOpen && <div className="flex gap-2 custom-bg-3 p-2 items-center rounded-lg absolute top-8 left-0 pr-5 shadow-md">
				<p className="font-medium">Invite:</p>
				<CopyField value={window.location.href.replace("/producer", "")} />
				<CloseIcon className="w-5 h-5 absolute right-0 top-0 cursor-pointer hover:text-red-400" onClick={() => setIsOpen(false)} />
			</div>}
		</div>
	)
};

export default InviteLink;
