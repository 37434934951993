import { darken } from "polished";
import React, { createRef, useState, useEffect } from "react";
import { TwitterPicker } from "react-color";
import Button from "../components/button";
import Modal from "../components/modal";
import { useOutsideClick } from "../helpers/hooks/useOutsideClick";

const StreamBackgroundModal = ({
  isOpen,
  onClose,
  onSubmit,
  initialColor = "",
}) => {
  const pickerRef = createRef();
  const [inputFocused, setInputFocused] = useState(false);
  const [color, setColor] = useState(initialColor);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      setImage();
      setColor();
    }
  }, []);

  useOutsideClick(pickerRef, () => {
    setInputFocused(false);
  });

  const handleColorChange = (color) => {
    setColor(color.hex);
    setInputFocused(false);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    await onSubmit({ color, image });
    setLoading(false);
    setImage();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-1/4">
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">
          Background Properties
        </h2>
      </div>
      <div className="p-2 flex">
        <div className="w-1/2 border-r border-gray-100 p-1 flex flex-col items-center">
          <h2 className="font-medium text-lg text-gray-300">
            Set Background Image
          </h2>
          <div style={{width: '100%'}}>
            <input
              id="img"
              name="img"
              className="rounded bg-none text-gray-300"
              type="file"
              onChange={(event) => setImage(event.target.files)}
            />
          </div>
        </div>
        <div className="w-1/2 flex flex-col items-center">
          <h2 className="font-medium text-lg text-gray-300">
            Set Background Color
          </h2>
          <div className="relative">
            <div
              onClick={() => setInputFocused(true)}
              className="flex items-center gap-1 uppercase cursor-pointer text-gray-300"
            >
              <div
                style={{
                  background: color ? color : "white",
                  border: `1px solid ${color ? darken(0.2, color) : "black"}`,
                }}
                className="w-4 h-4"
              ></div>
              {color ? color : "#ffffff"}
            </div>
            {inputFocused && (
              <div className="absolute -bottom-28" ref={pickerRef}>
                <TwitterPicker
                  color={color}
                  onChangeComplete={handleColorChange}
                />
              </div>
            )}
            <div className="text-xs mt-1 text-gray-300">(click to edit)</div>
          </div>
        </div>
      </div>
      <div className="flex flex-row-reverse p-2 border-t border-gray-400">
        <Button
          type="submit"
          className="text-gray-100 bg-gray-500 hover:bg-gray-400 rounded"
          onClick={!loading ? handleSubmit : null}
        >
          {loading ? '...uploading' : 'Submit'}
        </Button>
      </div>
    </Modal>
  );
};

export default StreamBackgroundModal;
