import React from "react";
import { useForm } from "react-hook-form";
import Button from "../button";
import InputField from "../forms/inputField";
import Modal from "../modal";

const SaveLayoutModal = ({ isOpen, onClose, onSubmit }) => {
  const { register, handleSubmit } = useForm();

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-1/4">
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">Save Layout</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="p-4">
        <InputField
          id="name"
          label="Name"
          labelStyle="text-gray-100"
          className="w-full"
          ref={register}
          required
        />
        <div className="flex flex-row-reverse mt-4">
          <Button type="submit" className="bg-gray-500 text-gray-100 hover:bg-gray-400">Save</Button>
        </div>
      </form>
    </Modal>
  );
};

export default SaveLayoutModal;
