import React, { useEffect, useMemo, useRef, useContext, useState } from "react";
import { LocalTracksContext } from "../../helpers/context/localTracksContext";

// TODO:
// - Improve loader css stability (sometimes creates too large of an element)

const Video = ({
  loading = false,
  tracks: providedTracks,
  isRemote,
  width,
  height,
  isLocal = false,
  className,
  disableAudio,
  ...props
}) => {
  const tracks = useMemo(
    () => (Array.isArray(providedTracks) ? providedTracks : [providedTracks]),
    [providedTracks]
  );
  
  const ref = useRef();
  const { localScreenShare } = useContext(LocalTracksContext);

  useEffect(() => {
    
    const el = ref.current;
    el.muted = !!isLocal || disableAudio;
    let newMediaStream = new MediaStream();
    tracks.forEach((track) => {
      if (track && track.enabled && track.readyState == 'live' && track.kind == 'video') {
        newMediaStream.addTrack(track);
        el.srcObject = newMediaStream;
        el.autoplay = true;
        el.playsInline = true;
      }
    });
    tracks.forEach((track) => {
      if (track && track.kind == 'video' && track.name && (!localScreenShare || isRemote)) {
        track.attach(el);
        el.autoplay = true;
        el.playsInline = true;
      }
      if (track && track.kind == "audio") {
        try {
          track.attach(el); 
        }
        catch (err) {
          console.log(err);
        }
      }
    });

    return () => {
      tracks.forEach((track) => {
        if (track && track.detach) track.detach(el);
      });
    };
  }, [tracks, ref, isLocal, localScreenShare]);

  useEffect(() => {
    try {
      const playPromise = ref.current.play();
      playPromise
        .then(() => {})
        .catch((err) => console.log(err));
    } catch (err) {
      console.log('will try again');
    }
  });

  let style = {
    background: "black",
  };

  if (width && height) {
    style = {
      width: width,
      height: height,
      objectFit: "cover",
      background: "black",
    };
  }

  return (
    <>
      <div className={`${loading ? "block" : "hidden"} loader`} />
      <video
        ref={ref}
        style={style}
        className={`${className ? className : ""} ${
          !loading ? "block w-full h-full" : "hidden"
        }`}
        {...props}
      />
    </>
  );
};

export default Video;
