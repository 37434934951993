export const timeFormat = (time) => {
	if (time <= 0 || time === undefined) return `HH : MM : SS`;
	let h = `${Math.floor(time / 3600)}`;
	if (h.length === 1) h = `0${h}`;
	time = time % 3600;
	let m = `${Math.floor(time / 60)}`;
	if (m.length === 1) m = `0${m}`;
	let s = `${Math.floor(time) % 60}`;
	if (s.length === 1) s = `0${s}`;
	return `${h} : ${m} : ${s}`;
};

export const defaultFonts = [
	{
		family: "Cabin", // Family Name
		styles: [
			"600..700", // Range, if family supports it.
			"100..200italic", // Range with italic
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500", // Regular with weight
			444, // Regular weight for variable font
		],
	},
	{
		family: "Roboto", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Futura", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Open Sans", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Lato", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Montserrat", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Oswald", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Slabo", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Raleway", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "PT Sans", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	{
		family: "Merriweather", // Family Name - Roboto doesn't support ranges
		styles: [
			"300italic", // Weight with italic
			"regular", // Shortcut to 400
			"italic", // Shortcut to 400 Italic
			"500",
			100,
		],
	},
	// {
	// 	family: "paytone-one", // Family Name - Roboto doesn't support ranges
	// 	styles: [
	// 		"300italic", // Weight with italic
	// 		"regular", // Shortcut to 400
	// 		"italic", // Shortcut to 400 Italic
	// 		"500",
	// 		100,
	// 	],
	// },
	// {
	// 	family: "pt-sans", // Family Name - Roboto doesn't support ranges
	// 	styles: [
	// 		"300italic", // Weight with italic
	// 		"regular", // Shortcut to 400
	// 		"italic", // Shortcut to 400 Italic
	// 		"500",
	// 		100,
	// 	],
	// }
];
