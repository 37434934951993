import React, {useState, useEffect} from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { PaymentElement, ElementsConsumer} from '@stripe/react-stripe-js';
import axios from "axios";
import { useFirebase } from "../../helpers/hooks/useFirebase";

const stripePromise = loadStripe('pk_test_51HpeE4Dflh72y32bB2M5UAj5X0ysTQAQYJuj9AQ5n5qwl0bV4VHUeCI9sBUA0TLMKJPccKSpwfCxRLQ0KNpv8MMY00Y1cc7HUY');
// const stripePromise = loadStripe('pk_live_51HpeE4Dflh72y32bWcF7tTi0CxqptcA1DOiFkZa5Rjr4AxbSBBS8LFlH24rNrL8UciY1b3Yg9k6sX2l18qG2MHL700YjuGFqun');

const CheckoutForm = ({stripe, elements, user, auth}) => {
  console.log(user);
  const firebase = useFirebase();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();


    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    elements.update();
    console.log(elements, elements.getElement('card'));

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "http://localhost:3000/",
      },
      redirect: "if_required"
    });
    
    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      console.log(user.uid);
      await firebase
        .firestore()
        .collection("profiles")
        .doc(user.uid)
        .set(
          {
            isSubscribed: true,
            // isAttached: false,
          },
          { merge: true }
        )
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="flex justify-between mt-3">
        <button className="border rounded px-2 py-1 my-1 bg-blue-400 text-white" disabled={!stripe}>Submit</button>
        <button
          className="underline"
          onClick={(e) => {
            e.preventDefault();
            auth?.signOut();
            window.location.href = '/login';
          }}
        >Back to Login</button>
      </div>
    </form>
  );
}

const Payment = ({ user, auth }) => {
	const [clientSecret, setClientSecret] = useState('');
	const [err, setErr] = useState();

	useEffect(() => {
		const getSecret = async () => {
      if (user && user?.uid && !user?.isAttached && !user?.isCompleted) {
        const data = {
          "userId": user?.uid
        };
        const url = "https://us-central1-conference-cloud-broadcaster.cloudfunctions.net/secret";
        // const url = "http://localhost:5000/secret";
        let response = await axios.post(url, {...data});
        if (response?.data?.client_secret) {
          setClientSecret(response.data.client_secret);
        } else {
          setErr('ERROR');
        }
      }
		}
		getSecret();
	}, [user]);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  return (
		<>
      {clientSecret && <Elements stripe={stripePromise} options={options}>
        {err && err}
        <ElementsConsumer>
          {({stripe, elements}) => (
            <CheckoutForm stripe={stripe} elements={elements} user={user} auth={auth}/>
          )}
        </ElementsConsumer>
      </Elements>}
		</>
  );
};

export default Payment;
