import { nanoid } from "nanoid";
import React, { useMemo, useState, useContext, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { Tab as ReactTab, TabList, TabPanel, Tabs } from "react-tabs";
import Button from "../components/button";
import Layout from "../components/layout/index.js";
import TabButton from "../components/tabButton";
import AuthCheck from "../features/authCheck";
import RecordingList from "../features/recordingList";
import CreateConferenceModal from "../features/createConferenceModal";
import DeleteConferenceModal from "../features/deleteConferenceModal";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { AuthContext } from "../helpers/context/authContext";
import { ReactComponent as LoginIcon } from "../icons/login.svg";
import { ReactComponent as Plus } from "../icons/plus.svg";
import { ReactComponent as TrashIcon } from "../icons/trash.svg";
import { ReactComponent as UserAddIcon } from "../icons/user-add.svg";
import { ReactComponent as CollectionIcon } from "../icons/collection.svg";
import { formatRelative, fromUnixTime } from "date-fns";
import TrashImg from "../icons/CC_icon_trash.png";

const AddAction = (props) => (
  <Button
    className="custom-bg-3 border-none text-gray-200"
    // className="flex gap-px font-bold text-gray-500 hover:text-gray-700 border-2 border-gray-400 hover:border-gray-700 px-4 py-px rounded-lg items-center"
    {...props}
  >
    <Plus className="w-5 h-5 cursor-pointer border border-gray-200 rounded-3xl mr-1" /> Add New
  </Button>
);

const SessionCard = ({ id, name, scheduledDate, onDelete }) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const history = useHistory();

  const handleDelete = () => {
    setConfirmDeleteOpen(false);

    onDelete();
  };

  return (
    <>
      {confirmDeleteOpen && (
        <DeleteConferenceModal
          key={id}
          isOpen={true}
          onClose={() => setConfirmDeleteOpen(false)}
          name={name}
          onSubmit={handleDelete}
        />
      )}
      <div className="flex gap-2 items-center">
        <div className="flex text-gray-200 hover:bg-gray-400 rounded p-2">
          <div style={{width: '35vw'}}>
            <div className="font-bold">{name}</div>
            <div className="text-gray-400 capitalize">
              {scheduledDate?.seconds
                ? formatRelative(
                    fromUnixTime(scheduledDate?.seconds),
                    new Date()
                  )
                : "Not Scheduled"}
            </div>
          </div>
          <div className="flex gap-2 ml-2 items-center">
            <Button
              className="flex custom-bg-3 text-gray-300 items-center gap-1"
              onClick={() => history.push(`/conference/${id}/producer`)}
            >
              <LoginIcon className="w-4 h-4" /> Enter
            </Button>
            <Button
              className="flex custom-bg-3 text-gray-300 items-center gap-1"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.href}conference/${id}`
                );
              }}
            >
              <UserAddIcon className="w-4 h-4" /> Invite
            </Button>
          </div>
          <button className="text-gray-400 hover:text-gray-700 flex items-center">
            {/* <TrashIcon
              className="w-6 h-6"
              onClick={() => setConfirmDeleteOpen(true)}
            /> */}
            <img
              onClick={() => setConfirmDeleteOpen(true)}
              className="w-6 h-6 min-w-6 h-4 ml-2 text-blue-400 hover:text-blue-200"
              src={TrashImg}
              alt="trash"
              style={{ minWidth: '15px' }}
            />
          </button>
        </div>
      </div>
    </>
  );
};

const SessionCardList = ({ children }) => {
  return (
    <>
      {children && 
        <div className="flex flex-col gap-2">{children}</div>
      }
      {!children &&
        <div className="flex flex-col justify-center items-center gap-2 w-72">
          <CollectionIcon className="w-10 h-10 text-gray-300" />
          <div className="font-semibold text-gray-400">No Sessions</div>
        </div>
      }
    </>
  )
};

const compare = (a, b) => {
  if (a.name < b.name)
    return -1;
  else if (a.name > b.name)
    return 1;
  
  return 0;
};


const Main = () => {
  const [createConferenceModalOpen, setCreateConferenceModalOpen] = useState(
    false
  );

  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);
  const [_conferences, _setConferences] = useState([]);
  const [_conferences1, conferencesLoading] = useCollectionQuery(
    firestore.collection("conferences"),
    {
      idField: "id",
    }
  );

  // console.log(_conferences);

  useEffect(() => {
    if (firestore && _setConferences) {
      firestore.collection("conferences").get().then(snapshot => {
        console.log(snapshot);
        _setConferences(Array.from(snapshot.docs).map(_snapshot => ({id: _snapshot.id, ..._snapshot.data()})));
      });
    }

    return () => {
      _setConferences();
    }
  }, [firestore, _setConferences]);

  const { user } = useContext(AuthContext);

  // const conferences = _conferences1?.filter(conference => conference.userId === user?.uid || conference.id === 'M_GzW7Y4QKWFFcvm9Gz-2').sort(compare);
  const conferences = _conferences1?.filter(conference => conference.userId === user?.uid).sort(compare);

  useEffect(() => {
    if (user) {
      firestore.collection('currentTime').doc('current_time').set({
        id: 'current_time',
        timestamp: new Date().getTime(),
      })
    }
  }, [user]);

  const handleDelete = (id) => {
    firestore.collection("conferences").doc(id).delete();
  };

  const handleConferenceCreate = ({ name, /*scheduledDate, rtmpUrl*/ }) => {
    const id = nanoid();

    firestore.collection("conferences").doc(id).set({
      name,
      userId: user?.uid,
      // scheduledDate,
      // rtmpUrl,
    });

    setCreateConferenceModalOpen(false);
  };

  if (conferencesLoading) {
    return <div className="loader">&nbsp;</div>;
  }

  return (
    <AuthCheck>
      {/* <Layout>
      {conferences?.map((conference, idx) => (
        <div>{conference?.name}</div>
      ))}
      </Layout> */}
      <Layout>
        <Helmet>
          <title>Conference Cloud</title>
        </Helmet>
        <CreateConferenceModal
          key="create-conference-modal"
          isOpen={createConferenceModalOpen}
          onClose={() => setCreateConferenceModalOpen(false)}
          onSubmit={handleConferenceCreate}
        />
        <Tabs
          className="p-12 w-2/3"
          selectedTabClassName="react-tabs__tab--selected"
        >
          <TabList className="flex gap-px">
            <TabButton el={ReactTab} className="h-10 text-lg text-gray-200">Schedule</TabButton>
            <TabButton el={ReactTab} className="h-10 text-lg text-gray-200">Recordings</TabButton>
          </TabList>
          <div className="rounded-b-xl rounded-r-xl w-full p-4 pt-10 pb-12 custom-bg-3 min-h-500">
            <TabPanel className="flex gap-3 w-full justify-around flex-wrap h-full">
              <div className="h-full">
                <div className={`flex ${conferences && conferences.length ? "justify-between" : "flex-col justify-center items-center min-h-400"} items-center mb-4`}>
                  <div>
                    <h3 className="text-2xl font-paytone-one text-gray-200">Studio Schedule</h3>
                  </div>
                  <AddAction
                    onClick={() => setCreateConferenceModalOpen(true)}
                  />
                </div>
                <SessionCardList>
                  {conferences?.map((conference, idx) => (
                    <SessionCard
                      key={idx}
                      {...conference}
                      onDelete={() => handleDelete(conference.id)}
                    />
                  ))}
                </SessionCardList>
              </div>
            </TabPanel>
            <TabPanel>
              <RecordingList />
            </TabPanel>
          </div>
        </Tabs>
      </Layout> 
    </AuthCheck>
  );
};

export default Main;
