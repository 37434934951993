
import { Dropbox } from "dropbox";

const dropboxUpload = ({
	file,
	filename,
	progress,
	setProgress,
	setChildProgress,
	dropboxAccessToken,
	setISOError,
}) => {
	try {
		var dbx = new Dropbox({ 
			accessToken: dropboxAccessToken
		});
		const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;

		if (file.size < UPLOAD_FILE_SIZE_LIMIT) {
			setChildProgress(1);
			return dbx.filesUpload({path: filename, contents: file})
					.then((response) => {
						console.log(response);
						setProgress(progress + 1);
					})
					.catch((error) => {
						setISOError("Check your Dropbox Access Token or Permission to write file.");
						console.log(error);
						return Promise.reject();
					});
		}
		const maxBlob = 8 * 1000 * 1000; // 8Mb - Dropbox JavaScript API suggested max file / chunk size

		var workItems = [];     
	
		var offset = 0;

		while (offset < file.size) {
			var chunkSize = Math.min(maxBlob, file.size - offset);
			workItems.push(file.slice(offset, offset + chunkSize));
			offset += chunkSize;
		}

		const task = workItems.reduce((acc, blob, idx, items) => {
			if (idx === 0) {
				// Starting multipart upload of file
				return acc.then(() => {
					setChildProgress((idx + 1) / items.length);
					return dbx.filesUploadSessionStart({ close: false, contents: blob})
										.then(response => {
											return response.result.session_id;
										}).catch(err => {
											setISOError("Check your Dropbox Access Token or Permission to write file.")
											return Promise.reject();
										});
				});          
			} else if (idx < items.length-1) {  
				// Append part to the upload session
				return acc.then((sessionId) => {
					var cursor = { session_id: sessionId, offset: idx * maxBlob };
					setChildProgress((idx + 1) / items.length);
					return dbx.filesUploadSessionAppendV2({ cursor: cursor, close: false, contents: blob }).then(() => sessionId); 
				});
			} else {
				// Last chunk of data, close session
				return acc.then((sessionId) => {
					var cursor = { session_id: sessionId, offset: file.size - blob.size };
					setChildProgress((idx + 1) / items.length);
					var commit = { path: filename, mode: 'add', autorename: true, mute: false };              
					return dbx.filesUploadSessionFinish({ cursor: cursor, commit: commit, contents: blob });           
				});
			}          
		}, Promise.resolve());
		
		return task.then(function(result) {
			console.log('File uploaded!');
		}).catch(function(error) {
			console.error(error);
			setISOError("Check your Dropbox Access Token or Permission to write file.");
		});
	} catch (err) {
		setISOError("Check your Dropbox Access Token or Permission to write file.")
		return Promise.reject();
	}
	
};

export default dropboxUpload;
