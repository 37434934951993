import styled from "@emotion/styled";
import React, { useContext, useState, useMemo } from "react";
import { ReactComponent as ChevronDownIcon } from "../../icons/chevron-down.svg";
import { ReactComponent as LogoutIcon } from "../../icons/logout.svg";
import Avatar from "../avatar";

const AvatarMenu = ({ user, auth }) => {

  const [isOpened, setIsOpened] = useState(false);

  const toggleOpen = () => {
    setIsOpened(!isOpened);
  };

  const handleLogout = async () => {
    await auth.signOut();
    window.location.href = '/';
  };

  if (!user) return null;

  return (
    <div className="relative">
      <button
        onClick={toggleOpen}
        className={`flex items-center custom-bg-3 p-2 rounded-t-lg ${isOpened ? "" : "rounded-b-lg"} hover:bg-gray-400`}
      >
        <Avatar url={user?.photoURL} />
        <div className="flex items-center ">
          {/* <span className="text-sm font-bold text-gray-700 w-full">
            {_user?.displayName ? _user?.displayName : (_user?.firstName && _user?.lastName) ? 
              `${_user?.firstName} ${_user?.lastName}` : _user?.email}
          </span> */}
          <ChevronDownIcon className="h-5 w-5 mt-px text-gray-200" />
        </div>
      </button>
      {isOpened && (
        <div className="absolute custom-bg-3 shadow-lg rounded-b-lg rounded-l-lg top-12 right-0 p-2">
          <button
            className="font-bold hover:bg-gray-50 w-full p-2 flex items-center justify-between gap-3 text-gray-300 rounded"
            onClick={handleLogout}
          >
            <LogoutIcon className="w-4 h-4" /> Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default AvatarMenu;
