import React, { createContext, useContext, useEffect, useState } from "react";
import { createLocalTracks, createLocalVideoTrack } from "twilio-video";
import DeviceSelection from "../../components/rtc/deviceSelection";
import { MediaDeviceContext } from "./mediaDeviceContext";
import { VirtualBackgroundProcessor, GaussianBlurBackgroundProcessor, isSupported } from '@twilio/video-processors';

export const LocalTracksContext = createContext({});

export const makeLocalTracks = (params) =>
  new Promise((resolve, reject) => {
    createLocalTracks(params).then(resolve, reject);
  });

export const LocalTracksProvider = ({ children }) => {
  const [localTracks, setLocalTracks] = useState();
  const [deviceSelectionOpen, setDeviceSelectionOpen] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [localScreenShare, setLocalScreenShare] = useState(false);
  const [localCameraTrack, setLocalCameraTrack] = useState(false);
  const [params, setParams] = useState({});
  const [isSharingIntent, setIntent] = useState(false);
  const [vbImage, setvbImage] = useState();
  let virtualBackground;

  const {
    videoDeviceId,
    audioInputDeviceId,
    audioOutputDeviceId,
    changeVideoDevice,
    changeAudioInputDevice,
    changeAudioOutputDevice,
  } = useContext(MediaDeviceContext);

  const handleDeviceSelectionClose = () => {
    setDeviceSelectionOpen(false);
  };

  const openDeviceSelection = () => {
    setDeviceSelectionOpen(true);
  };

  const closeDeviceSelection = () => {
    setDeviceSelectionOpen(false);
  };

  const rebuildLocalTracks = (_param) => {
    makeLocalTracks(_param)
      .then((tracks) => {
        if (vbImage) {
          virtualBackground(tracks, vbImage);
        } else {
          setLocalTracks(tracks);
        }
        setLoading(false);
        setError("");
      })
      .catch((err) => {
        if (err.message.indexOf('audio') >= 0)
          setError("We can't use your current audio input device, please select another Mic option.");
        if (err.message.indexOf('video') >= 0)
          setError("We can't use your current video input device, please select another Camera option.");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const rebuildTwilio = () => {
    rebuildLocalTracks(params);
  }

  useEffect(() => {
    const check = async () => {
      const deviceUsable = new Promise((resolve, reject) =>
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(() => resolve()).catch(err => reject(err)));
      await deviceUsable.then(() => {}).catch(err => console.log(err));
      
      // if (!localTracks && !loading) {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          const vidDevices = devices.filter((d) => d.kind === "videoinput");
          const micDevices = devices.filter((d) => d.kind === "audioinput");

          let tempParams = {};
          if (videoDeviceId === 'none')
            tempParams.video = false;
          else tempParams.video = videoDeviceId
            ? { deviceId: { exact: videoDeviceId } }
            : !!vidDevices?.length;
          
          if (audioInputDeviceId === 'none')
            tempParams.audio = false;
          else tempParams.audio = audioInputDeviceId
              ? { deviceId: { exact: audioInputDeviceId } }
              : !!micDevices?.length;
          
          setParams(tempParams);

          if (tempParams.video === false && tempParams.audio === false) {
            setError("There's no Mic and Cam");
            return;
          }

          setLoading(true);
          rebuildLocalTracks(tempParams);
        });
      // }
    };

    check();

    return () => {
      // setLocalTracks();
      setParams({});
    }
  }, [audioInputDeviceId, videoDeviceId]);

  useEffect(() => {
    if (error) {
      openDeviceSelection();
    } else {

    }
  }, [error]);

  useEffect(() => {
    const videoTrack = localTracks ? localTracks.find((track) => track?.kind === "video") : null;
  
    if (videoTrack) {
      if (videoDeviceId && videoDeviceId != 'none') {
        setLoading(true);

        videoTrack
          .restart({ deviceId: { exact: videoDeviceId } })
          .then (() => {
            virtualBackground(localTracks, vbImage);
            setError('');
            setError(false);
          })
          .catch (err => {
            setError("We can't use your current video input device, please select another Camera option.");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } 
    if (videoDeviceId === 'none' && !vbImage) {
      let tempParams = JSON.parse(JSON.stringify(params));
      tempParams = { ...tempParams, video: false };
      if (videoTrack) videoTrack.disable();
      setParams(tempParams);
      setLoading(true);
      rebuildLocalTracks(tempParams);
    } else if (videoDeviceId && !videoTrack && !vbImage) {
      let tempParams = JSON.parse(JSON.stringify(params));
      tempParams = { ...tempParams, video: { deviceId: { exact: videoDeviceId } } };
      setParams(tempParams);
      setLoading(true);
      rebuildLocalTracks(tempParams);
    }
  }, [videoDeviceId, vbImage]);

  useEffect(() => {
    const audioTrack = localTracks ? localTracks.find((track) => track?.kind === "audio") : null;
    if (audioTrack) {
      if (audioInputDeviceId && audioInputDeviceId != 'none') {
        audioTrack.restart({ deviceId: { exact: audioInputDeviceId } })
          .then(() => {
            setError('');
            setError(false);
          })
          .catch (err => {
            console.log(err);
            setError("We can't use your current audio input device, please select another Mic option.");
          })
          .finally (() => {
            setLoading(false);
          });
      }
    } 
    if (audioInputDeviceId == 'none') {
      let tempParams = JSON.parse(JSON.stringify(params));
      tempParams = { ...tempParams, audio: false };
      setParams(tempParams);
      setLoading(true);
      rebuildLocalTracks(tempParams);
    } else if (audioInputDeviceId && !audioTrack) {
      let tempParams = JSON.parse(JSON.stringify(params));
      tempParams = { ...tempParams, audio: { deviceId: { exact: audioInputDeviceId } } };
      setParams(tempParams);
      setLoading(true);
      rebuildLocalTracks(tempParams);
    }
  }, [audioInputDeviceId]);

  let virtualBackgroundProcessor;

  virtualBackground = async (tracks, imageUrl = '') => {
    if (imageUrl === '') {
      setLocalTracks(tracks);
      const videoTrack = tracks?.find(v => v?.kind === 'video');
      if (videoTrack?.processor) {
        videoTrack?.removeProcessor(videoTrack?.processor);
      }
      return;
    }

    const img = new Image();
    
    img.onload = () => {
      virtualBackgroundProcessor = new VirtualBackgroundProcessor({
        // assetsPath: 'http://localhost:5000/assets',
        assetsPath: 'https://restreamer.myconferencecloud.com/assets/',
        // assetsPath: 'https://stream.myconferencecloud.com/assets/',
        backgroundImage: img,
      });

      virtualBackgroundProcessor.loadModel().then(() => {
        
        const videoTrack = tracks.filter(_ => _.kind === 'video')?.[0];
        if (videoTrack?.processor) {
          videoTrack?.removeProcessor(videoTrack.processor);
        }
        videoTrack?.addProcessor(virtualBackgroundProcessor);
         
      });
    };
    img.crossOrigin = 'anonymous';
    img.src = imageUrl;
  };

  return (
    <LocalTracksContext.Provider
      value={{
        localTracks,
        acquiringTracks: loading,
        error,
        setLocalTracks,
        openDeviceSelection,
        videoDeviceId,
        audioInputDeviceId,
        audioOutputDeviceId,
        changeAudioInputDevice,
        changeAudioOutputDevice,
        changeVideoDevice,
        localScreenShare,
        setLocalScreenShare,
        localCameraTrack,
        setLocalCameraTrack,
        isSharingIntent,
        setIntent,
        closeDeviceSelection,
        virtualBackground,
        vbImage,
        setvbImage,
        rebuildTwilio,
      }}
    >
      <DeviceSelection
        isOpen={deviceSelectionOpen}
        message={error}
        setMessage={setError}
        // onClose={!error && handleDeviceSelectionClose}
        onClose={handleDeviceSelectionClose}
      />
      {children}
    </LocalTracksContext.Provider>
  );
};
