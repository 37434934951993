import React from "react";

const Button = ({ danger = false, children, className, ...props }) => (
  <button
    className={`${
      danger
        ? "bg-red-500 text-white hover:bg-red-600 focus:ring-offset-red-400 focus:ring-red-400 focus:z-10"
        : "hover:bg-gray-50 bg-white border-gray-400 focus:ring-offset-gray-100 focus:z-10"
    }  whitespace-nowrap focus:outline-none focus:ring-2 inline-flex items-center disabled:opacity-50 disabled:cursor-not-allowed ${
      className ? className : ""
    } ${className?.indexOf('px-') < 0 ? 'px-2' : ''} ${className?.indexOf('p-') < 0 ? 'p-2' : ''} 
     ${className?.indexOf('text-') < 0 ? 'text-gray-700' : ''} 
     ${className?.indexOf('h-') < 0 ? 'h-8' : ''} ${className?.indexOf('border') < 0 ? 'border' : ''}
     ${className?.indexOf('rounded') < 0 ? 'rounded-md' : ''} 
     ${className?.indexOf('justify') < 0 ? 'justify-center' : ''} 
     ${className?.indexOf('font') < 0 ? 'font-medium' : ''} 
     ${className?.indexOf('focus:ring') < 0 ? 'focus:ring-yellow-500' : ''} 
    `}
    {...props}
  >
    {children}
  </button>
);

export default Button;
