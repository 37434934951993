import React, { useContext, useMemo, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/context/authContext";
import { useFirebase } from "../helpers/hooks/useFirebase";
import EntryQueue from "./entryQueue";
import LocalVideoFeed from "./localVideoFeed";
import ParticipantVideoFeed from "./participantVideoFeed";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
import { useAudioChannelContext } from "../helpers/hooks/useAudioChannelContext";
import { ReactComponent as XCircle } from "../icons/x-circle.svg";
import { ReactComponent as Microphone } from "../icons/microphone2.svg";
import { LocalTracksContext } from "../helpers/context/localTracksContext";
import { ReactComponent as ScreenShare } from "../icons/screen-share.svg";
import { ReactComponent as CogIcon } from "../icons/cog.svg";
import { ReactComponent as Host } from "../icons/user.svg";
import ParticipantControl from "./participantControl";
// import BringTopModal from "./bringTopModal";

// TODO
// - fix svg color

const AttendeesRoom = ({
  sessions,
  localParticipant,
  participants,
  streamParticipants,
  height,
  userIsLive = false,
  producer = false,
}) => {
  const { user, hostStatus, auth, hostLavel, toggleHost, initHost } = useContext(AuthContext);
  const { conferenceId: confId } = useParams();
  const firebase = useFirebase();
  const db = useMemo(() => firebase.database(), [firebase]);
  const history = useHistory();
  const [currentParticipant, setCurrentparticipant] = useState(-1);
  const [editName, setEditName] = useState(-1);
  const [currentName, setCurrentName] = useState('');
  const [userLavel, setUserLavel] = useState({});
  const {
    localScreenShare,
    setLocalScreenShare,
    setIntent,
    openDeviceSelection,
  } = useContext(LocalTracksContext);

  const mySession = sessions?.[user?.uid];
  const { currentState, setEdited } = useLayerEditorContext();
  const { currentAudioChannel } = useAudioChannelContext();

  const [editable, setEditable] = useState(() => {
    if (hostStatus) {
      if (user)
        return hostStatus[user?.uid];
      else return false;
    } return false;
  });

  useEffect(() => {
    if (editName !== -1) {
      const inputs = document.querySelectorAll(".customer-name");
      
      for (let input of inputs) {
        input.focus();
      }
    }
  }, [editName]);

  useEffect(() => {
    setEditable(() => {
      if (hostStatus) {
        if (user)
          return hostStatus[user?.uid];
        else return false;
      } return false;
    });
    
    const _setUserLavel = async () => {
      // hostStatus && setIsHost(hostStatus[user?.uid]);
      let lavels = await hostLavel(confId);
      setUserLavel(lavels);
    };
    _setUserLavel();

  }, [hostStatus, user]);

  const toggleLive = (uid) => {

    const ref = db.ref(`${confId}/status/${uid}`);
    // if (currentState) {
    //   ref.update({
    //     editingLive: true,
    //   });
    //   setEdited(true);
    // } else {
    //   ref.update({
    //     live: true,
    //   });
    // }
    ref.update({
      live: true,
    });
  }

  const goLive = (uid) => {
    toggleLive(uid);

    if (producer || editable) {
      setCurrentparticipant(uid);
    }
  };

  const offLive = (uid) => {
    const ref = db.ref(`${confId}/status/${uid}`);
    // if (currentState) {
    //   ref.update({
    //     editingLive: false,
    //   });
    //   setEdited(true);
    // } else {
    //   ref.update({
    //     live: false,
    //   });
    // }
    ref.update({
      live: false,
    });
  };

  const kick = (participant) => {
    if (!producer) return;

    fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/removeParticipant`, {
      method: "POST",
      body: JSON.stringify({
        participant: participant.sid,
        room: confId,
        identity: participant.identity,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const mute = (participant, currentMute) => {
    if (!producer && !editable && participant?.identity !== localParticipant?.identity) return;
    if (!editable && participant?.identity !== localParticipant?.identity) return;
    const isYouInPrivate = currentAudioChannel.members ? (currentAudioChannel.members.some(m => m.id == user?.uid)) : false;
    const isPInPrivate = currentAudioChannel.members ? (currentAudioChannel.members.some(m => m.id == participant.identity)) : false;

    // if (isPInPrivate && !isYouInPrivate) return;
    db.ref(`${confId}/status/${participant.identity}`).update({
      muted: !currentMute,
    });
  };

  const customForamt = (str) => {
    if (str?.length > 15) {
      return str.substring(0, 15) + '...';
    }
    return str;
  };

  const handleNameChange = (id, name) => {
    db.ref(`${confId}/status/${id}`).update({
      name
    });
  };

  return (
    <div className="flex w-auto h-full">
      {!userIsLive && (
        <div style={{width: '180px'}}>
          <LocalVideoFeed
            localParticipant={localParticipant}
            className="w-full flex flex-wrap"
            onAction={() => goLive(user?.uid)}
            showAction={producer || editable}
            width={180}
            height={180 / 1920 * 1080}
            muted={mySession?.muted}
            onMute={() =>
              mute(localParticipant, sessions[localParticipant.identity]?.muted)
            }
          />
        </div>
      )}
      {userIsLive && (
        <div
          onClick={() => {
            if (editable || producer) offLive(user?.uid);
          }}
          className="p-1 cursor-pointer relative" style={{width: '180px', height: '101.25px'}}
        >
          {/* {(producer || editable) && ( */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                mute(localParticipant, sessions[localParticipant.identity]?.muted);
              }}
              className={`absolute bottom-0 right-0 ${
                sessions[localParticipant.identity]?.muted
                  ? "text-red-600 hover:text-red-400"
                  : "text-gray-300 hover:text-gray-400"
              } cursor-pointer z-30`}
            >
              <Microphone className="w-6 h-6 mr-2 mb-2" />
            </button>
          {/* )} */}
          <div className="absolute left-0 top-0 z-30">
            <button
              onClick={() => openDeviceSelection()}
              className="text-gray-300 hover:text-gray-400"
            >
              <CogIcon className="w-6 h-6 ml-1 mt-1 text-gray-400" />
            </button>
          </div>

          <button
            onClick={(e) => {
              e.stopPropagation();
              setLocalScreenShare(!localScreenShare);
              setIntent(true);
            }}
            className={`absolute bottom-0 left-0 ${
              localScreenShare
                ? "text-red-600 hover:text-red-400"
                : "text-gray-300 hover:text-gray-400"
            } cursor-pointer z-30`}
          >
            <ScreenShare className="w-6 h-6 ml-2 mb-2"/>
          </button>
          <div className="h-full border-solid border-2 border-black rounded flex justify-center items-center bg-gray white">
            {editName !== localParticipant.identity && <>
              <button
                className="rounded-md pl-2 pr-2 bg-gray-500 text-gray-100 hover:bg-blue-200"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditName(localParticipant.identity);
                  setCurrentName(customForamt(sessions[localParticipant.identity]?.name));
                }}
              >
                {customForamt(sessions[localParticipant.identity]?.name)}
              </button>
            </>}
            {editName === localParticipant.identity && <>
              <input
                className="w-32 customer-name ml-2 mr-2"
                value={currentName}
                onChange={(e) => setCurrentName(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    if (e.target.value === '') return;
                    handleNameChange(localParticipant.identity, e.target.value);
                    setEditName(-1);
                  }
                }}
                onBlur={(e) => {
                  setEditName(-1);
                  setCurrentName(customForamt(sessions[localParticipant.identity]?.name));
                }}
              />
            </>}
          </div>
        </div>
      )}
      {participants.map((participant, idx) => (
          <div key={idx} style={{width: '180px'}}>
            <ParticipantVideoFeed
              className="w-full flex flex-wrap"
              width={180}
              height={180 / 1920 * 1080}
              // key={participant.sid}
              keyIdx={`key-${idx}`}
              participant={participant}
              onAction={() => goLive(participant.identity)}
              // disableAudio={userIsLive}
              disableAudio={sessions[participant.identity]?.live}
              session={sessions[participant.identity]}
              showAction={producer || editable}
              onClose={(e) => {
                e.stopPropagation();
                kick(participant);
              }}
              onMute={(e) => {
                e.stopPropagation();
                mute(participant, sessions[participant.identity]?.muted)
              }}
            />
          </div>
      ))}
      {streamParticipants.map((participant, idx) => (
        <ParticipantControl
          idx={idx} editable={editable} producer={producer} offLive={offLive}
          participant={participant} kick={kick} mute={mute} sessions={sessions}
          hostStatus={hostStatus} userLavel={userLavel} toggleHost={toggleHost} 
          confId={confId} editName={editName} setEditName={setEditName} setCurrentName={setCurrentName}
          customForamt={customForamt} currentName={currentName} handleNameChange={handleNameChange}
        />
        // <div
        //   key={`${idx}-xyz`}
        //   onClick={() => {
        //     if (editable || producer) offLive(participant.identity)
        //   }}
        //   className="p-1 cursor-pointer relative" style={{width: '180px', height: '101.25px'}}
        // >
        //   {(producer || editable) && (
        //     <button
        //       onClick={(e) => {
        //         e.stopPropagation();
        //         kick(participant);
        //       }}
        //       className="absolute top-0 right-0 text-red-500 hover:text-red-400 cursor-pointer z-30"
        //     >
        //       <XCircle className="w-6 h-6 mr-2 mt-2" />
        //     </button>
        //   )}
        //   {(producer || editable) && (
        //     <button
        //       onClick={(e) => {
        //         e.stopPropagation();
        //         mute(participant, sessions[participant.identity]?.muted);
        //       }}
        //       className={`absolute bottom-0 right-0 ${
        //         sessions[participant.identity]?.muted
        //           ? "text-red-600 hover:text-red-400"
        //           : "text-gray-300 hover:text-gray-400"
        //       } cursor-pointer z-30`}
        //     >
        //       <Microphone className="w-6 h-6 mr-2 mb-2" />
        //     </button>
        //   )}
        //   {(producer || editable) && (
        //         <button
        //           // className="bg-white hover:bg-gray-100 rounded-md shadow-md py-1 px-3 z-40 font-medium text-gray-800"
        //           className={
        //             `absolute top-1 left-1 w-3 h-3
        //               ${hostStatus[participant.identity]
        //                 ? "text-red-600 hover:text-red-400"
        //                 : "text-white hover:text-gray-300"
        //               }
        //               cursor-pointer z-30`
        //             }
        //           onClick={(e) => {
        //             e.stopPropagation();
        //             toggleHost(confId, participant.identity)
        //           }}
        //         >
        //           <Host
        //             className="w-6 h-6 ml-1 mb-1"
        //           />
        //         </button>
        //   )}
        //   <div className="h-full border-solid border-2 border-black rounded flex justify-center items-center bg-gray white">
        //     {editName !== participant.identity && <>
        //       <button
        //         className="rounded-md pl-2 pr-2 bg-gray-500 text-gray-100 hover:bg-blue-200"
        //         onClick={(e) => {
        //           e.stopPropagation();
        //           if (producer || editable) {
        //             setEditName(participant.identity);
        //             setCurrentName(customForamt(sessions[participant.identity]?.name));
        //           }
        //         }}
        //       >
        //         {customForamt(sessions[participant.identity]?.name)}
        //       </button>
        //     </>}
        //     {editName === participant.identity && <>
        //       <input
        //         className="w-32 customer-name ml-2 mr-2"
        //         value={currentName}
        //         onChange={(e) => setCurrentName(e.target.value)}
        //         onClick={(e) => e.stopPropagation()}
        //         onKeyPress={(e) => {
        //           if (e.charCode === 13) {
        //             if (e.target.value === '') return;
        //             handleNameChange(participant.identity, e.target.value);
        //             setEditName(-1);
        //           }
        //         }}
        //         onBlur={(e) => {
        //           setEditName(-1);
        //           setCurrentName(customForamt(sessions[participant.identity]?.name));
        //         }}
        //       />
        //     </>}
        //   </div>
        // </div>
      ))}

      {producer && <EntryQueue className="w-2/12" />}
    </div>
  );
};

export default AttendeesRoom;
