import { useEffect, useRef } from "react";

export const isEqual = (a, b) => a?.isEqual?.(b) ?? false;

export const isInstance = (a, b) => a === b;

export const useComparatorRef = (value, isEqual, onValueChange) => {
  const ref = useRef(value);
  useEffect(() => {
    if (!isEqual(value, ref.current)) {
      ref.current = value;

      if (onValueChange) {
        onValueChange();
      }
    }
  });

  return ref;
};

export const useStableValueRef = (value, onChange) =>
  useComparatorRef(value, isEqual, onChange);

export const useStableIdentityRef = (value, onChange) =>
  useComparatorRef(value, isInstance, onChange);
