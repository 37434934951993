import React from "react";
import Button from "../components/button";
import Modal from "../components/modal";

const DeleteSceneModal = ({ isOpen, onClose, onSubmit, name }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-1/4 z-3000">
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center z-3000">
        <h2 className="font-medium text-xl text-gray-100">Delete Scene</h2>
      </div>
      <div className="p-4">
        <div className="text-gray-300 text-lg">
          Are you sure you want to delete scene,{" "}
          <span className="font-bold">{name}</span>?<br />
          This action cannot be undone.
        </div>
        <div className="flex flex-row-reverse mt-4 gap-4">
          <Button className="w-20" danger onClick={onSubmit}>
            Yes
          </Button>
          <Button className="w-20" onClick={onClose}>No</Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteSceneModal;
