import React from 'react';
import styled from "@emotion/styled";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ScrollToBottom from "react-scroll-to-bottom";
import TextareaAutosize from "react-textarea-autosize";
import { AuthContext } from "../helpers/context/authContext";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { ReactComponent as PaperAirplane } from "../icons/paper-airplane.svg";

// TODO:
// - Delete/Edit a message
// - Turn off chat
// - Kick a user out of chat / ban them
// - does not submit newlines
// - markup support?
// - emoji support?

const ChatContainer = ({
  children,
  className,
  justifyDirection = "between",
}) => (
  <div
    // style={{ height: "100vh" }}
    className={`${className ? className : ""} bg-cPurple-light w-full h-full`}
  >
    {children}
  </div>
);

const StyledScrollToBottom = styled(ScrollToBottom)`
  height: calc(100% - ${(props) => props.height}px);
`;

const ChatRoom = () => {
  const [inputOffset, setInputOffset] = useState(0);
  const formRef = useRef();
  const { register, handleSubmit, reset } = useForm();
  const firebase = useFirebase();
  const { user } = useContext(AuthContext);
  const { conferenceId } = useParams();

  const firestore = useMemo(() => firebase.firestore(), [firebase]);

  const docRef = firestore
    .collection("conferences")
    .doc(conferenceId)
    .collection("messages");

  const [messages, loading, error] = useCollectionQuery(
    docRef.orderBy("created", "asc").limit(100),
    {
      idField: "id",
    }
  );

  useEffect(() => {
    // this is here to trigger re-renders which cause the
    // dynamic styles to calculate the offsets correctly
    if (formRef.current) {
      const latest = formRef.current.offsetHeight;

      if (latest !== inputOffset) {
        setInputOffset(latest);
      }
    }
  });

  const onMessageSend = ({ message }) => {
    docRef.add({
      userId: user?.uid,
      name: user?.displayName || user?.email,
      message,
      created: new Date(),
    });

    reset();
  };

  const handleKeyDown = async (event) => {
    // enter events without a modifier should cause a submit
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault();
      handleSubmit(onMessageSend)();
    }

    // FIXME: I put this here to trigger recalc on form height
    // doesn't work in all cases. Should be improved.
    if (formRef.current) {
      const latest = formRef.current.offsetHeight;

      if (latest !== inputOffset) {
        setInputOffset(latest);
      }
    }
  };

  if (error) console.error(error);

  if (loading)
    return (
      <ChatContainer className="items-center" justifyDirection="center">
        <div className="loader">Loading...</div>
      </ChatContainer>
    );

  return (
    <ChatContainer>
      <StyledScrollToBottom height={inputOffset}>
        {messages.map(({ name, message, created, id }) => (
          <div className="mb-2 px-2 mt-px" key={id}>
            <div className="text-xs text-gray-400 font-bold mb-px tracking-tight">
              {name}
              <span className="font-light ml-1">
                {formatDistanceToNow(created.toDate())} ago
              </span>
            </div>
            <div className="bg-gray-100 rounded-lg p-2 text-gray-800 text-sm leading-4">
              {message}
            </div>
          </div>
        ))}
      </StyledScrollToBottom>
      <form
        ref={formRef}
        onSubmit={handleSubmit(onMessageSend)}
        className="bg-gray-50 w-full flex justify-between items-center"
      >
        <TextareaAutosize
          minRows={1}
          maxRows={3}
          name="message"
          ref={register}
          placeholder="Type your message..."
          className="resize-none w-full h-full border-none bg-transparent focus:outline-none focus:ring-transparent"
          onKeyDown={handleKeyDown}
        />
        <button className="p-2" type="submit">
          <PaperAirplane className="h-8 w-8 transform rotate-90 text-gray-600 hover:text-yellow-500" />
        </button>
      </form>
    </ChatContainer>
  );
};

export default ChatRoom;
