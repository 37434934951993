import styled from "@emotion/styled";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../helpers/context/authContext";
import { ReactComponent as ChevronDownIcon } from "../../icons/chevron-down.svg";
import { ReactComponent as LogoutIcon } from "../../icons/logout.svg";
// import Logo from "../../images/CC_logo.png";
import Logo from "../../images/Voodoo Cast_logo_dark mode.svg";
import Avatar from "../avatar";
import AvatarMenu from "./menu";

const Container = styled.div`
  width: 100%;
  height: calc(100% - 56px);
`;

const Layout = ({ children, titleBarContent }) => {
  const { user, auth } = useContext(AuthContext);

  const img = <img alt="logo" src={Logo} className="h-12 w-auto" />;

  return (
    <div className="w-full">
      <div className="custom-bg-1 h-14 p-2 flex justify-between items-center shadow-lg relative z-3000">
        <div className="flex items-center w-full">
          {!user?.isAnonymous ? <Link to="/">{img}</Link> : img}
          {titleBarContent && <div className="ml-4 mr-2 w-full">{titleBarContent}</div>}
        </div>
        {!user?.isAnonymous && <AvatarMenu user={user} auth={auth} />}
      </div>
      <Container className="flex justify-center w-full h-full custom-shadow custom-bg-2">
        {children}
      </Container>
    </div>
  );
};

export default Layout;
