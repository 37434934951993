import { useEffect, useState } from "react";
import { connect } from "twilio-video";
import { useTwilioToken } from "./useTwilioToken";

const makeRoom = ({ token, ...params }) =>
  new Promise((resolve, reject) => {
    connect(token, params).then(resolve, reject);
  });

export const useTwilioVideo = (
  { roomName, identity, roomParams = {} },
  onRoomAvailable
) => {
  const [room, setRoom] = useState();
  const [error, setError] = useState();
  const [participants, setParticipants] = useState([]);
  const token = useTwilioToken({ roomName, identity });

  const disconnect = () => {
    if (room) {
      room.disconnect(
        () => {
          console.log("Disconnected from the room.");
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  useEffect(() => {
    makeRoom({ token, ...roomParams })
      .then((conn) => {
        console.log(`Connected to room: ${conn}`);

        setRoom(conn);

        if (onRoomAvailable) {
          onRoomAvailable(room);
        }
      })
      .catch((err) => {
        setError(err);
      });

    window.addEventListener("beforeunload", () => {
      disconnect();
    });

    return () => {
      disconnect();
    };
  }, []);

  useEffect(() => {
    if (room) {
      setParticipants(Array.from(room.participants.values()));

      const addParticipant = (participant) => {
        participant.on('trackSubscribed', track => {
          console.log('new track subscribed', room.participants);
        });
        participant.on('trackUnsubscribed', track => {
          track.detach();
          console.log('a track unsubscribed', room.participants);
        });
        participant.on('trackAdded', track => {
          console.log('new track added', track);
        });
        setParticipants((prev) => [...prev, participant]);
      }
        
      const removeParticipant = (participant) =>
        setParticipants((prev) => prev.filter((p) => p !== participant));

      room.on("participantConnected", addParticipant);
      room.on("participantDisconnected", removeParticipant);

      return () => {
        room.off("participantConnected", addParticipant);
        room.off("participantDisconnected", removeParticipant);
      };
    }
  }, [room]);

  return [room, participants, error];
};
