import React, {useState} from 'react';
import { useEffect } from "react";
import { useFirebase } from "./useFirebase";
import { useLoading } from "./useLoading";

// can user be memoized? or reducer?

export const useFirebaseAuthState1 = () => {
  const fireInstance = useFirebase();

  const getValueFn = async () => {
    // if (!fireInstance.auth().currentUser)
    //   window.location.href = "/";
    return fireInstance.auth().currentUser;
  };

  const { error, loading, setError, setValue, value } = useLoading(getValueFn);

  useEffect(() => {
    const listener = fireInstance.auth().onAuthStateChanged(setValue, setError);

    return () => {
      listener();
    };
  }, []);

  return [value, loading, error, fireInstance.auth()];
};

export const useFirebaseAuthState = () => {
  const fireInstance = useFirebase();
  const [_value, _setValue] = useState();


  const getValueFn = async () => {
    // if (!fireInstance.auth().currentUser)
    //   window.location.href = "/";
    const _user = fireInstance.auth().currentUser;
    const _userP = new Promise((resolve, reject) => fireInstance.firestore().collection("profiles").doc(_user?.uid).onSnapshot(doc => {
      const userData = doc.data();
      resolve(userData)
    }, err => {
      reject(err);
    }));
    return await _userP.then(res => res);
    // return fireInstance.auth().currentUser;
  };

  const { error, loading, setError, setValue, value } = useLoading(getValueFn);

  useEffect(() => {
    if (!fireInstance) return;
    const _user = fireInstance.auth().currentUser;
    fireInstance.firestore().collection("profiles").doc(_user?.uid).onSnapshot(doc => {
      const userData = doc.data();
      _setValue({
        uid: _user?.uid,
        ...userData
      });
    }, err => {
      console.log(err);
    })
  }, [value]);

  useEffect(() => {
    const listener = fireInstance.auth().onAuthStateChanged(setValue, setError);

    return () => {
      listener();
    };
  }, []);

  return [_value, loading, error, fireInstance.auth()];
};
