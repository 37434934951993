import React from 'react';
import { useMemo } from "react";
import useSWR from "swr";
import { fetcher } from "../../fetcher";
import { useFirebase } from "../useFirebase";
import { useDocumentQuery } from "../useFirebaseDocument";

/**
 * Polls a service to see if a userId can join a given conferenceId at a refreshInterval.
 *
 * @param reqInfo Properties needed to formulate request body
 * @param reqInfo.conferenceId The conference ID the user is trying to join
 * @param reqInfo.userId The users ID
 * @param reqInfo.refreshInterval The polling interval to check if the user can join
 */
export const useCanJoinRoom = ({
  conferenceId,
  userId,
  refreshInterval = 1000,
}) => {
  const body = useMemo(() => ({ userId, conferenceId }), [
    userId,
    conferenceId,
  ]);
  const expiration = 24 * 3600 * 1000;
  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);
  const participantRef = firestore
    .collection("conferences")
    .doc(conferenceId)
    .collection("participants")
    .doc(userId);
  const [participantData] = useDocumentQuery(participantRef);

  const { data, error, loading } = useSWR(
    [`${process.env.REACT_APP_FUNCTIONS_URL}/isConferenceJoinable`, body],
    (url, data) =>
      fetcher(url, {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    { refreshInterval }
  );

  const checkExpiration = () => {
    const offset = new Date().getTimezoneOffset();
    if (new Date().getTime() + offset * 60 * 1000 < participantData?.actionTime + expiration && participantData?.canJoin === true)
      return true;
    else if (participantData?.canJoin) {
      participantRef.update({
        canJoin: false,
        deny: false,
        actionTime: 0,
      })
    } else if (new Date().getTime() + offset * 60 * 1000 > participantData?.actionTime + expiration && participantData?.deny) {
      participantRef.update({
        canJoin: false,
        deny: false,
        actionTime: 0,
      })
    }
    return false;
  }

  if (error) {
    console.log(error);
  }

  return [checkExpiration() && !!data?.result, loading, error];
};
