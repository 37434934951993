import { useRef } from "react";
import { jwt } from "twilio";

const AccessToken = jwt.AccessToken;
const VideoGrant = AccessToken.VideoGrant;

export const getTwilioToken = (roomName, identity) => {
  const ACCOUNT_SID = process.env.REACT_APP_TWILIO_ACCOUNT_SID;
  const API_KEY_SID = process.env.REACT_APP_TWILIO_SID;
  const API_KEY_SECRET = process.env.REACT_APP_TWILIO_SECRET;

  // Create an Access Token
  const accessToken = new AccessToken(ACCOUNT_SID, API_KEY_SID, API_KEY_SECRET);

  // Set the Identity of this token
  accessToken.identity = identity;
  // Grant access to Video
  const grant = new VideoGrant();
  grant.room = roomName;
  accessToken.addGrant(grant);

  // Serialize the token as a JWT
  const jwt = accessToken.toJwt();

  return jwt;
};

export const useTwilioToken = ({ roomName = "", identity = "" }) => {
  const ref = useRef(getTwilioToken(roomName, identity));
  return ref.current;
};
