import React, { useEffect, useState } from "react";
import Select from "../select";
import { Collapse } from "react-collapse";
import { ReactComponent as ArrowIcon } from "../../icons/chevron-down.svg";

const DeviceSelectionV = ({ 
  isOpen, onClose = () => {}, message = "", setMessage = () => {}, 
  videoDeviceId, loading, changeVideoDevice, videoDevices, setError,
}) => {

  return (
    <Collapse isOpened={isOpen}>
      <div
        className="flex h-full w-full items-center justify-center custom-bg-1"
      >
        {isOpen && 
          <button
            className="absolute custom-bg-3 rounded border border-gray-700 top-0 right-3 z-4000 text-black hover:text-black"
            onClick={onClose}
          >
            <ArrowIcon className="w-6 h-6" style={{transform: "rotate(180deg)"}} />
          </button>
        }
        <div
          className="rounded shadow-lg w-full custom-bg-3 flex flex-col p-4 relative z-3000"
          onClick={(e) => e.stopPropagation()}
        >
          {/* <div className={`${loading ? "loader" : "hidden"}`} ></div> */}
        
          <div>
            <Select
              id="videoDevice"
              label="Select Camera"
              labelStyle="text-gray-100"
              value={videoDeviceId}
              onChange={(e) => {
                setError('');
                changeVideoDevice(e.target.value);
              }}
            >
              {videoDevices.map((device) => (
                <option value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </option>
              ))}
              <option value="none" key="-1">No use</option>
            </Select>
          </div>
        </div>
      </div>
    </Collapse>
  );
};

export default DeviceSelectionV;
