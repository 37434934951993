import { nanoid } from "nanoid";
import React, { useMemo, useState, useContext, useEffect, useRef } from "react";
import Button from "../components/button";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { AuthContext } from "../helpers/context/authContext";
import { ReactComponent as CollectionIcon } from "../icons/collection.svg";
import { ReactComponent as MediaPlayIcon } from "../icons/media_play.svg";
import InputField from "../components/forms/inputField";
import TextareaField from "../components/forms/textareaField";
import { ReactComponent as RemoveIcon } from "../icons/trash.svg";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";

const compare = (a, b) => {
  if (a.name < b.name)
    return -1;
  else if (a.name > b.name)
    return 1;
  
  return 0;
};

const RecordingPlayer = ({currentRecording, onEdit, onPlay}) => {
  const ref = useRef();
  const [isShowPlayBtn, setIsShowPlayBtn] = useState(false);
  console.log(currentRecording);

  const handleMouseEnter = (e) => {
    e.stopPropagation();
    setIsShowPlayBtn(true);
  };

  const handleMouseLeave = (e) => {
    setIsShowPlayBtn(false);
  };

  return (
    <div className="pl-2 pr-2 flex flex-col w-full">
      <div
        className="relative flex justify-center items-center"
      >
        <button
          className="rounded-lg absolute z-20"
          onClick={onPlay}
        >
          <MediaPlayIcon className="text-red-500 w-12 h-12" />
        </button>

        {/* } */}
        <video
          ref={ref}
          src={currentRecording?.url}
          onMouseOver={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="w-80 h-40 bg-black rounded-md z-10"
        />
      </div>
    </div>
  )
};

const ConferenceRecordings = ({conference, currentRecording, setCurrentRecording, ...props}) => {
  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);
  const [recordings, recordingsLoading] = useCollectionQuery(firestore.collection("conferences").doc(conference?.id).collection('recordings'), {
    idField: "id",
  });
  
  useEffect(() => {
    if (props.index === 0 && !currentRecording && recordings && conference) {
      setCurrentRecording({conferenceId: conference?.id, room: conference?.name, ...recordings[0]});
    }
  }, [recordings, conference]);

  if (!recordings || recordings.length === 0) {
    return (<></>)
  }

  return (
    <>
      {recordings && recordings.map(record => (
        <tr
          key={record.id}
          className={`hover:bg-green-200 m-2 rounded-md cursor-pointer  }`}
          onClick={() => setCurrentRecording({...record, room: conference.name, conferenceId: conference.id})}
        >
          <td className={`flex flex-col ${currentRecording?.id === record.id ? "border border-gray-500 p-2 rounded" : "opacity-80"}`}>
            <div className="flex flex-col">
              <div className="text-left text-gray-100">Name: {record.name}</div>
              <div className="text-left italic text-sm text-gray-100">{new Date(record?.createdAt).toLocaleString()}</div>
            </div>
            <div className="flex flex-row">
              <span
                className="text-blue-400 cursor-pointer italic hover:text-blue-800"
                onClick={() => {
                  if (currentRecording && currentRecording?.id === record.id )
                    props.onEdit();
                }}
              >
                ...Edit
              </span>
              <span
                className="text-blue-400 ml-2 cursor-pointer italic hover:text-blue-800"
                onClick={() => {
                  if (currentRecording && currentRecording?.id === record.id )
                    props.onAddToScene(currentRecording.url);
                }}
              >
                ...Add to the scene
              </span>
              <div className="pr-3 flex items-center">
                <RemoveIcon
                  onClick={() => {
                    if (currentRecording && currentRecording?.id === record.id )
                    props.handleRemove();
                  }}
                  className="w-4 h-4 ml-2 text-gray-400 hover:text-gray-800"
                />
              </div>
              
            </div>
            
            {currentRecording && currentRecording?.id === record.id &&
              <RecordingPlayer
                currentRecording={currentRecording}
                setCurrentRecording={setCurrentRecording}
                onEdit={props.handleOnEditRecording}
                onPlay={props.handlePlay}
              />
            }
          </td>
        </tr>
      ))}
    </>
  )
};

const EditCurrentRecording = ({currentRecording, setCurrentRecording, isOpen, onClose, update}) => {
  const [name, setName] = useState(currentRecording?.name);
  const [description, setDescription] = useState(currentRecording?.description);
  const [error, setError] = useState('');
  console.log(currentRecording);

  useEffect(() => {
    if (isOpen) {
      setName(currentRecording.name);
      setDescription(currentRecording.description);
    } else {
      setName('');
      setDescription('');
    }
  }, [isOpen]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleUpdate = async (e) => {
    if (!name) {
      setError("Name field required");
      return;
    }
    setCurrentRecording({...currentRecording, name, description});
    await update(name, description);
    setName('');
    setDescription('');
    onClose();
  };

  if (isOpen) {
    return (
      <div className="fixed w-full h-full top-0 left-0 flex justify-center items-center" style={{zIndex: 100000}}>
        <div className="absolute w-full h-full top-0 left-0 bg-gray-400 opacity-70" onClick={onClose}></div>
        <div className="w-80 h-auto flex flex-col bg-white p-4 rounded-md"  style={{zIndex: 100000}}>
          {error && <div className="text-red-400 text-center">
            {error}
          </div>}
          <div>
            <InputField 
              defaultValue={currentRecording?.name}
              value={name} onChange={handleNameChange} label="name"
            />
          </div>
          <div className="pt-2">
            <TextareaField
              value={description}
              defaultValue={currentRecording?.description}
              onChange={handleDescriptionChange}
              label="description"
            />
          </div>
          <div className="pt-3 pl-8 pr-8 flex justify-between">
            <Button className="w-30" onClick={handleUpdate}>Upldate</Button>
            <Button className="w-30" onClick={onClose}>Cancel</Button>
          </div>
        </div>
        
      </div>
    )
  } else {
    return (<></>)
  }
};

const ConfirmToDeleteModal = ({isOpen, OnOk, OnCancel, currentRecording}) => {
  if (!isOpen) {
    return (<></>);
  } else if (isOpen) {
    return (
      <div className="fixed w-full h-full top-0 left-0 flex justify-center items-center" style={{zIndex: 100000}}>
        <div className="absolute w-full h-full top-0 left-0 bg-gray-400 opacity-70" onClick={OnCancel}></div>
        <div className="w-80 h-auto flex flex-col bg-white p-4 rounded-md relative"  style={{zIndex: 100000}}>
          <div className="bg-gray-200 h-5 absolute left-0 top-0 w-full" style={{borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}}></div>
          <p className="text-center mt-4">Confirm to Delete <label className="italic font-bold text-blue-400">{currentRecording.name}</label> ?</p>
          <div className="pl-4 pr-4 mt-3 flex justify-between">
            <Button className="w-30" onClick={OnOk}>Confirm</Button>
            <Button className="w-30" onClick={OnCancel}>Cancel</Button>
          </div>
        </div>
      </div>
    );
  }
}

const RecordingListEmbeded = ({ type='main', conference, docRef }) => {
  const [createConferenceModalOpen, setCreateConferenceModalOpen] = useState(
    false
  );

  const { layersCollection } = useLayerEditorContext();

  const [currentRecording, setCurrentRecording] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [isConfirmToDelete, setConfirmToDelete] = useState(false);

  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);
  const collectionRef = docRef.collection("scenes");
  const [_conferences, conferencesLoading] = useCollectionQuery(
    firestore.collection("conferences"),
    {
      idField: "id",
    }
  );

  const { user } = useContext(AuthContext);
  const [conferences, setConferences] = useState(_conferences?.filter(conference => conference.userId === user?.uid).sort(compare));

  useEffect(() => {
    setConferences(_conferences?.filter(conference => conference.userId === user?.uid).sort(compare));
    return () => {
      setConferences();
    }
  }, [_conferences, user]);

  const handleDelete = (id) => {
    firestore.collection("conferences").doc(id).delete();
  };

  const handleConferenceCreate = ({ name, /*scheduledDate, rtmpUrl*/ }) => {
    const id = nanoid();

    firestore.collection("conferences").doc(id).set({
      name,
      userId: user?.uid,
      // scheduledDate,
      // rtmpUrl,
    });

    setCreateConferenceModalOpen(false);
  };

  const handleOnEditRecording = (e) => {
    setIsEdit(true);
  };

  const handleOnRemove = () => {
    firestore.collection("conferences").doc(currentRecording?.conferenceId).collection('recordings').doc(currentRecording?.id).delete();
    setConfirmToDelete(false);
    setCurrentRecording('deleted');
  };

  const handleRemove = () => {
    setConfirmToDelete(true);
  }

  const handleEditRecording = (name, description) => {
    // setCurrentRecording({...currentRecording, name, description});
    firestore.collection("conferences").doc(currentRecording?.conferenceId).collection('recordings').doc(currentRecording?.id).set({
      name: name ? name : '',
      description: description ? description : ''
    }, { merge: true });
  };

  const createVideoLayer = (src = null) => {
    const id = nanoid();

    collectionRef.doc(conference.activeScene).collection(layersCollection).doc(id).set({
      id,
      type: "video",
      src,
      x: 0,
      y: 0,
      z: 3,
      volume: 1,
      muted: true,
      currentTime: 0,
      playStatus: 0,
      height: 250,
      width: 444,
    });

    return id;
  };

  const handlePlayVideo = (e) => {
    collectionRef.doc(conference.activeScene).update({
      playVideo: {
        url: currentRecording.url,
        state: true,
        currentTime: 0,
      }
    });
  };

  if (conferencesLoading) {
    return <div className="loader">&nbsp;</div>;
  }

  return (
		<>
			<EditCurrentRecording
				currentRecording={currentRecording}
				setCurrentRecording={setCurrentRecording}
				isOpen={isEdit}
				onClose={() => setIsEdit(false)}
				update={handleEditRecording}
			/>
			<ConfirmToDeleteModal
				isOpen={isConfirmToDelete}
				OnOk={handleOnRemove}
				OnCancel={() => setConfirmToDelete(false)}
				currentRecording={currentRecording}
			/>
			
			<div className={`flex justify-center gap-2 ${type !== "main" ? "flex-col" : ""}`}>
				<div
					className=" p-4 ml-1 mr-1 min-h-full"
				>
					<table className="min-w-full divide-y divide-gray-200">
						<tbody className="bg-cPurple divide-y divide-gray-200">
						{conferences?.map((conference, idx) => (
								<ConferenceRecordings
									key={idx}
									index={idx}
									conference={conference}
									currentRecording={currentRecording}
									setCurrentRecording={setCurrentRecording}
									onEdit={handleOnEditRecording}
									handleRemove={handleRemove}
                  handleOnEditRecording={handleOnEditRecording}
                  handlePlay={handlePlayVideo}
                  onAddToScene={(src) => createVideoLayer(src)}
								/>
						))}
						{!currentRecording && <div className="font-semibold text-gray-400 flex items-center w-full">
							<CollectionIcon className="w-10 h-10 text-gray-300 mr-3" />
							No Recordings
						</div>}
						</tbody>
					</table>
				</div>
			</div>
		</>
  );
};

export default RecordingListEmbeded;
