import React from 'react';
import Participant from "../components/rtc/participant";
import VideoFeed from "../components/rtc/videoFeed";
import VideoContainer from "../components/videoContainer";

const ParticipantVideoFeed = ({
  session,
  participant,
  onAction,
  width,
  height,
  z,
  onActionText = "Go Live",
  onClose = () => {},
  onMute = () => {},
  className,
  disableAudio = false,
  showAction = false,
  ...props
}) => {
  console.log(participant.identity);
  return (
    <VideoFeed
      onAction={onAction}
      onActionText={onActionText}
      className={className}
      showAction={showAction}
      {...props}
    >
      <VideoContainer
        onClose={onClose}
        onMute={onMute}
        key={participant.sid}
        identity={participant.identity}
        guestName={session?.name}
        muted={session?.muted}
        showAdminCommands={showAction}
        // width={width ? width : height / 1080 * 1920}
        width={width}
        height={height}
      >
        <Participant
          // width={width ? width : height / 1080 * 1920}
          identity={participant.identity}
          width={width}
          height={height}
          z={z}
          participant={participant}
          disableAudio={disableAudio}
        />
      </VideoContainer>
    </VideoFeed>
  );
};

export default ParticipantVideoFeed;
