import React, { useState } from "react";
import {ReactComponent as CloseIcon} from "../../icons/x-circle.svg";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";
import { ReactComponent as DesktopIcon } from "../../icons/desktop_1.svg";
import { ReactComponent as MobileIcon } from "../../icons/mobile.svg";
import Button from "../button";
import InputField from "../forms/inputField";

const BroadcastSetting = ({
	destinations=[],
	setDestinations,
	user,
	RTMPUrl,
	RTMPKey,
	setRTMPKey,
	setRTMPUrl,
	setIsOpen
}) => {
	const [isShowAdding, setIsShowAdding] = useState(false);
	const [currentSetting, setCurrentSetting] = useState(false);
	const [newDestination, setNewDestination] = useState({
		kind: '', url: '', key: '', device: 'desktop'
	});

	const handleNewServernameChange = (e) => {
		setNewDestination({
			...newDestination, kind: e.target.value,
		});
	};

	const handleNewUrlChange = (e) => {
		setNewDestination({
			...newDestination, url: e.target.value,
		});
	};

	const handleNewKeyChange = (e) => {
		setNewDestination({
			...newDestination, key: e.target.value,
		});
	};

	const handleNewDeviceChange = (device) => {
		setNewDestination({
			...newDestination, device: device
		});
	};

	const handleConfrimNew = (e) => {
		if (!newDestination?.kind) return;
		setDestinations([
			...destinations, newDestination
		]);
		setNewDestination({
			kind: '', url: '', key: '', device: 'desktop'
		});
		setIsShowAdding(false);
	}

	const handleUrlChange = (_destination, v) => {
		setDestinations(destinations?.map(_ => {
			if (_.kind === _destination?.kind) {
				return {
					..._destination, url: v
				}
			} else {
				return _;
			}
		}));
	};

	const handleKeyChange = (_destination, v) => {
		setDestinations(destinations?.map(_ => {
			if (_.kind === _destination?.kind) {
				return {
					..._destination, key: v
				}
			} else {
				return _;
			}
		}));
	};

	const handleDeviceChange = (_destination, device) => {
		setDestinations(destinations?.map(_ => {
			if (_.kind === _destination?.kind) {
				return {
					..._destination, device: device
				}
			} else {
				return _;
			}
		}));
	};

	const handleRemoveSetting = (_destination) => {
		setDestinations([...destinations?.filter(_d => _d.kind !== _destination.kind)]);
	};

	return (
		<div className="flex gap-2 custom-bg-3 p-2 items-center rounded-lg absolute top-10 right-0 pr-2 shadow-md w-64">
			<div
				className="pt-3 w-full"
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="options-menu"
			>
				{destinations && destinations?.map((_destination, idx) => (
					<div key={idx}>
						<div className="flex justify-between items-center">
								{/* <InputField
									type={"checkbox"}
									className="w-5 h-5 px-0 mt-0 mr-2"
									checked={destinations?.filter(_d => _d.kind === 'vimeo')?.length > 0}
									onChange={handleVimeoChange}
								/>  */}
								<Button
									className="relative px-2 my-1 w-full bg-gray-500 hover:bg-gray-400 text-gray-100 text-left justify-start mr-2 overflow-hidden"
									onClick={() => {
										setCurrentSetting(currentSetting === _destination?.kind ? null : _destination?.kind);
									}}
								>
									{_destination?.kind?.[0]?.toUpperCase() + _destination?.kind?.slice(1)}
									<span className="italic ml-2 text-xs text-gray-100 -mb-1 w-32 overflow-hidden">{_destination?.url + _destination?.key}</span>
									{/* {_destination?.device === 'desktop' && <DesktopIcon className="w-6 text-yellow-500 absolute right-0" />}
									{_destination?.device === 'mobile' && <MobileIcon className="w-6 text-yellow-500 absolute right-0" />} */}
								</Button>

							<Button
								className="w-5 h-5 rounded p-0 px-0 text-lg border-none custom-bg-3"
								onClick={() => handleRemoveSetting(_destination)}
							>
								<TrashIcon className="w-5 h-5 text-blue-500 cursor-pointer" />
							</Button>

						</div>
						{currentSetting === _destination?.kind &&
							<div className="block pl-4 pr-7 pb-2 text-sm text-gray-700">
								<div>
									<InputField
										className="w-full h-6"
										label="Endpoint"
										labelStyle="text-gray-100"
										value={_destination?.url}
										onChange={(e) => handleUrlChange(_destination, e.target.value)}
										placeholder="RTMP server url"
									/>
								</div>
								<div>
									<InputField
										className="w-full h-6"
										value={_destination?.key}
										label="Key"
										labelStyle="text-gray-100"
										onChange={(e) => handleKeyChange(_destination, e.target.value)}
										placeholder="RTMP server key"
									/>
								</div>
								{/* <div className = "flex px-2 pt-2">
									<DesktopIcon 
										className={`w-8 cursor-pointer border border-2 rounded mr-2 p-1 ${_destination?.device === 'desktop' ? 'text-yellow-600 border-blue-500' : 'text-black border-gray-700'}`} 
										onClick={(e) => handleDeviceChange(_destination, 'desktop')}
									/>
									<MobileIcon 
										className={`w-8 cursor-pointer border border-2 rounded p-1 ${_destination?.device === 'mobile' ? 'text-yellow-600 border-blue-500' : 'text-black border-gray-700'}`} 
										onClick={(e) => handleDeviceChange(_destination, 'mobile')}
									/>
								</div> */}
							</div>
						}
					</div>
				))}
				
				{!isShowAdding &&
					<>
						<div className="border-t border-gray-200 my-1"></div>
						<div className="flex justify-end">
							<Button
								className="border"
								onClick={() => {
									setIsShowAdding(true);
								}}
							>Add New</Button>
						</div>
					</>
				}
				
				{isShowAdding && 
					<div className="flex flex-col border border-gray-400 rounded p-2">
						
						<InputField
							className="w-full h-6"
							label={"Server Name"}
							labelStyle="text-gray-100"
							value={newDestination?.kind}
							onChange={handleNewServernameChange}
						/> 

						<InputField
							className="w-full h-6"
							label={"Url"}
							labelStyle="text-gray-100"
							value={newDestination?.url}
							onChange={handleNewUrlChange}
						/>

						<InputField
							className="w-full h-6"
							label={"Key"}
							labelStyle="text-gray-100"
							value={newDestination?.key}
							onChange={handleNewKeyChange}
						/> 
						{/* <div className = "flex px-2 pt-2">
							<DesktopIcon 
								className={`w-8 cursor-pointer border border-2 rounded mr-2 p-1 ${newDestination?.device === 'desktop' ? 'text-yellow-600 border-blue-500' : 'text-black border-gray-700'}`} 
								onClick={(e) => handleNewDeviceChange('desktop')}
							/>
							<MobileIcon 
								className={`w-8 cursor-pointer border border-2 rounded p-1 ${newDestination?.device === 'mobile' ? 'text-yellow-600 border-blue-500' : 'text-black border-gray-700'}`} 
								onClick={(e) => handleNewDeviceChange('mobile')}
							/>
						</div> */}
						<div className = "flex justify-between px-2 pt-2">
							<Button
								className="border rounded-md bg-gray-500 hover:bg-gray-400 text-gray-100"
								onClick={handleConfrimNew}
							> Confirm </Button>
							<Button
								className="border rounded-md"
								onClick={() => setIsShowAdding(false)}
							> Cancel </Button>
						</div>
					</div>
				}
				
			</div>
			<CloseIcon className="w-5 h-5 absolute right-0 top-0 cursor-pointer hover:text-red-400" onClick={() => setIsOpen(false)} />
		</div>
	)
};

export default BroadcastSetting;
