import React from 'react';
import { AuthContext } from "../helpers/context/authContext";
import { useFirebaseAuthState } from "../helpers/hooks/useAuth";
import { useHost } from "../helpers/hooks/useHost";

const UserSession = ({ children, loader = <p>Loading....</p> }) => {
  const [user, loading, error, auth] = useFirebaseAuthState();
  const [hostStatus, hostLavel, setHost, initHost, setMainHost, toggleHost] = useHost();

  if (loading) {
    return loader;
  }

  if (error) {
    console.error(error);

    throw error; // catch this with an ErrorBoundary
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        error,
        auth,
        hostStatus,
        hostLavel,
        setHost,
        initHost,
        setMainHost,
        toggleHost,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default UserSession;
