

import React from 'react';
import Button from "../button.js";
import InputField from "../forms/inputField";
import { ReactComponent as LinkIcon } from "../../icons/link.svg";
import { ReactComponent as SortAscendingIcon } from "../../icons/sort-ascending.svg";
import { ReactComponent as SortDescendingIcon } from "../../icons/sort-descending.svg";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";

const Z_LIMIT = 30;

const PPTLayerForm = ({
  z,
  src: url,
  width,
  height,
  id,
  updateLayer,
  removeLayer,
  currentSlide,
  endSlide,
}) => {
  const handleBringForward = () => {
    // don't go over Z_LIMIT
    updateLayer({
      id,
      data: { z: z < Z_LIMIT ? z + 1 : z },
    });
  };

  const handleSendBack = () => {
    // don't go negative
    updateLayer({
      id,
      data: { z: z > 0 ? z - 1 : z },
    });
  };

  const handleNextSlide = () => {
    updateLayer({
      id,
      data: { currentSlide: currentSlide < endSlide ? currentSlide + 1 : endSlide },
    });
  }

  const handlePrevSlide = () => {
    updateLayer({
      id,
      data: { currentSlide: currentSlide > 0 ? currentSlide - 1 : 0 },
    });
  };

  const handleDimensionUpdate = ({
    width: givenWidth,
    height: givenHeight,
  }) => {
    updateLayer({
      id,
      data: {
        width: parseInt(givenWidth ? givenWidth : width),
        height: parseInt(givenHeight ? givenHeight : height),
      },
    });
  };

  return (
    <div>
      <div className="w-full border-b border-gray-100 mb-4">
        <h3 className="text-gray-700 font-bold text-lg leading-relaxed">
          Power Point
        </h3>
      </div>
      <div className="h-82 bg-gray-50 rounded-lg p-1 mb-4 flex flex-col justify-between">

        <div>
          <h3 className="text-gray-700 font-bold text-lg mb-2">Current Slide</h3>
          <div className="flex gap-1 mb-2 w-2/3 justify-between items-center">
            <Button onClick={handlePrevSlide} className="w-12 text-xs px-1">
              Prev
            </Button>
            <span className="italic">Slide {currentSlide}</span>
            <Button className="w-12 text-xs px-1" onClick={handleNextSlide}>
              Next
            </Button>
          </div>
        </div>

        <div>
          <h4 className="text-gray-700 font-bold text-lg mb-2">Location</h4>
          <a href={url} target="_new">
            <div className="flex flex-row items-center hover:text-yellow-400">
              <LinkIcon className="flex-shrink-0 w-3 h-3 mr-1" />
              <p className="truncate">{url}</p>
            </div>
          </a>
        </div>
        <div>
          <h4 className="text-gray-700 font-bold text-lg mb-2">Dimensions</h4>
          <div className="flex gap-2">
            <div>
              <InputField
								className="w-20"
                label="Width"
                id="width"
                value={Math.floor(width * 1000) / 1000}
                type="number"
                onChange={(event) =>
                  handleDimensionUpdate({ width: event.target.value })
                }
              />
            </div>
            <div>
              <InputField
								className="w-20"
                label="Height"
                id="height"
                value={Math.floor(height * 1000) / 1000}
                type="number"
                onChange={(event) =>
                  handleDimensionUpdate({ height: event.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-gray-700 font-bold text-lg mb-2">Stacking</h3>
          <div className="flex gap-1 mb-2">
            <Button onClick={handleBringForward} className="w-1/2 text-xs px-1">
              <SortAscendingIcon className="w-4 h-4" />
              Bring Forward
            </Button>
            <Button className="w-1/2 text-xs px-1" onClick={handleSendBack}>
              <SortDescendingIcon className="w-4 h-4" /> Send Back
            </Button>
          </div>
          <span className="italic">Layer Level: {z}</span>
        </div>
      </div>
      <div>
        <Button danger className="w-full" onClick={() => removeLayer(id)}>
          <TrashIcon className="w-4 h-4 mr-px" />
          Remove
        </Button>
      </div>
    </div>
  );
};

export default PPTLayerForm;
