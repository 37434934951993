import { Resizable } from "re-resizable";
import React, {  useEffect, useRef } from "react";
import { install } from "resize-observer";
import { ReactComponent as XCircleIcon } from "../icons/x-circle.svg";
import Draggable from './draggable';
import Button from "../components/button";
import { ReactComponent as SortAscendingIcon } from "../icons/sort-ascending.svg";
import { ReactComponent as SortDescendingIcon } from "../icons/sort-descending.svg";
// TODO
// - Ratio resize?
install();

const ratio = 1920 / 1080;

const scaleDimensions = ({ viewWidth, viewHeight, width, height, isCustomRatio }) => {
  const widthPercentage = viewWidth / 1920;
  const heightPercentage = viewHeight / 1080;
  // const computedWidth = widthPercentage * (width > 0 ? width : 500);
  // const computedHeight = heightPercentage * (height > 0 ? height : 500);
  const computedHeight = heightPercentage * (height > 0 ? height : 500);
  const computedWidth = isCustomRatio ? widthPercentage * (width > 0 ? width : 500) : computedHeight * ratio;

  return {
    width: computedWidth,
    height: computedHeight,
  };
};

const deScaleDimensions = ({ viewWidth, viewHeight, width, height }) => {
  const widthPercentage = viewWidth / 1920;
  const heightPercentage = viewHeight / 1080;

  // const computedWidth = width / widthPercentage;
  // const computedHeight = height / heightPercentage;
  const computedHeight = height / heightPercentage;
  const computedWidth = computedHeight * ratio;

  return {
    width: computedWidth,
    height: computedHeight,
  };
};

const VideoLayer = ({
	onDrag,
	src,
	id,
	x = 0,
	y = 0,
	z = 0,
	height: givenHeight,
	width: givenWidth,
	onClick,
	onResize,
	onRemove,
	onPlay,
	onPause,
	volume,
	playStatus,
	muted,
	currentTime,
	onVolumeChange,
	selected,
	hovered,
	editable,
	viewWidth,
	viewHeight,
	isCustomRatio,
	handleBringForward,
	handleBringBack,
	streamer,
	isBeingReposition,
	setIsBeingReposition,
	zoneIndex,
	hoveredLayer,
	zone
}) => {
	const ref = useRef();
	const { width, height } = scaleDimensions({
		viewWidth,
		viewHeight,
		width: givenWidth,
		height: givenHeight,
		isCustomRatio
	});
	const isInHoveredLayer = () => {
		if (z > hoveredLayer?.z && hoveredLayer?.zone === zone) {
		  if (hoveredLayer?.x < x && hoveredLayer?.x + hoveredLayer?.width > x
			&& hoveredLayer?.y < y && hoveredLayer?.y + hoveredLayer?.height > y) {
			  return true;
			}
		  if (hoveredLayer?.x < x + givenWidth && hoveredLayer?.x + hoveredLayer?.width > x + givenWidth
			&& hoveredLayer?.y < y && hoveredLayer?.y + hoveredLayer?.height > y) {
			  return true;
			}
		  if (hoveredLayer?.x < x && hoveredLayer?.x + hoveredLayer?.width > x
			&& hoveredLayer?.y < y + givenHeight && hoveredLayer?.y + hoveredLayer?.height > y + givenHeight) {
			  return true;
			}
		  if (hoveredLayer?.x < x + givenWidth && hoveredLayer?.x + hoveredLayer?.width > x + givenWidth
			&& hoveredLayer?.y < y + givenHeight && hoveredLayer?.y + hoveredLayer?.height > y + givenHeight) {
			  return true;
			}
		}
		return false;
	  };

	const baseZ = isInHoveredLayer() ? 1500 : 0;
	
	const style = {
		position: "absolute",
		zIndex: isBeingReposition === id ? 2500 : hovered ? 2000 : selected ? 1500 + baseZ :  isInHoveredLayer() ? 2000 + z : 250 * zoneIndex + z,
	};

	const refV = useRef();
	
	useEffect(() => {
		try {
			if (playStatus)
				refV.current.play();
			else refV.current.pause();
			if (muted)
				refV.current.muted = true;
			else refV.current.muted = false;
			refV.current.volume = volume;
			refV.current.currentTime = currentTime;
		} catch (err) {
			console.log(err);
		}
	}, [refV, volume, playStatus, muted, currentTime,]);

	return (
		<Draggable
			id={id}
			onDrag={onDrag(id)}
			x={x}
			y={y}
			disabled={!editable}
			viewHeight={viewHeight}
			viewWidth={viewWidth}
			style={style}
			setIsBeingReposition={setIsBeingReposition}
		>
			<Resizable
				style={{
					position: "absolute",
					zIndex: isBeingReposition === id ? 2500 : hovered ? 2000 : selected ? 1500 + baseZ :  isInHoveredLayer() ? 2000 + z : 250 * zoneIndex + z,
				}}
				ref={ref}
				bounds="parent"
				handleClasses="handle"
				handleWrapperClass="re-handle"
				className={`inline-block absolute bg-red-400 ${
					(hovered || isBeingReposition === id) ? "border-2 border-yellow-500" : selected ? "border-2 border-red-500" : ""
				}`}
				enable={{
					top: false,
					right: true && editable,
					bottom: true && editable,
					bottomLeft: false,
					bottomRight: true && editable,
					topRight: false,
					topLeft: false,
				}}
				size={{ width, height }}
				lockAspectRatio={!isCustomRatio}
				onResizeStart={(e) => {
					e.stopPropagation();
					setIsBeingReposition(id);
				}}
				onResizeStop={(e, direction, ref, d) => {
					const deScale = deScaleDimensions({
						viewWidth,
						viewHeight,
						width: width + d.width,
						height: height + d.height,
					});
					const countedW =(width + d.width) / viewWidth * 1920;
					const countedH = (height + d.height) / viewHeight * 1080;
					onResize({
						id,
						width: isCustomRatio ? countedW : deScale.width,
						height: isCustomRatio ? countedH : deScale.height,
					});
					setIsBeingReposition(null);
				}}
				onClick={onClick}
			>
				<div
					// style={style}
					className="absolute w-full h-full cursor-move"
				>
					<div className="absolute w-full h-full">
						{/* {editable && (selected || hovered) && <button
							className="z-46 absolute -top-1 -right-1 bg-white rounded-full w-5 h-5 text-red-500 hover:text-red-400"
							onClick={() => onRemove(id)}
						>
							<XCircleIcon className="w-5 h-5" />
						</button>} */}
						<video
							ref={refV}
							crossOrigin="anonymous"
							className="video"
							autoPlay={false}
							controls={editable}
							onPause={(e) => onPause(id, e)}
							onPlay={(e) => onPlay(id, e)}
							muted={streamer}
							onVolumeChange={(e) => streamer?{}:onVolumeChange(id, e)}
							loop
							style={{
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								zIndex: isBeingReposition === id ? 2500 : hovered ? 2000 : selected ? 1500 + baseZ :  isInHoveredLayer() ? 2000 + z : 250 * zoneIndex + z
							}}
						>
							<source crossOrigin="anonymous" src={src} />
						</video>
					</div>
				</div>
				{/* {selected && <div className="absolute flex flex-col top-0 left-0 w-16 h-8">
					<div>
						<Button
							className="w-8"
							onClick={(e) => {
							e.stopPropagation();
							handleBringForward({id, z});
							}}
						>
							<SortAscendingIcon className="w-4 h-4" />
						</Button>
						<Button
							className="w-8"
							onClick={(e) => {
							e.stopPropagation();
							handleBringBack({id, z});
							}}
						>
							<SortDescendingIcon className="w-4 h-4" />
						</Button>
					</div>
					<span className="text-red-500 font-bold">level: {z}</span>
				</div>} */}
			</Resizable>
		</Draggable>
	);
};
  
export default VideoLayer;