import React from 'react';
import { useState, useMemo, useEffect } from "react";
import { useFirebase } from "./useFirebase";

export const useHost = () => {
	const firebase = useFirebase();
  const db = useMemo(() => firebase.database(), [firebase]);
	const [hostStatus, setHostStatus] = useState({});

	useEffect(() => {
		return () => {
			setHostStatus({});
		}
	}, []);
	
	const hostLavel = async (conferenceId) => {
		const ref = db.ref(`${conferenceId}/status`);

		const lavelPromise = new Promise((resolve, reject) => {
			let tempLavel = {};
			let caption;
			ref.get().then((data) => {
				for (let uid in data.val()) {
					if (data.val()[uid].mainHost)
						caption = 'admin host';
					else if (data.val()[uid].host)
						caption = 'co host';
					else caption = 'guest';
					tempLavel[uid] = caption;
				}
				resolve(tempLavel);
			});
		});

		return await lavelPromise.then((res) => {
			return res;
		});
	};

  const toggleHost = (conferenceId, uid) => {
		
    const ref = db.ref(`${conferenceId}/status/${uid}`);
    let temp = hostStatus ? JSON.parse(JSON.stringify(hostStatus)) : {};
		temp = { ...temp, [uid]: !!!temp[uid] }

		ref.update({
			host: temp[uid],
		});
	}

	const initHost = (conferenceId) => {
    const ref = db.ref(`${conferenceId}/status`);
		let temp = hostStatus ? JSON.parse(JSON.stringify(hostStatus)) : {};

    ref.get().then((data) => {
			for (let uid in data.val()) {
				if (uid)
					temp = { ...temp, [uid]: !!data.val()[uid].host || !!data.val()[uid].mainHost }
			}
			setHostStatus(temp);
		});
	}

	const setHost = async (value) => {
		let flag = false;
		for (let key in value) {
			if (value[key] != hostStatus[key]) {
				flag = true;
				break;
			}
		}
		if (flag) {
			await setHostStatus(value);
		}
	}

	const setMainHost = (conferenceId, uid) => {
		const ref = db.ref(`${conferenceId}/status/${uid}`);
		let temp = hostStatus ? JSON.parse(JSON.stringify(hostStatus)) : {};
		temp = { ...temp, [uid]: true }
    ref.update({
			mainHost: true,
			host: true,
		});
	}

  return [hostStatus, hostLavel, setHost, initHost, setMainHost, toggleHost];
};
