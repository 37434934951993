import React, { useEffect, useState } from "react";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";

const DropboxAuth = () => {
	// const { 
	// 	dropboxAccessToken, setDropboxAccessToken,
	// }
	// = useLayerEditorContext();

	const [dropboxAccessToken, setDropboxAccessToken] = useState();

	const [redirectUrl, setRedirectUrl] = useState('');

	const parseQueryString = (str) => {
		const ret = Object.create(null);

		if (typeof str !== 'string') {
			return ret;
		}

		str = str.trim().replace(/^(\?|#|&)/, '');

		if (!str) {
			return ret;
		}

		str.split('&').forEach((param) => {
			const parts = param.replace(/\+/g, ' ').split('=');
			// Firefox (pre 40) decodes `%3D` to `=`
			// https://github.com/sindresorhus/query-string/pull/37
			let key = parts.shift();
			let val = parts.length > 0 ? parts.join('=') : undefined;

			key = decodeURIComponent(key);

			// missing `=` should be `null`:
			// http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
			val = val === undefined ? null : decodeURIComponent(val);

			if (ret[key] === undefined) {
				ret[key] = val;
			} else if (Array.isArray(ret[key])) {
				ret[key].push(val);
			} else {
				ret[key] = [ret[key], val];
			}
		});

		return ret;
	};

	useEffect(() => {
		const _accessToken = parseQueryString(window.location.hash).access_token;
		console.log(parseQueryString(window.location.hash));
		setDropboxAccessToken(_accessToken);
		window.localStorage.setItem('dropboxAccessToken', _accessToken);
		setRedirectUrl(window.localStorage.getItem('dropbox_oauth_redirect'));
	}, []);

	return (
		<div className="flex justify-center items-center">
			{dropboxAccessToken && 
				<div className="flex flex-col justify-center items-center pt-20">
					<p className="text-4xl pb-5 text-blue-800">Dropbox Authentication Success</p>
					<p><span className="text-xl text-blue-600">Your Access Token: </span> <span className="font-bold italic">{dropboxAccessToken}</span></p>
					<a
						className="border w-20 pl-2 pr-2 rounded text-blue-700 text-center mt-6"
						href={redirectUrl}
					>
						Back
					</a>
				</div>
			}
			{!dropboxAccessToken && 
				<div className="flex flex-col justify-center items-center pt-20">
					<p className="text-4xl pb-5 text-red-800">Dropbox Authentication Failed</p>
					<a
						className="border w-20 pl-2 pr-2 rounded text-blue-700 text-center mt-6"
						href={redirectUrl?redirectUrl:'/'}
					>
						Back
					</a>
				</div>
			}
		</div>
	)
};

export default DropboxAuth;
