import React, { useState, useEffect } from "react";
import Button from "../components/button";
import Modal from "../components/modal";

const ConfirmCurrentChangeModal = ({ isOpen, onClose, onSubmit, caption="" }) => {

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-1/4">
      {/* <div className="bg-gray-100 w-full p-2 rounded-t-md border-b border-gray-200 flex items-center justify-center">
        <h2 className="font-medium text-xl text-gray-700 text-center">
          {caption ? '' : 'Do you confirm to commit?' }
        </h2>
      </div> */}
      <div className="bg-gray-700 h-8 rounded-t text-white pl-2 pt-1">

      </div>
      <div className="pt-4 flex flex-col items-center text-center justify-center">
        
        <div className="m-1 w-2/3 text-gray-100">
        {caption ? caption : 'Do you confirm to commit?' }
        </div>
        <div className="m-4 w-2/3">
          <Button
            onClick={() => {
              onSubmit();
              onClose();
            }}
            className="float-left"
          >
            Confirm
          </Button>
          <Button onClick={onClose} className="float-right">
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmCurrentChangeModal;
