
import React, { useState, useEffect } from "react";
import {ReactComponent as CloseIcon} from "../../icons/x-circle.svg";
import InputField from "../forms/inputField";
import { gapi, loadClientAuth2 } from 'gapi-script';
import { Dropbox } from "dropbox";

const ISOSetting = ({
	isISOSettingOpen,
	isISOSetting,
	setIsISOSettingOpen,
	handleISOChecked,
	isoDestination,
	handleISODestinationChange,
	dropboxAccessToken,
	handleDropboxAccessToken,
	setGoogleAccessToken
}) => {
	const dropboxClientId = `${process.env.REACT_APP_DROPBOX_CLIENT_KEY}`;
	const _dropboxAuthUrl = `${process.env.REACT_APP_DROPBOX_AUTH_URL}`;

	const googleClientId = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;
	const googleScopes = 'https://www.googleapis.com/auth/drive.file';
	const [dropboxAuthUrl, setDropboxAuthUrl] = useState();

	useEffect(() => {
		// const script = document.createElement("script");

    // script.src = "https://www.dropbox.com/static/api/2/dropins.js" 
		// script.id="dropboxjs";
		// script.data-app-key
    // script.async = true;

    // document.body.appendChild(script);
		// console.log(parseQueryString(window.location.hash).access_token);
		// setDropboxAuthUrl(parseQueryString(window.location.hash).access_token);
	}, []);
	
	useEffect(() => {
		
		let gapiClient = loadClientAuth2(gapi, googleClientId, googleScopes);
		const load = async () => {
			await gapi.load("auth2", async () => {
				const clientInit = gapi.auth2.getAuthInstance().signIn().then((authResult) => {
					// console.log('auth result: ', authResult.xc.access_token);
					return authResult.xc.access_token;
				}, (error) => {
					console.error(error);
					if (error) alert('An error occured while sigining.');
				});
				// const clientInit = window.gapi.auth2.init({
				// 	client_id: googleClientId,
				// 	fetch_basic_profile: true,
				// 	scope: googleScopes,
				// });
				await gapi.client.load('drive', 'v2', async () => { 
					const accessToken = await clientInit.then(res=>res);
					console.log('here finally the access token: ', accessToken);
					// handleAuthResult(clientInit) 
					setGoogleAccessToken(accessToken);
					if (accessToken) alert('Google Authentication Success.');
				});
			});
		};

		if (isoDestination.kind === 'dropbox') {
			const dbx = new Dropbox({ clientId: dropboxClientId });
			dbx.auth.getAuthenticationUrl(_dropboxAuthUrl).then(url => {
				window.localStorage.setItem('dropbox_oauth_redirect', window.location.href);
				setDropboxAuthUrl(url);
			})
		}
		else if (isoDestination.kind === 'google') {
			load();
		}
	}, [isoDestination]);
	// Setting the access token (expires in 3600s)

	return (
		<div className="flex flex-col gap-2 custom-bg-3 p-2 rounded-lg absolute top-10 right-0 pr-5 shadow-md w-64">
			<script type="text/javascript" src="https://www.dropbox.com/static/api/2/dropins.js" id="dropboxjs" data-app-key={dropboxClientId}></script>
			<div className="flex items-center text-lg text-gray-100">
				<InputField
					type={"checkbox"}
					className="w-5 h-5 px-0 mt-0 mr-2"
					checked={isISOSetting}
					onChange={handleISOChecked}
				/> Save ISO Files
			</div>
			{isISOSetting && 
				<div className="flex flex-col pl-3">
					<div className="flex justify-between items-center">
						<div className="flex items-center text-lg text-gray-100">
							<InputField
								type={"radio"}
								name="isoDesitnation"
								className="w-5 h-5 px-0 mt-0 mr-2"
								checked={isoDestination?.kind === 'dropbox'}
								onChange={() => handleISODestinationChange({
									kind: 'dropbox'
								})}
							/> Dropbox
						</div>
						
					</div>
					{isoDestination?.kind === 'dropbox' && 
						<div className="flex flex-col px-3 py-1 text-sm text-gray-100 hover:text-gray-200">
							<span>{dropboxAccessToken?'Authenticated': 'Unauthenticated'}</span>
							<a 
								href={dropboxAuthUrl} 
								className="border w-32 pl-2 pr-2 rounded text-gray-100"
								rel="noreferrer"
							>{dropboxAccessToken?'Re-Authenticate': 'Authenticate'}</a>
						</div>
					}

					<div className="flex justify-between items-center">
						<div className="flex items-center text-lg text-gray-100">
							<InputField
								name="isoDesitnation"
								type={"radio"}
								className="w-5 h-5 px-0 mt-0 mr-2"
								checked={isoDestination?.kind === 'google'}
								onChange={() => handleISODestinationChange({
									kind: 'google'
								})}
							/> Google Drive
						</div>
					</div>

					<div className="flex justify-between items-center">
						<div className="flex items-center text-lg text-gray-100">
							<InputField
								type={"radio"}
								name="isoDesitnation"
								className="w-5 h-5 px-0 mt-0 mr-2"
								checked={isoDestination?.kind === 'amazon'}
								onChange={() => handleISODestinationChange({
									kind: 'amazon'
								})}
							/> Amazon S3
						</div>
					</div>
					{isoDestination?.kind === 'amazon' && 
						<div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
							<div>
								<InputField
									fullWidth
									label="Server Url: "
								/>
							</div>
							<div>
								<InputField
									fullWidth
									label="Key"
								/>
							</div>
						</div>
					}
				</div>
			}
			<CloseIcon className="w-5 h-5 absolute right-0 top-0 cursor-pointer hover:text-red-400" onClick={() => setIsISOSettingOpen(false)} />
		</div>
	)
};

export default ISOSetting;
