import React, { useRef, useState, useEffect } from "react";
import axios from 'axios';



const PPTPlayer = ({id, src, currentSlide}) => {
	const reader = new FileReader();
	const frameRef = useRef();
	
  return (
		<>
			{/* <iframe
				id={`iframe_${id}`}
				ref={frameRef}
				title="ppt"
				src={`https://view.officeapps.live.com/op/embed.aspx?src=${src}`}
				width="100%"
				height="100%"
				frameBorder="0"
			>
			</iframe> */}
			<img src={src?.[currentSlide]} alt={src?.[currentSlide]} className="w-full h-full"/>
		</>
	)
}

export default PPTPlayer;
