import React from 'react';
import { default as fb } from "firebase";
import { useEffect, useMemo, useState } from "react";

export const useSelfPresence = ({ firebase, confId, user }) => {
  const db = useMemo(() => firebase.database(), [firebase]);
  const [ref, setRef] = useState();

  const online = {
    modified: fb.database.ServerValue.TIMESTAMP,
    name: user?.displayName || user?.email || "",
    live: false,
    editingLive: false,
    muted: false,
  };

  useEffect(() => {
    if (ref) {
      // ref?.delete();
    }

    setRef(db.ref(`${confId}/status/${user?.uid}`));
  }, [user]);

  useEffect(() => {
    if (ref) {
      // when a user disconnects, remove their record
      ref.onDisconnect().remove();

      // when they connect, make their record
      ref.update(online);
    }

    return () => {
      if (ref) {
        console.log(ref);
        ref?.remove();
      }
    };
  }, [ref]);
};

/**
 * Published the users own presence and gathers all users from the realtime
 * database that are associated with the conference.
 *
 * @param connectionProperties
 * @param connectionProperties.firebase The firebase instance to use
 * @param connectionProperties.confId The conference to apply presence to
 * @param user A firebase user instance
 */
export const usePresence = ({ firebase, confId, user }) => {
  const db = useMemo(() => firebase.database(), [firebase]);
  const ref = db.ref(`${confId}/status`);

  const [users, setUsers] = useState({});

  // updates the state on value changes
  const handleValues = (snapshot) => {
    if (snapshot.val() === false) {
      console.log('unexpected 0');
      setUsers({});
    }

    setUsers(snapshot.val());
  };

  useEffect(() => {
    ref.on("value", handleValues);

    return () => {
      console.log('unexpected');
      ref.off("value", handleValues);
    };
  }, []);

  return users;
};
