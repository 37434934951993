import React from 'react';
import { createContext, useState, useEffect, useRef, useMemo } from "react";
import { io } from "socket.io-client";

export const LayerEditorContext = createContext({});

export const LayerEditorProvider = ({ children }) => {
  const [isTeleprompter, setIsTeleprompter] = useState();
  const [isBeingReposition, setIsBeingReposition] = useState();
  const [activeLayer, setActiveLayer] = useState();
  const [semiActiveLayer, setSemiActiveLayer] = useState();
  const [textActive, setTextActive] = useState();
  const [currentState, setCurrentState] = useState(0);
  const [scenes, setScenes] = useState();
  const [activeScene, setActiveScene] = useState();
  const editingScene = { id: 'editing_scene', name: 'Editing Scene', layers: [] };
  const [edited, setEdited] = useState(false);
  // const [RTMPUrl, setRTMPUrl] = useState("rtmps://rtmp-global.cloud.vimeo.com:443/live/");
  // const [RTMPKey, setRTMPKey] = useState('7c393fcf-09b3-4d3e-9ba0-93abbc9ba2c4');
  const [RTMPUrl, setRTMPUrl] = useState("");
  const [RTMPKey, setRTMPKey] = useState('');
  // const mediaRecorder = useRef();
  const [mediaRecorder, setMediaRecorder] = useState();
  const backgroundImg = useRef();
  const drawId = useRef();
  const [sceneLocked, setSceneLock] = useState(false);
  const [recording, setRecording] = useState(null);
  const [localRecording, setLocalRecording] = useState(false);
  const isEditing = useRef();
  const [activeZone, setActiveZone] = useState('foreground');


	const [isISOSetting, setIsISOSetting] = useState();
	const [isoDestination, setISODestination] = useState();
  const [dropboxAccessToken, setDropboxAccessToken] = useState('');
  const [googleAccessToken, setGoogleAccessToken] = useState('');

  const [curDevice, setCurDevice] = useState('desktop');
  // const [layersCollection, setLayersCollection] = useState('layers');
  const layersCollection = curDevice === 'mobile' ? 'mobile_layers' : 'layers';


  const socketOptions = {
    secure: true,
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    timeout:150000000,
    pingTimeout: 15000,
    pingInterval: 45000,
    query: {framespersecond: 25, audioBitrate: 25}
  };

  // const broadcastSocket = io.connect("http://13.58.236.167:5000", socketOptions);
  // const broadcastSocket = io.connect("http://ec2-13-58-236-167.us-east-2.compute.amazonaws.com:5000/", socketOptions);
  // const broadcastSocket = io.connect("http://streamer.myconferencecloud.com:5000/", socketOptions);
  // const broadcastSocket = io.connect("http://localhost:5000/", socketOptions);
  // const broadcastSocket = io.connect("https://streamer.myconferencecloud.com/", socketOptions);
  const broadcastSocket = io.connect("https://restreamer.myconferencecloud.com/", socketOptions);
  // const broadcastSocket = io.connect("https://stream.myconferencecloud.com/", socketOptions);

  // const localRecordingSocket = io.connect("http://localhost:5000/", socketOptions);
  const localRecordingSocket = io.connect("https://restreamer.myconferencecloud.com/", socketOptions);
  // const localRecordingSocket = io.connect("https://stream.myconferencecloud.com/", socketOptions);

  window.onbeforeunload = function(e) {
    broadcastSocket.disconnect();
    localRecordingSocket.disconnect();
  };
  
  broadcastSocket.on("connect_error", (err) => {
    console.log('hi error', err);
    try {
      broadcastSocket.disconnect();
    } catch (err) {
      console.log(err);
    }
  });
  
  // try {
  //   broadcastSocket.disconnect();
  // } catch (err) {
  //   console.log(err);
  // }

  // try {
  //   localRecordingSocket.disconnect();
  // } catch (err) {
  //   console.log(err);
  // }

  const loadEditingScene = async (collectionRef, id) => {
    const layers = collectionRef.doc(id).collection(layersCollection);
    console.log(collectionRef.doc(id));
    collectionRef.doc(id).get().then(snapshot => {
      collectionRef.doc('editing_scene').update({
        background: {...snapshot.data().background},
        topZIndex: {...snapshot.data().topZIndex}
      });
    })
    setEdited(false);
    const loadPromise = new Promise((resolve, reject) => {
      collectionRef.doc('editing_scene').collection(layersCollection).get().then(query => {
        query.docs.map(doc => {
          collectionRef.doc('editing_scene').collection(layersCollection).doc(doc.id).delete();
        });
        
        layers.get()
          .then(querySnapshot => {
            const _loadPromise = Array.from(querySnapshot.docs).map(doc => 
              collectionRef.doc('editing_scene').collection(layersCollection).doc(doc.id).set({
                ...doc.data()
              }));
            Promise.all(_loadPromise).then(() => resolve()).catch((err) => reject(err));
          });
      });  
    });
    await loadPromise.then(() => {
      return editingScene;
    }).catch(err => { return null; });
    return editingScene;
  };

  // useEffect(() => {
  //   if (curDevice === 'mobile') {
  //     setLayersCollection('mobile_layers');
  //   } else {
  //     setLayersCollection('layers');
  //   }
  // }, [curDevice]);

  console.log(layersCollection);

  useEffect(() => {
    const isLocalRecording = window.localStorage.getItem('localRecording');
    const isBroadcasting = window.localStorage.getItem('broadcast');
    setLocalRecording(isLocalRecording === '1');
    setRecording(isBroadcasting === '1');

    const _isISOSetting = window.localStorage.getItem('isISOSetting');
    const _dropboxAccessToken = window.localStorage.getItem('dropboxAccessToken');
    const _isoDestination = window.localStorage.getItem('isoDestination');
    const _googleAccessToken = window.localStorage.getItem('googleAccessToken');
    const _rtmpUrl = window.localStorage.getItem('RTMPUrl');
    const _rtmpKey = window.localStorage.getItem('RTMPKey');

    setIsISOSetting(_isISOSetting === 'true' || _isISOSetting === true);
    setDropboxAccessToken(_dropboxAccessToken);
    setGoogleAccessToken(_googleAccessToken);
    setISODestination({
      kind: _isoDestination
    })
    setRTMPKey(_rtmpKey);
    setRTMPUrl(_rtmpUrl);
    
    return () => {
      setCurrentState(0);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('isISOSetting', isISOSetting);
  }, [isISOSetting]);

  useEffect(() => {
    window.localStorage.setItem('isoDestination', isoDestination?.kind);
  }, [isoDestination]);

  useEffect(() => {
    window.localStorage.setItem('dropboxAccessToken', dropboxAccessToken);
  }, [dropboxAccessToken]);

  useEffect(() => {
    window.localStorage.setItem('googleAccessToken', googleAccessToken);
  }, [googleAccessToken]);


  useEffect(() => {
    window.localStorage.setItem('RTMPUrl', RTMPUrl);
    window.localStorage.setItem('RTMPKey', RTMPKey);
    window.localStorage.setItem('rtmp', RTMPUrl + RTMPKey);
  }, [RTMPUrl, RTMPKey]);

  useEffect(() => {
    // console.log(mediaRecorder);
  }, [mediaRecorder]);

  

  return (
    <LayerEditorContext.Provider
      value={{
        activeLayer,
        setActiveLayer,
        semiActiveLayer,
        setSemiActiveLayer,
        currentState,
        setCurrentState,
        scenes,
        setScenes,
        activeScene,
        setActiveScene,
        editingScene,
        loadEditingScene,
        edited,
        setEdited,
        broadcastSocket,
        localRecordingSocket,
        RTMPUrl,
        setRTMPUrl,
        RTMPKey,
        setRTMPKey,
        mediaRecorder,
        setMediaRecorder,
        sceneLocked,
        setSceneLock,
        isEditing,
        recording,
        setRecording,
        localRecording,
        setLocalRecording,
        backgroundImg,
        drawId,
        textActive,
        setTextActive,
        isTeleprompter,
        setIsTeleprompter,
        isBeingReposition,
        setIsBeingReposition,
        isISOSetting,
        setIsISOSetting,
        dropboxAccessToken,
        setDropboxAccessToken,
        isoDestination,
        setISODestination,
        googleAccessToken,
        setGoogleAccessToken,
        activeZone,
        setActiveZone,
        curDevice,
        setCurDevice,
        layersCollection,
      }}
    >
      {children}
    </LayerEditorContext.Provider>
  );
};
