import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useDocumentQuery } from "../helpers/hooks/useFirebaseDocument";
import { ReactComponent as LockClosed } from "../icons/lock-closed.svg";
import { ReactComponent as LockOpen } from "../icons/lock-open.svg";

const RoomLock = () => {
  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);
  const { conferenceId } = useParams();

  const docRef = firestore.collection("conferences").doc(conferenceId);

  const [conferenceData, loading, error] = useDocumentQuery(docRef, {
    idField: "id",
  });

  const toggleLockRoom = () =>
    docRef.update({
      locked: !conferenceData.locked,
    });

  if (error) {
    console.log(error);
  }

  if (loading) {
    return (
      <button className="mx-2" disabled>
        Loading...
      </button>
    );
  }

  return (
    <button className="mx-2" onClick={toggleLockRoom}>
      {conferenceData?.locked ? (
        <LockClosed className="w-5 h-5 text-red-600 hover:text-red-500" />
      ) : (
        <LockOpen className="w-5 h-5 text-gray-600 hover:text-gray-500" />
      )}
    </button>
  );
};

export default RoomLock;
