import React, { useState, useEffect, useRef } from "react";
import { timeFormat } from "../utils/utils";
import CountdownTimeModal from "../features/countdownTimeModal";

const CountDownTimer = ({ endTime, setTime, stopTimer, onClick, zIndex, selected, editable, isTimerModal, setIsTimerModal, ...props }) => {
	const [timerMessage, setTimerMessage] = useState('');
	const [curTime, setCurTime] = useState();
	const timeout = useRef();
	
	useEffect(() => {
		clearTimeout(timeout.current);
		
		if (props.playStatus && endTime > new Date().getTime()) {
			const countdown = () => {
				setCurTime(Math.round((endTime - new Date().getTime()) / 1000));
				
				if (props.playStatus && endTime <= new Date().getTime()) {
					clearTimeout(timeout.current);
					stopTimer();
					setTimeout(stopTimer, 1000);
				} else {
					timeout.current = setTimeout(() => {
						countdown();
					}, 1000 - ((endTime - new Date().getTime()) % 1000));
				}
			}

			countdown();

		} else if (endTime === 0) {
			setCurTime(0);
		} else if (props.playStatus && endTime <= new Date().getTime()) {
			stopTimer();
			setTimeout(stopTimer, 1000);
		}
	}, [props.playStatus, endTime, setTime, stopTimer]);

	const handleSetTime = (t) => {
		setTime(t);
		setTimerMessage('');
	};

	const style = {
    position: "absolute",
    zIndex: selected || isTimerModal ? 40 : zIndex,
		// borderColor: selected?'red':'gray',
		// borderWidth: selected?'2px':'0px',
		background: props.backgroundColor,
		// width: props.width,
		// height: props.height,
  };
  
	const timerStyle = {
		fontFamily: props.fontFamily,
		color: props.textColor,
		fontSize: `${props.fontSize}px`,
		fontWeight: props.fontWeight,
		fontStyle: props.fontStyle,
		textDecoration: props.textDecoration,
		textAlign: props.textAlign,
	}

	return (
		<div
			className={`text-white p-2 rounded-md flex relative w-full h-full items-center`}
			style={style}
			onClick={onClick}
			// style={{position: '', right: 0, top: 0, zIndex: 10000, background: 'black', color: 'white'}}
		>
			<CountdownTimeModal
				isOpen={isTimerModal}
				onClose={() => {
					setIsTimerModal(false);
					props.setTextActive(null);
				}}
				setCountdownTime={handleSetTime}
				message={timerMessage}
			/>
			<div className="w-full" style={timerStyle}>
				{/* {timeFormat(props.currentTime)} */}
				{timeFormat(curTime)}
			</div>
			{/* {editable && selected && <button
				className="w-8 h-8 float-right text-red-500 rounded"
				onClick={() => {
					setIsTimerModal(true);
					props.setTextActive(props.id);
				}}
			>
				<EditIcon />
			</button>} */}
		</div>
	)
};

export default CountDownTimer;
