import React, { useState, useEffect, useRef } from "react";
import { DraggableCore } from "react-draggable";

const Draggable = ({
  id,
  children,
  isText = false,
  onStop,
  onDrag,
  position,
  viewHeight,
  viewWidth,
  bounds,
  disabled,
  isTimer,
  setIsBeingReposition,
}) => {
  const [flag, setFlag] = useState(false);
  const [x, setX] = useState(position.x);
  const [y, setY] = useState(position?.y ?? 0);
  const [firstX, setFirstX] = useState(0);
  const [firstY, setFirstY] = useState(0);
  const nodeRef = useRef();
  const ref = useRef();

  useEffect(() => {
    if (!flag) {
      setX(position?.x);
      setY(position?.y);
    }
  }, [position, flag]);

  useEffect(() => {
    setX(position.x);
    setY(position.y);
    return () => {
      setFlag(false);
      setX(0);
      setY(0);
      setFirstX(0);
      setFirstY(0);
    }
  }, []);

  const style = {
    top: `${flag ? y : position.y}%`,
    left: `${flag ? x : position.x}%`,
  };

  const onHandleStart = (event, details) => {
    // setFirstX(details.x);
    // setFirstY(details.y);
    setIsBeingReposition(id);
  };

  const onHandleDrag = (event, details) => {
    setFlag(true);
    setX((parseInt(firstX + details.deltaX) / viewWidth) * 100);
    setY((parseInt(firstY + details.deltaY) / viewHeight) * 100);
    setFirstX(firstX + details.deltaX);
    setFirstY(firstY + details.deltaY);
    // onDrag(null, { x, y });
  };

  const onHandleStop = (event, details) => {
    onDrag(null, { x, y });
    // 
    setFirstX(0);
    setFirstY(0);
    setTimeout(() => {
      setFlag(false);
      setIsBeingReposition(null);
    }, 10);
  };

  const onHandleMouseDown = (event) => {
    let temp = event.target;
    // while (temp.className != 'dragging') {
    while (!temp.className || typeof(temp.className) !== 'string' || (temp.className && temp.className.indexOf('dragging') < 0)) {
      temp = temp.parentElement;
    }
    setFirstX(temp.offsetLeft);
    setFirstY(temp.offsetTop);
    event.stopPropagation();
  };

  return (
    <DraggableCore
      onMouseDown={onHandleMouseDown}
      onStart={onHandleStart}
      onStop={onHandleStop}
      onDrag={onHandleDrag}
      bounds={bounds}
      disabled={disabled}
      nodeRef={nodeRef}
    >
      {/* <Component1 ref={nodeRef} /> */}
      <div
        id={id}
        className="dragging" 
        ref={ref} 
        style={{ position: 'absolute', ...style }}
      >
        {/* {React.cloneElement(React.Children.only(children), {
          style: { ...children.props.style, ...style },
        })} */}
        {children}
      </div>
    </DraggableCore>
  );
};

export default Draggable;
