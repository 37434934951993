import { useContext, useEffect } from "react";
import { LocalTracksContext } from "../context/localTracksContext";
import { useTwilioVideo } from "./useTwilioVideo";

export const useTwilioCall = ({
  roomName,
  identity,
  publishLocalTracks = true,
}) => {
  const { localTracks } = useContext(LocalTracksContext);

  const [room, participants] = useTwilioVideo({
    roomName,
    identity,
    roomParams: {
      video: false,
      audio: false,
    },
  });

  useEffect(() => {
    if (localTracks?.length && room && publishLocalTracks) {
      // publish local tracks to room once they become available
      console.log(room, room.localPariticipant, room.localParticipant.tracks);
      const unpublishPromise = Array.from(room.localParticipant.tracks.values()).map(track => {
        console.log(track);
        // if (track) return room.localParticipant.unpublishTrack(track);
        if (track) return track.unpublish();
        return Promise.resolve();
      });
      console.log(localTracks);
      Promise.all(unpublishPromise).then((values) => {
        room.localParticipant.publishTracks(localTracks.filter((track) => track !== undefined)).then(
          (e) => {
            console.log("Tracks published!", e);
          },
          (error) => {
            console.log(error, localTracks);
          }
        );
      });
    }
  }, [localTracks, room]);

  return [room, localTracks, participants];
};
