import './polyfills';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthCheck from "./features/authCheck";
import UserSession from "./features/userSession";
import FirebaseAppProvider from "./helpers/context/firebaseContext";
import { LocalTracksProvider } from "./helpers/context/localTracksContext";
import MediaDeviceProvider from "./helpers/context/mediaDeviceContext";
import { LayerEditorProvider } from "./helpers/context/layerEditorContext";
import { AudioChannelProvider } from "./helpers/context/audioChannelContext";
import "./index.css";
import Conference from "./pages/conference";
import ForgotPassword from "./pages/forgotPassword";
import Login from "./pages/login";
import Register from './pages/register';
import Main from "./pages/main";
import Producer from "./pages/producer";
import StreamRenderRTMP from "./pages/streamRenderRTMP";
import StreamRenderLocal from "./pages/streamRenderLocal";
import reportWebVitals from "./reportWebVitals";
import fireConfig from "./services/fireBaseConfig";
import DropboxAuth from "./pages/dropboxAuth";

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider config={fireConfig}>
      <MediaDeviceProvider>
        <LocalTracksProvider>
          <UserSession>
            <LayerEditorProvider>
              <AudioChannelProvider>
                <Router>
                  <Switch>
                    <Route path="/login">
                      <Login redirectToHome />
                    </Route>
                    <Route path="/register">
                      <Register redirectToHome />
                    </Route>
                    <Route path="/forgot-password">
                      <ForgotPassword />
                    </Route>
                    <Route path="/conference/:conferenceId/producer">
                      <Producer />
                    </Route>
                    <Route path="/conference/:conferenceId/stream_rtmp">
                      <AuthCheck allowAnon>
                        <StreamRenderRTMP />
                      </AuthCheck>
                    </Route>
                    <Route path="/conference/:conferenceId/stream_local">
                      <AuthCheck allowAnon>
                        <StreamRenderLocal />
                      </AuthCheck>
                    </Route>
                    <Route path="/conference/:conferenceId">
                      <AuthCheck allowAnon>
                        <Conference />
                      </AuthCheck>
                    </Route>
                    <Route path="/dropbox_auth">
                      <DropboxAuth />
                    </Route>
                    <Route path="/">
                      <Main />
                    </Route>
                  </Switch>
                </Router>
              </AudioChannelProvider>
              
            </LayerEditorProvider>
          </UserSession>
        </LocalTracksProvider>
      </MediaDeviceProvider>
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
