import { nanoid } from "nanoid";
import React, { useMemo, useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import Button from "../components/button";
import DeleteConferenceModal from "../features/deleteConferenceModal";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { AuthContext } from "../helpers/context/authContext";
import { ReactComponent as LoginIcon } from "../icons/login.svg";
import { ReactComponent as Plus } from "../icons/plus.svg";
import { ReactComponent as TrashIcon } from "../icons/trash.svg";
import { ReactComponent as UserAddIcon } from "../icons/user-add.svg";
import { ReactComponent as CollectionIcon } from "../icons/collection.svg";
import { formatRelative, fromUnixTime } from "date-fns";
import { ReactComponent as MediaPlayIcon } from "../icons/media_play.svg";
import InputField from "../components/forms/inputField";
import TextareaField from "../components/forms/textareaField";
import { ReactComponent as DownloadIcon } from "../icons/download.svg";
import { ReactComponent as RemoveIcon } from "../icons/trash.svg";
import TrashImg from "../icons/CC_icon_trash.png";
import { ReactComponent as PlayIcon } from "../icons/play-button.svg";

const AddAction = (props) => (
  <Button
    // className="flex gap-px font-bold text-gray-500 hover:text-gray-700 border-2 border-gray-400 hover:border-gray-700 px-4 py-px rounded-lg items-center"
    {...props}
  >
    <Plus className="w-5 h-5 cursor-pointer" /> Add
  </Button>
);

const SessionCard = ({ id, name, scheduledDate, onDelete }) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const history = useHistory();

  const handleDelete = () => {
    setConfirmDeleteOpen(false);

    onDelete();
  };

  return (
    <>
      {confirmDeleteOpen && (
        <DeleteConferenceModal
          key={id}
          isOpen={true}
          onClose={() => setConfirmDeleteOpen(false)}
          name={name}
          onSubmit={handleDelete}
        />
      )}
      <div className="flex gap-2 items-center">
        <div className="flex bg-white shadow-md rounded p-2">
          <div className="w-72">
            <div className="font-bold">{name}</div>
            <div className="text-gray-400 capitalize">
              {scheduledDate?.seconds
                ? formatRelative(
                    fromUnixTime(scheduledDate?.seconds),
                    new Date()
                  )
                : "Not Scheduled"}
            </div>
          </div>
          <div className="flex gap-2 ml-2 items-center">
            <Button
              className="flex items-center gap-1"
              onClick={() => history.push(`/conference/${id}/producer`)}
            >
              <LoginIcon className="w-4 h-4" /> Enter
            </Button>
            <Button
              className="flex items-center gap-1"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.href}conference/${id}`
                );
              }}
            >
              <UserAddIcon className="w-4 h-4" /> Invite
            </Button>
          </div>
        </div>
        <div>
          <button className="text-gray-400 hover:text-gray-700">
            <TrashIcon
              className="w-6 h-6"
              onClick={() => setConfirmDeleteOpen(true)}
            />
          </button>
        </div>
      </div>
    </>
  );
};

const SessionCardList = ({ children }) =>
  React.Children.count(children) > 0 ? (
    <div className="flex flex-col gap-2">{children}</div>
  ) : (
    <div className="flex flex-col justify-center items-center gap-2 w-72">
      <CollectionIcon className="w-10 h-10 text-gray-300" />
      <div className="font-semibold text-gray-400">No Sessions</div>
    </div>
  );

const compare = (a, b) => {
  if (a.name < b.name)
    return -1;
  else if (a.name > b.name)
    return 1;
  
  return 0;
};

const ConferenceRecordings = ({conference, currentRecording, setCurrentRecording, onPlay, ...props}) => {
  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);
  const [recordings, recordingsLoading] = useCollectionQuery(firestore.collection("conferences").doc(conference?.id).collection('recordings'), {
    idField: "id",
  });
  
  useEffect(() => {
    if (props.index === 0 && !currentRecording && recordings && conference) {
      setCurrentRecording({conferenceId: conference?.id, room: conference?.name, ...recordings[0]});
    }
  }, [recordings, conference]);

  if (!recordings || recordings.length === 0) {
    return (<></>)
  }

  return (
    <>
      {recordings && recordings.map(record => (
        <tr
          key={record.id}
          className={`hover:bg-gray-600 m-2 rounded-md ${currentRecording?.id === record.id ? "custom-bg-3" : "custom-bg-3" }`}
          onClick={() => setCurrentRecording({...record, room: conference.name, conferenceId: conference.id})}
        >
          <td className="text-left pt-2 pb-2 pl-3 flex items-center">
            <PlayIcon
              onClick={() => {
                setCurrentRecording({...record, room: conference.name, conferenceId: conference.id});
                onPlay();
              }}
              className="w-10 h-6 text-gray-300 border-2 border-gray-300 rounded-md mr-1 cursor-pointer"
            />
            {record.name}
          </td>
          <td className="text-left pt-2 pb-2">{conference.name}</td>
          <td className="text-left pt-2 pb-2 w-72 pl-2">{record.description}</td>
          <td className="text-left pt-2 pb-2 italic text-sm text-gray-400">{new Date(record?.createdAt).toLocaleString()}</td>
          <td className="">
            <button
              className="text-blue-300 italic hover:text-blue-700"
              onClick={props.onEdit}
            >
              ...Edit
            </button>
          </td>
          <td className="pr-3">
              {/* <RemoveIcon  /> */}
              <img
                onClick={props.handleRemove}
                className="w-6 h-6 min-w-6 h-4 ml-2 text-blue-400 hover:text-blue-200"
                src={TrashImg}
                alt="trash"
                style={{ minWidth: '15px' }}
              />
          </td>
        </tr>
      ))}
    </>
  )
};

const EditCurrentRecording = ({currentRecording, setCurrentRecording, isOpen, onClose, update}) => {
  const [name, setName] = useState(currentRecording?.name);
  const [description, setDescription] = useState(currentRecording?.description);
  const [error, setError] = useState('');
  console.log(currentRecording);

  useEffect(() => {
    if (isOpen) {
      setName(currentRecording.name);
      setDescription(currentRecording.description);
    } else {
      setName('');
      setDescription('');
    }
  }, [isOpen]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleUpdate = async (e) => {
    if (!name) {
      setError("Name field required");
      return;
    }
    setCurrentRecording({...currentRecording, name, description});
    await update(name, description);
    setName('');
    setDescription('');
    onClose();
  };

  if (isOpen) {
    return (
      <div className="fixed w-full h-full top-0 left-0 flex justify-center items-center" style={{zIndex: 100000}}>
        <div className="absolute w-full h-full top-0 left-0 bg-gray-400 opacity-70" onClick={onClose}></div>
        <div className="w-80 h-auto flex flex-col bg-white p-4 rounded-md"  style={{zIndex: 100000}}>
          {error && <div className="text-red-400 text-center">
            {error}
          </div>}
          <div>
            <InputField 
              defaultValue={currentRecording?.name}
              value={name} onChange={handleNameChange} label="name"
            />
          </div>
          <div className="pt-2">
            <TextareaField
              value={description}
              defaultValue={currentRecording?.description}
              onChange={handleDescriptionChange}
              label="description"
            />
          </div>
          <div className="pt-3 pl-8 pr-8 flex justify-between">
            <Button onClick={handleUpdate}>Upldate</Button>
            <Button onClick={onClose}>Cancel</Button>
          </div>
        </div>
        
      </div>
    )
  } else {
    return (<></>)
  }
};

const RecordingPlayer = ({currentRecording, onEdit, onPlay}) => {
  const ref = useRef();
  const [isPlay, setIsPlay] = useState(false);
  const [isShowPlayBtn, setIsShowPlayBtn] = useState(false);
  console.log(currentRecording);
  useEffect(() => {
    setIsPlay(false);
  }, [currentRecording]);

  const handleMouseEnter = (e) => {
    e.stopPropagation();
    setIsShowPlayBtn(true);
  };

  const handleMouseLeave = (e) => {
    setIsShowPlayBtn(false);
  };

  const handleTogglePlay = (e) => {
    console.log(isPlay);
    if (!isPlay) {
      ref.current.play();
    } else ref.current.pause();

    setIsPlay(!isPlay);
  };

  const handleDownload = (e) => {
    var a = document.createElement("a");
    fetch(currentRecording.url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = currentRecording.name;
      link.click();
    })
    .catch(console.error);
  };

  return (
    <div className="pl-2 pr-2 flex flex-col w-1/4">
      <div
        className="relative flex justify-center items-center"
      >
        {/* {isShowPlayBtn && */}
        {/* <button
          className="rounded-lg absolute z-20"
          onClick={handleTogglePlay}
        >
          {isPlay && <MediaPauseIcon className="text-red-500 w-12 h-12"/>}
          {!isPlay && <MediaPlayIcon className="text-red-500 w-12 h-12" />}
        </button> */}
        <button
          className="rounded-lg absolute z-20"
          onClick={onPlay}
        >
          <MediaPlayIcon className="text-red-500 w-12 h-12" />
        </button>

        {/* } */}
        <video
          ref={ref}
          src={currentRecording?.url}
          onMouseOver={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="w-80 bg-black rounded-md z-10"
        />
      </div>
      <div className="mt-1 mb-1">
        <label className="mr-1 font-bold">Name:</label>{currentRecording?.name}
      </div>
      <div className="mt-1 mb-1">
        <label className="mr-1 font-bold">Conference:</label>{currentRecording?.room}
      </div>
      <div className="mt-1 mb-1">
        <label className="mr-1 font-bold">Description:</label>{currentRecording?.description}
      </div>
      <div className="mt-1 mb-1">
        <label className="mr-1 font-bold">Recorded Date:</label>{new Date(currentRecording?.createdAt).toLocaleString()}
      </div>
      <div className="">
        <button
          onClick={onEdit}
          className="float-right text-blue-400 flex items-center h-5 m-2"
        >...Edit</button>
        <button
          onClick={handleDownload}
          className="float-right text-blue-400 flex h-5 items-center m-2"
        >
          Download
          <DownloadIcon className="ml-1 h-4 w-4"/>
        </button>
      </div>
    </div>
  )
};

const VideoPlayer = ({isOpen, currentRecording, onClose}) => {
  const handleClose = (e) => {
    onClose();
  };

  if (isOpen) {
    return (
      <>
        <div className="fixed w-full h-full top-0 left-0 flex justify-center items-center z-3000">
          <div className="absolute z-3000 opacity-90 w-full h-full top-0 left-0 bg-gray-400" onClick={handleClose}></div>
          <div className="rounded-md z-3000">
            <video
              autoPlay
              muted={false}
              playsInline
              src={currentRecording?.url}
              width="700px"
              height="500px"
              controls
              className="rounded-md"
            />
          </div>
        </div>
      </>
    )
  } else {
    return (<></>)
  }
};

const ConfirmToDeleteModal = ({isOpen, OnOk, OnCancel, currentRecording}) => {
  if (!isOpen) {
    return (<></>);
  } else if (isOpen) {
    return (
      <div className="fixed w-full h-full top-0 left-0 flex justify-center items-center" style={{zIndex: 100000}}>
        <div className="absolute w-full h-full top-0 left-0 bg-gray-400 opacity-70" onClick={OnCancel}></div>
        <div className="w-80 h-auto flex flex-col bg-white p-4 rounded-md relative"  style={{zIndex: 100000}}>
          <div className="bg-gray-200 h-5 absolute left-0 top-0 w-full" style={{borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}}></div>
          <p className="text-center mt-4">Confirm to Delete <label className="italic font-bold text-blue-400">{currentRecording.name}</label> ?</p>
          <div className="pl-4 pr-4 mt-3 flex justify-between">
            <Button onClick={OnOk}>Confirm</Button>
            <Button onClick={OnCancel}>Cancel</Button>
          </div>
        </div>
      </div>
    );
  }
}

const RecordingList = ({ type='main' }) => {
  const [createConferenceModalOpen, setCreateConferenceModalOpen] = useState(
    false
  );

  const [currentRecording, setCurrentRecording] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [isConfirmToDelete, setConfirmToDelete] = useState(false);

  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);

  const [_conferences, conferencesLoading] = useCollectionQuery(
    firestore.collection("conferences"),
    {
      idField: "id",
    }
  );

  const { user } = useContext(AuthContext);
  const [conferences, setConferences] = useState(_conferences?.filter(conference => conference.userId === user?.uid).sort(compare));

  useEffect(() => {
    setConferences(_conferences?.filter(conference => conference.userId === user?.uid).sort(compare));
    return () => {
      setConferences();
    }
  }, [_conferences, user]);

  const handleDelete = (id) => {
    firestore.collection("conferences").doc(id).delete();
  };

  const handleConferenceCreate = ({ name, /*scheduledDate, rtmpUrl*/ }) => {
    const id = nanoid();

    firestore.collection("conferences").doc(id).set({
      name,
      userId: user?.uid,
      // scheduledDate,
      // rtmpUrl,
    });

    setCreateConferenceModalOpen(false);
  };

  const handleOnEditRecording = (e) => {
    setIsEdit(true);
  };

  const handleOnRemove = () => {
    firestore.collection("conferences").doc(currentRecording?.conferenceId).collection('recordings').doc(currentRecording?.id).delete();
    setConfirmToDelete(false);
    setCurrentRecording('deleted');
  };

  const handleRemove = () => {
    setConfirmToDelete(true);
  }

  const handleEditRecording = (name, description) => {
    // setCurrentRecording({...currentRecording, name, description});
    firestore.collection("conferences").doc(currentRecording?.conferenceId).collection('recordings').doc(currentRecording?.id).set({
      name: name ? name : '',
      description: description ? description : ''
    }, { merge: true });
  };

  if (conferencesLoading) {
    return <div className="loader">&nbsp;</div>;
  }

  return (
		<>
			<EditCurrentRecording
				currentRecording={currentRecording}
				setCurrentRecording={setCurrentRecording}
				isOpen={isEdit}
				onClose={() => setIsEdit(false)}
				update={handleEditRecording}
			/>
			<ConfirmToDeleteModal
				isOpen={isConfirmToDelete}
				OnOk={handleOnRemove}
				OnCancel={() => setConfirmToDelete(false)}
				currentRecording={currentRecording}
			/>
			<VideoPlayer
				isOpen={isPlay}
				onClose={() => setIsPlay(false)}
				currentRecording={currentRecording}
			/>
			<div className={`flex justify-center gap-2 ${type !== "main" ? "flex-col" : ""}`}>
				<div
					className="rounded-md overflow-y-scroll p-4 mr-4 scrollbar mb-2 pt-2"
					style={{height: '60vh', width: currentRecording ? '75%' : '100%'}}
				>
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="text-gray-300 border-b border-gray-300">
							<th className="pl-3 text-left">Name</th>
							<th className="text-left">Room</th>
							<th className="text-left pl-2">Description</th>
							<th className="pr-3 text-left">Date</th>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200 mt-2 text-gray-300">
						{conferences?.map((conference, idx) => (
								<ConferenceRecordings
									key={idx}
									index={idx}
									conference={conference}
									currentRecording={currentRecording}
									setCurrentRecording={setCurrentRecording}
                  onPlay={() => setIsPlay(true)}
									onEdit={handleOnEditRecording}
									handleRemove={handleRemove}
								/>
						))}
						{!currentRecording && <div className="font-semibold text-gray-400 flex items-center w-full">
							<CollectionIcon className="w-10 h-10 text-gray-300 mr-3" />
							No Recordings
						</div>}
						</tbody>
					</table>
				</div>
				{currentRecording &&
					<RecordingPlayer
						currentRecording={currentRecording}
						setCurrentRecording={setCurrentRecording}
						onEdit={handleOnEditRecording}
						onPlay={() => setIsPlay(true)}
					/>
				}
			</div>
		</>
  );
};

export default RecordingList;
