import React from "react";
import { ReactComponent as XCircleIcon } from "../icons/x-circle.svg";

const Modal = ({ isOpen, onClose, children, className }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-3000">
      <div
        className="absolute top-0 left-0 w-full h-full bg-gray-800 opacity-50 z-3000"
        onClick={onClose}
      >
        &nbsp;
      </div>
      <div
        className={`custom-bg-2 shadow-lg rounded-md z-3000 relative ${
          className ? className : ""
        }`}
      >
        {children}
        <button
          className="absolute bg-white rounded-full shadow-lg -top-3 -right-3 text-red-700 hover:text-red-500"
          onClick={onClose}
        >
          <XCircleIcon className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default Modal;
