import React, { useContext } from "react";
import { LocalTracksContext } from "../../helpers/context/localTracksContext";
import {
  useAudioInputDevices,
  useAudioOutputDevices,
  useVideoInputDevices,
} from "../../helpers/hooks/useMediaDevices";
import { ReactComponent as XCircleIcon } from "../../icons/x-circle.svg";
import Select from "../select";
import Video from "./video";

const DeviceSelection = ({ isOpen, onClose = () => {}, message = "", setMessage = () => {} }) => {
  const [videoDevices, videoInputDevicesLoading] = useVideoInputDevices();
  const [audioInputDevices, audioInputDevicesLoading] = useAudioInputDevices();
  const [
    audioOutputDevices,
    audioOutputDevicesLoading,
  ] = useAudioOutputDevices();

  const loading =
    videoInputDevicesLoading ||
    audioInputDevicesLoading ||
    audioOutputDevicesLoading;

  const {
    acquiringTracks,
    localTracks,
    changeAudioInputDevice,
    changeAudioOutputDevice,
    changeVideoDevice,
    videoDeviceId,
    audioInputDeviceId,
    audioOutputDeviceId,
  } = useContext(LocalTracksContext);

  return (
    <div
      className={`fixed top-0 left-0 h-full w-full items-center justify-center  ${
        isOpen ? "flex" : "hidden"
      }`}
      style={isOpen?{zIndex: 10000}:{}}
      onClick={() => onClose && onClose()}
    >
      <div className="absolute top-0 left-0 z-40 h-full w-full bg-gray-800 opacity-50">
        &nbsp;
      </div>
      <div
        className="rounded shadow-lg w-1/4 bg-white flex flex-col gap-12 p-4 relative z-3000"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="bg-white absolute rounded-full border border-gray-200 w-5 h-5 flex justify-center items-center -top-2 -right-2 shadow cursor-pointer text-gray-700 hover:text-gray-500"
          onClick={() => onClose && onClose()}
        >
          <XCircleIcon className="w-5 h-5" />
        </div>
        {loading ? (
          <div className="loader" />
        ) : (
          <>
            {message && <div className="text-red mt-2 -mb-10 flex items-center">
              <p className="text-red-700 text-center">{message}</p>
            </div>}
            <div className="flex justify-center">
              <Video
                loading={acquiringTracks}
                key={videoDeviceId}
                tracks={localTracks || []}
                muted
                autoPlay
                className="rounded-md shadow"
              />
            </div>
            <div>
              <Select
                id="videoDevice"
                label="Select Camera"
                value={videoDeviceId}
                onChange={(e) => {
                  changeVideoDevice(e.target.value);
                }}
              >
                {videoDevices.map((device) => (
                  <option value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </option>
                ))}
                <option value="none" key="-1">No use</option>
              </Select>
            </div>
            <div>
              <Select
                id="audioInDevice"
                label="Select Microphone"
                value={audioInputDeviceId}
                onChange={(e) => {
                  changeAudioInputDevice(e.target.value);
                }}
              >
                {audioInputDevices.map((device) => (
                  <option value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </option>
                ))}
                <option value="none" key="-1">No use</option>
              </Select>
            </div>
            <div>
              <Select
                id="audioOutDevice"
                label="Select Speaker"
                value={audioOutputDeviceId}
                onChange={(e) => changeAudioOutputDevice(e.target.value)}
              >
                {audioOutputDevices.map((device) => (
                  <option value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </option>
                ))}
              </Select>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DeviceSelection;
