import React, { useState } from 'react';
import styled from "@emotion/styled";
import LayerEditor from "./layerEditor";
import UploadMedia from "./uploadMedia";
import ChatRoom from "./chatRoom";
import RecordingListEmbeded from "./recordingListEmbeded";
import { Tab as ReactTab, TabList, TabPanel, Tabs } from "react-tabs";
import { ReactComponent as TemplateIcon } from "../icons/template.svg";
import { ReactComponent as ChatIcon } from "../icons/chat.svg";
import { ReactComponent as FilmIcon } from "../icons/film.svg";
import { ReactComponent as RecordingIcon } from "../icons/play1.svg";

const StyledTabPanelContainer = styled.div`
.tab-panel {
	display: none !important;
}

.react-tabs__tab-panel--selected {
	display: block !important;
}
`;

const StyledTabList = styled(TabList)`
li {
	color: #d1d5db;
	border-bottom: 3px solid transparent;
}

li.react-tabs__tab--selected {
	background-color: #77379f;
}

li:hover {
	color: #4b5563;
}

.react-tabs__tab--selected {
	color: white;
	/*border-bottom: 3px solid #fcd34d;*/
}
`;

StyledTabList.tabsRole = "TabList";

const LayerEditorControls = ({ room, conferenceId, conference, docRef }) => {
	const [activeTab, setActiveTab] = useState(0);

	return (
		<StyledTabPanelContainer className="w-2/12 bg-cPurple custom-shadow flex flex-col absolute right-0 " style={{height: 'calc(100vh - 210px)'}}>
			<Tabs className="border-b border-gray-400 h-full py-1 overflow-y-scroll overflow-x-hidden" defaultIndex={0}>
				<StyledTabList className="flex justify-around mt-2">
					<ReactTab
						className="cursor-pointer flex items-center rounded-t px-2 bg-cPurple"
						onClick={() => setActiveTab(0)}
					>
						<TemplateIcon className="w-4 h-4 mr-1" />
						{activeTab === 0 ? 'Editor' : ''}
					</ReactTab>
					<ReactTab
						className="cursor-pointer flex items-center rounded-t px-2 bg-cPurple"
						onClick={() => setActiveTab(1)}
					>
						<FilmIcon className="w-4 h-4 mr-1" />
						{activeTab === 1 ? 'Media' : ''}
					</ReactTab>
					<ReactTab
						className="cursor-pointer flex items-center rounded-t px-2 bg-cPurple"
						onClick={() => setActiveTab(2)}
					>
						<RecordingIcon className="w-4 h-4 mr-1 text-red" />
						{activeTab === 2 ? 'Recordings' : ''}
					</ReactTab>
					<ReactTab
						className="cursor-pointer flex items-center rounded-t px-2 bg-cPurple"
						onClick={() => setActiveTab(3)}
					>
						<ChatIcon className="w-4 h-4 mr-1" />
						{activeTab === 3 ? 'Chat' : ''}
					</ReactTab>
				</StyledTabList>
				{/* <div className="w-full h-0 border border-gray-500"></div> */}
				<TabPanel
					style={{ height: "calc(100% - 45px)" }}
					className="tab-panel bg-cPurple-light"
				>
					<LayerEditor
						room={room}
						conferenceId={conferenceId}
						sceneId={conference.activeScene}
					/>
				</TabPanel>
				<TabPanel
					style={{ height: "calc(100% - 45px)" }}
					className="tab-panel bg-cPurple-light"
				>
					<p className="pl-2 text-gray-100">Media uploads</p>
					<UploadMedia docRef={docRef} conference={conference}/>
				</TabPanel>
				<TabPanel
					style={{ height: "calc(100% - 45px)" }}
					className="tab-panel bg-cPurple-light"
				>
					<RecordingListEmbeded type={'embeded'} conference={conference} docRef={docRef} />
				</TabPanel>
				<TabPanel
					style={{ height: "calc(100% - 45px)" }}
					className="tab-panel bg-cPurple-light"
				>
					<ChatRoom />
				</TabPanel>
			</Tabs>
		</StyledTabPanelContainer>
	)
};

export default LayerEditorControls;
