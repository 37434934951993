import React, { useState, useEffect } from "react";
import Button from "../components/button";
import Modal from "../components/modal";

const UploadImageModal = ({ isOpen, onClose, onSubmit, progress, caption = "Upload Image", extension="" }) => {
  const [file, setFile] = useState();
  const [ uploading, setUploading ] = useState(false);
  const [isCustomRatio, setIsCustomRatio] = useState(true);
  const handleChange = (e) => {
    setFile(e.target.files);
  };

  useEffect(() => {
    setUploading(false);
  }, [isOpen]);

  const handleSubmit = async (e) => {
    if (uploading) return;
    setUploading(true);
    await onSubmit({ img: file, isCustomRatio });
    setFile();
    setUploading(false);
    // onClose();
  };

  const handleCustomRatio = (e) => {
    setIsCustomRatio(e.target.checked);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-1/4">
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">{caption}</h2>
      </div>
      <div className="p-4">
        {/* <InputField id="name" label="Name" ref={register} required /> */}
        <input
          id="img"
          className="w-full overflow-x-hidden text-gray-300 bg-none"
          name="img"
          type="file"
          onChange={handleChange} accept={extension === 'mp4' ? 'video/mp4' : extension === 'ppt, pptx' ? '.ppt, .pptx' : ''}
        />
        <div className="flex items-center mt-2 hidden">
          <input type="checkbox" checked={isCustomRatio} value={isCustomRatio} onChange={handleCustomRatio} />
          <p
            className="ml-2"
            onClick={() => {setIsCustomRatio(!isCustomRatio)}}
          >
            Use Custom Ratio
          </p>
        </div>
        <div className="flex flex-row-reverse mt-4">
          <Button
            type="submit"
            className="bg-gray-500 hover:bg-gray-400 text-gray-100"
            disabled={uploading}
            onClick={handleSubmit}
          >
            {!uploading && "Submit"}
            {uploading && `...Uploading`}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadImageModal;
