import React from 'react';
import { useEffect, useMemo, useState, useContext } from "react";
import { useLoading } from "./useLoading";
import { useStableValueRef } from "./useStableRef";
import { AuthContext } from "../context/authContext";

export const snapShotToData = (snapshot, idField) => {
  if (!snapshot.exists) {
    return;
  }

  return {
    ...snapshot.data(),
    ...(idField ? { [idField]: snapshot.id } : null),
  };
};

export const useFirebaseCollection = (query, options) => {
  const { error, loading, reset, setError, setValue, value } = useLoading();

  const ref = useStableValueRef(query, reset);
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const listener = options?.snapshotListenOptions
      ? ref.current?.onSnapshot(
          options.snapshotListenOptions,
          setValue,
          setError
        )
      : ref.current?.onSnapshot(setValue, setError);

    return () => {
      listener();
    };
  }, [ref.current, user]);

  return [value, loading, error];
};

export const useCollectionQuery = (query, options) => {
  const idField = options?.idField;
  const snapshotListenOptions = options?.snapshotListenOptions;

  const [snapshot, loading, error] = useFirebaseCollection(query, {
    snapshotListenOptions,
  });

  const values = useMemo(
    () => snapshot?.docs?.map?.((doc) => snapShotToData(doc, idField)),
      [snapshot, idField]
    );

  // const values = snapshot?.docs?.map?.((doc) => snapShotToData(doc, idField));
  
  return [values, loading, error];
};
