import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../helpers/context/authContext";
import { useParams } from "react-router-dom";
import { LocalTracksContext } from "../helpers/context/localTracksContext";
import { ReactComponent as CogIcon } from "../icons/cog.svg";
import { ReactComponent as Microphone } from "../icons/microphone.svg";
import { ReactComponent as XCircle } from "../icons/x-circle.svg";
import { ReactComponent as ScreenShare } from "../icons/screen-share.svg";
import { ReactComponent as Host } from "../icons/user.svg";

const VideoContainer = ({
  onClose,
  onMute,
  guestName = "",
  className = "",
  muted = false,
  shared = false,
  onScreenShare,
  hideControls = false,
  isLocal = false,
  selected = false,
  showAdminCommands = false,
  children,
  onClick,
  identity,
  width,
  height,
}) => {
  const { openDeviceSelection } = useContext(LocalTracksContext);
  const { user, hostStatus, hostLavel, toggleHost, initHost } = useContext(AuthContext);
  const { conferenceId } = useParams();
  const [isHost, setIsHost] = useState(hostStatus[user?.uid]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [userLavel, setUserLavel] = useState({});
  let style = {
    objectFit: "cover"
  };
  if (width && height) {
    style = {
      width: width,
      height: height,
    }
  }

  useEffect(() => {
    const _setUserLavel = async () => {
      hostStatus && setIsHost(hostStatus[user?.uid]);
      let lavels = await hostLavel(conferenceId);
      setUserLavel(lavels);
    };
    _setUserLavel();
  }, [hostStatus]);

  return (
    <>
      {identity && 
        <div className={`${className} h-full p-1`} style={style}>
          <div
            onClick={onClick}
            className={`${
              selected ? "border-2 border-yellow-400" : ""
            } h-full rounded overflow-hidden shadow-lg relative bg-black`}
          >
            {/* {!hideControls && (
              <>
                {!isLocal && showAdminCommands && (
                  <button
                    onClick={onClose}
                    className="absolute top-0 right-0 text-red-500 hover:text-red-400 cursor-pointer z-30"
                  >
                    <XCircle className="w-6 h-6 mr-1 mt-1" />
                  </button>
                )}
                {(isLocal || showAdminCommands) && (
                  <button
                    onClick={onMute}
                    className={`absolute bottom-0 right-0 ${
                      muted
                        ? "text-red-600 hover:text-red-400"
                        : "text-white hover:text-gray-300"
                    } cursor-pointer z-30`}
                  >
                    <Microphone className="w-6 h-6 mr-1 mb-1" />
                  </button>
                )}
                {isLocal && (
                  <button
                    onClick={onScreenShare}
                    className={`absolute bottom-0 left-0 ${
                      shared
                        ? "text-red-600 hover:text-red-400"
                        : "text-white hover:text-gray-300"
                    } cursor-pointer z-30`}
                  >
                    <ScreenShare className="w-6 h-6 ml-1 mb-1"/>
                  </button>
                )}
                <button
                  disabled={!isHost}
                  // className="bg-white hover:bg-gray-100 rounded-md shadow-md py-1 px-3 z-40 font-medium text-gray-800"
                  className={
                    `absolute top-0
                      ${isLocal ? "right-0" : "left-0"}
                      ${hostStatus[identity]
                        ? "text-red-600 hover:text-red-400"
                        : "text-white hover:text-gray-300"
                      }
                      cursor-pointer z-30`
                    }
                  onClick={() => toggleHost(conferenceId, identity)}
                  onMouseOver={() => setShowTooltip(true)}
                  onMouseOut={() => setShowTooltip(false)}
                >
                  <Host
                    className="w-6 h-6 ml-1 mb-1"
                  />
                  {showTooltip &&
                    <div
                      className={`
                        absolute inline top-full w-24 
                          ${isLocal
                            ? "right-0 float-right text-right"
                            : "left-0 float-left text-left"
                          }
                      `}
                    >
                      {userLavel && userLavel[identity]}
                    </div>
                  }
                </button>
              </>
            )} */}
            <div className="absolute left-0 bottom-0 text-white font-bold z-10 pl-2 pb-1">
              {guestName}
            </div>
            {/* {isLocal && (
              <div className="absolute left-0 top-0 z-30">
                <button
                  onClick={() => openDeviceSelection()}
                  className="text-white hover:text-gray-300"
                >
                  <CogIcon className="w-6 h-6 ml-1 mt-1" />
                </button>
              </div>
            )} */}
            {children}
          </div>
        </div>
      }
    </>
  );
};

export default VideoContainer;
