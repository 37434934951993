import React from 'react';
import { useContext } from "react";
import { usePublication } from "../../helpers/hooks/usePublication";
import Publication from "./publication";
import { useAudioChannelContext } from "../../helpers/hooks/useAudioChannelContext";
import { AuthContext } from "../../helpers/context/authContext";

const Participant = ({ identity, width, height, z, participant, disableAudio = false }) => {
  console.log(identity);
  const publications = usePublication(participant);
  const { currentAudioChannel } = useAudioChannelContext();
  const { user } = useContext(AuthContext);

  const isYouInPrivate = (!currentAudioChannel || !currentAudioChannel.members) ? false : (currentAudioChannel.members.some(m => m.id === user?.uid));
  const isPInPrivate = (!currentAudioChannel || !currentAudioChannel.members) ? false : (currentAudioChannel.members.some(m => m.id === participant.identity));

  return publications.map((publication) => {
    return (
      <Publication
        width={width}
        height={height}
        publication={publication}
        isLocalPrivate={isYouInPrivate}
        isParticipantPrivate={isPInPrivate}
        identity={identity}
        key={publication.trackName}
        disableAudio={disableAudio}
        z={z}
      />
    );
  });
};

export default Participant;
