import { nanoid } from "nanoid";
import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import Button from "../components/button";
import DropDownMenu from "../components/dropDownMenu";
import UploadMediaModal from "../features/uploadImageModal";
import { deleteFile } from "../helpers/firebaseUpload";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";

// TODO
// - Punch in audio public silences attendees, has audio for live feed
// - Turn punch in into toggle

const UploadMedia = ({ docRef, conference, conf }) => {
  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);
  const videoRef = firestore.collection("videos");
  const [modalOpen, setModalOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [videos, setVideos] = useState([]);
  const [controlShow, setControlShow] = useState(-1);
  const [deleting, setDeleting] = useState(false);
	const { activeZone, layersCollection } = useLayerEditorContext();
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const collectionRef = docRef.collection("scenes");
  // const confRef = firestore.collection("conferences").doc(conferenceId);
  // const scenesRef = confRef.collection("scenes");

  const updateVideoList = () => {
    if (videoRef && conference) {
      videoRef.get().then((snapshot) => {
        let temp = [];
        Array.from(snapshot.docs).map(doc => {
          if (doc.data().userId === conference.userId)
            temp.push(doc.data());
        });
        setVideos(temp);
      });
    }
    const thumbs = document.querySelectorAll('.video-thumb');
    if (thumbs) {
      Array.from(thumbs).map(thumb => {
        thumb.addEventListener('timeupdate', (e) => {
          if (e.target.currentTime > 5) e.target.currentTime = 0.2;
        });
      });
    }
  };

  useEffect(() => {
    updateVideoList();

    return () => {
      setVideos();
    }
  }, []);

  useEffect(() => {
    const thumbs = document.querySelectorAll('.video-thumb');
    if (thumbs) {
      Array.from(thumbs).map(thumb => {
        videoRef.doc(thumb.id.split('a76')[1]).update({
          duration: thumb.duration
        });
        thumb.onloadeddata = (e) => {
          videoRef.doc(e.target.id.split('a76')[1]).update({
            duration: e.target.duration
          });
          try {
            document.querySelector(`label#${e.target.id}`).innerText = Math.round(e.target.duration) + ' secs';
          } catch (err) {console.log(err)}
        }
        // document.querySelector(`label#${thumb.id}`).innerText = Math.round(thumb.duration) + ' secs';
      });
    }
    collectionRef.doc(conference.activeScene).update({
      playVideo: {
        url: '',
        state: false,
        currentTime: 0,
      }
    });
  }, [videos]);

  const createVideoLayer = (src = null) => {
    const id = nanoid();

    collectionRef.doc(conference.activeScene).collection(layersCollection).doc(id).set({
      id,
      type: "video",
      src,
      x: 0,
      y: 0,
      z: 3,
      volume: 1,
      muted: true,
      currentTime: 0,
      playStatus: 0,
      height: 250,
      width: 444,
      zone: activeZone
    });

    return id;
  };

  const [progress, setProgress] = useState(0);

  const addVideoLayer = async ({ img }) => {
    const file = img?.[0];
    if (!file) return;
    const fileId = `${new Date().getTime()}-${file.name}`;
    const fileRef = firebase.storage().ref().child(fileId);
    const task = fileRef.put(file);   // <--- See the difference here
    const _promise = new Promise((resolve, reject) => {
      task.on('state_changed' , 

        function progress(snapshot){
          let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(percentage);
        },
        function error(err){

        },
        async function complete(data){
          const url = await fileRef.getDownloadURL();
          const id = nanoid();

          // collectionRef.doc(conference.activeScene).collection("videos").doc(id).set({
          //   id,
          //   url,
          //   title: 'TITLE'
          // });
          videoRef.doc(id).set({
            id,
            url,
            userId: conference.userId,
            title: 'TITLE'
          });
          updateVideoList();
          setProgress(0);
          resolve(id);
        }
      );
    })
    let res;
    await _promise.then(data => res = data);
    return res;
  };

  const updateTitle = async (id, title) => {
    let temp = JSON.parse(JSON.stringify(videos));
    temp.find(_t => _t.id == id).title = title;
    setVideos(temp);
    videoRef.doc(id).update({
      title
    });
  };

  const handleRename = (e) => {
    setControlShow(-1);
    document.querySelector(`input#${e.target.id}`).select();
    document.querySelector(`input#${e.target.id}`).focus();
  };

  const handleDelete = async (e) => {
    const currentId = e.target.id;
    const _video = videos.find(_v => _v.id === currentId.split('a76')[1]);
    setDeleting(true);
    try {
      await deleteFile(firebase)({ name: _video.url });
    } catch (err) {console.error(err)}
    console.log(e.target, e);
    videoRef.doc(currentId.split('a76')[1]).delete();
    setVideos(videos?.filter(_ => _.id !==currentId.split('a76')[1]));
    setDeleting(false);
  };

  const handleAddToScene = (e) => {
    const _video = videos.find(_v => _v.id === e.target.id.split('a76')[1]);
    createVideoLayer(_video.url);
  };

  const handlePlayVideo = (e) => {
    const _video = videos.find(_v => _v.id === e.target.id.split('a76')[1]);
    collectionRef.doc(conference.activeScene).update({
      playVideo: {
        url: _video.url,
        state: true,
        currentTime: 0,
      }
    });
  };

  return (
		<div style={{paddingLeft: '5px'}}>
      <Helmet>
        <title>Upload Media</title>
      </Helmet>
      <UploadMediaModal
        isOpen={videoModalOpen}
        onClose={() => setVideoModalOpen(false)}
        progress={progress}
        onSubmit={addVideoLayer}
        extension="mp4"
        caption="Upload Video"
      />
      <div className="flex flex-wrap">
				<DropDownMenu
          className="flex bg-cPurple-light border-none text-gray-100 ml-2 items-center"
          childCls="custom-bg-2 text-gray-100 shadow-lg rounded"
          text="Add Video"
        >
					<DropDownMenu.MenuItem
            className="px-2 py-1 text-gray-100 cursor-pointer"
						onClick={() => setVideoModalOpen(true)}
					>
						Add Video
					</DropDownMenu.MenuItem>
				</DropDownMenu>
      </div>
      <div className="flex flex-wrap">
        {videos && videos.map((video, idx) => (
          <div
            key={idx}
            style={{margin: '5px'}}
            className="relative"
            // onMouseEnter={(e) => {setControlShow(idx); e.stopPropagation();}}
            onMouseOver={(e) => {setControlShow(idx); e.stopPropagation();}}
            onMouseLeave={(e) => {setControlShow(-1); e.stopPropagation();}}
          >
            {controlShow === idx && 
            <>
              {/* <div
                className="absolute flex flex-col items-center justify-center z-10 w-full h-full"
                style={{opacity: 0.6, background: 'gray'}}
              ></div> */}
              <div className="absolute flex flex-col items-center justify-center z-10 w-full h-full">
                <Button
                  id={'a76' + video.id}
                  className="bg-cPurple-light w-32 px-2 rounded text-white hover:text-gray-700 h-8"
                  onClick={handlePlayVideo}
                >
                  play
                </Button>
                <Button
                  id={'a76' + video.id}
                  className="bg-cPurple-light w-32 px-2 rounded text-white hover:text-gray-700 h-8"
                  onClick={handleAddToScene}
                >
                  add to scene
                </Button>
                <Button
                  id={'a76' + video.id}
                  className="bg-cPurple-light w-32 px-2 rounded text-white hover:text-gray-700 h-8"
                  onClick={handleRename}
                >
                  Rename
                </Button>
                <Button
                  id={'a76' + video.id}
                  className="bg-cPurple-light w-32 px-2 rounded text-white hover:text-gray-700 h-8"
                  onClick={handleDelete}
                  disabled={deleting}
                >
                  {deleting? '...Deleting' : 'Delete'}
                </Button>
              </div>
            </>
            }
            <video id={'a76' + video.id} src={video.url} className="video-thumb" muted autoPlay loop />
            <input
              id={'a76' + video.id}
              type='text'
              value={video.title}
              onChange={(e) => {updateTitle(e.target.id.split('a76')[1], e.target.value)}}
              style={{position: 'absolute', left: 0, top: 0, background: 'black', width: '100%', height: '20px', color: 'white', border: 'none'}}
            />
            <label id={'a76' + video.id} style={{position: 'absolute', right: 5, top: 3, background: 'black', height: '16px', color: 'white', fontSize: '12px', padding: 0}}>
              {video && video.duration > 0 ? `${Math.round(video.duration)} secs`: 'loading'}
            </label>
          </div>
        ))}
      </div>
		</div>
  );
};

export default UploadMedia;
