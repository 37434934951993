import React, { useContext, useEffect, useRef } from "react";
import { MediaDeviceContext } from "../../helpers/context/mediaDeviceContext";

const Audio = ({ identity, tracks: providedTracks, isLocal = false, disableAudio }) => {
  console.log(disableAudio, identity);
  const { audioOutputDeviceId } = useContext(MediaDeviceContext);

  const tracks = Array.isArray(providedTracks)
    ? providedTracks
    : [providedTracks];
  const ref = useRef();

  useEffect(() => {
    const el = ref.current;
    el.muted = isLocal;

    tracks.forEach((track) => {
      track.attach(el);
    });

    return () => {
      tracks.forEach((track) => track.detach(el));
    };
  }, [tracks, ref, isLocal]);

  useEffect(() => {
    const el = ref.current;
    if (el) {
      console.log(el);
      // el.muted = !!disableAudio;
      // console.log(el.muted);
      // document.querySelector(`#id_${identity}`).muted = disableAudio;
      setTimeout(() => {
        // document.querySelector(`#id_${identity}`).muted = disableAudio;
        tracks.forEach((track) => {
          if (track.kind === 'audio') {
            if (disableAudio) {
              console.log(track);
              track.detach(el);
            } else {
              track.attach(el);
              // track.enable();
            }
          }
        });
      }, 100);
    }
  }, [disableAudio, tracks]);

  useEffect(() => {
    if (ref.current && audioOutputDeviceId) {
      ref.current.setSinkId(audioOutputDeviceId);
    }
  }, [audioOutputDeviceId, ref]);

  return <audio id={`id_${identity}`}ref={ref} className="hidden" />;
};

export default Audio;
