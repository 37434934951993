import React from 'react';
import { nanoid } from "nanoid";
import { useMemo, useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../helpers/context/authContext";
import DropDownMenu from "../components/dropDownMenu";
import UploadImageModal from "../features/uploadImageModalNProgress";
import UploadMediaModal from "../features/uploadImageModalNProgress";
import UploadPPTModal from "../features/uploadImageModalNProgress";
import { firebaseUpload } from "../helpers/firebaseUpload";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { useDocumentQuery } from "../helpers/hooks/useFirebaseDocument";
import { useLayerEditorContext } from "../helpers/hooks/useLayerEditorContext";
import { useTwilioVideo } from "../helpers/hooks/useTwilioVideo";
import LayerForm from "../components/layerForms/layerForm";
import Button from "../components/button";
import { Collapse } from "react-collapse";
import { ReactComponent as ChevronDownIcon } from "../icons/chevron-down.svg";
import BackgroundControl from '../components/layerFormControl/backgroundControl';
import ParticipantControl from '../components/layerFormControl/participantControl';
import ForegroundControl from '../components/layerFormControl/foregroundControl';
import OverlayControl from '../components/layerFormControl/overlayControl';
import axios from "axios";
import { ReactComponent as PlusIcon } from "../icons/plus.svg";

const LayerEditor = ({ room, sceneId, conferenceId }) => {
  const [activeLayerIndex, setActiveLayerIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [sceneModalOpen, setSceneModalOpen] = useState(false);
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [PPTModalOpen, setPPTModalOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [bgModalOpen, setBgModalOpen] = useState(false);
  const [currentZone, setCurrentZone] = useState('background');
  const [openAlertCurrentChange, setOpenAlertCurrentChange] = useState(false);
  
  const [nextId, setNextId] = useState(-1);

  const containerRef = useRef();

  const { 
    activeLayer, setActiveLayer, currentState, loadEditingScene, edited, 
    setEdited,  backgroundImg, activeZone, layersCollection
  } = useLayerEditorContext();

  const [targetZone, setTargetZone] = useState('foreground');

  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);

  const confRef = firestore.collection("conferences").doc(conferenceId);
  const scenesRef = confRef.collection("scenes");

  const { user, hostStatus, setHost } = useContext(AuthContext);

  const [editable, setEditable] = useState(() => {
    if (hostStatus) {
      if (user)
        return hostStatus[user?.uid];
      else return false;
    } return false;
  });

  const [_, participants] = useTwilioVideo({
    roomName: conferenceId,
    identity: "canvas_rtmp",
    roomParams: {
      video: false,
      audio: false,
    },
  });

  const [deletingImages, setDeletingImages] = useState([]);
  const [currentBackground ,setCurrentBackground] = useState();

  useEffect(() => {
    return () => {
      setDeletingImages();
      setCurrentBackground();
    }
  }, []);

  useEffect(() => {
    setEditable(() => {
      if (hostStatus) {
        if (user)
          return hostStatus[user?.uid];
        else return false;
      } return false;
    });
  }, [hostStatus, user, conferenceId]);

  useEffect(() => {
    if (activeZone) {
      setCurrentZone(activeZone);
    }
  }, [activeZone]);

  // let EditingScene;
  // try {
  //   EditingScene = scenesRef.doc(editable ? 'editing_scene' : sceneId);
  // } catch (err) {
  //   scenesRef.doc('editing_scene').set({
  //     name: 'EditingScene',
  //     layers: [],
  //     background: {},
  //   });
  // }
  const layersRef = scenesRef.doc(sceneId).collection(layersCollection);
  // const customFontsRef = scenesRef.doc(editable && currentState ? 'editing_scene' : sceneId).collection("fonts");
  const customFontsRef = firestore.collection('fonts');

  const [conference, confLoading] = useDocumentQuery(confRef);

  const [scenes, scenesLoading] = useCollectionQuery(scenesRef, {
    idField: "id",
  });
  const [layers, layersLoading] = useCollectionQuery(layersRef, {
    idField: "id",
  });

  const zoneLayers = layers?.filter(_ => _?.zone === activeZone);

  const [_customFonts, customFontsLoading] = useCollectionQuery(customFontsRef, {
    idField: "id",
  });

  const customFonts = _customFonts?.filter(font => font.userId === user?.uid);
  const selectLavel = activeZone === 'participant' ? "Select Participant" : "Select Layer";
  const activeScene = scenes?.find(({ id }) => id === conference.activeScene);
  const editingScene = scenes?.find(({ id }) => id === 'editing_scene');

  useEffect(() => {
    if (layers && layers.length > 0) {
      let i = 0;
      for (let layer of zoneLayers) {
        if (layer.id === activeLayer) {
          setActiveLayerIndex(i);
          break;
        }
        i++;
      }
    }
  }, [activeLayer]);

  useEffect(() => {
    const ele = document.getElementById('onstage');
    if (activeScene && editingScene) {
      ele.style.backgroundImage = editable && currentState ? `url(${editingScene?.background?.image}` : `url(${activeScene?.background?.image}`;
      ele.style.backgroundColor = editable && currentState ? editingScene?.background?.color : activeScene?.background?.color;
    }
      // let img = document.createElement('img');
      let img = new Image();
      img.style.width = ele?.style.width;
      img.style.height = ele?.style.height;
      img.style.backgroundSize = ele?.style.backgroundSize;
      img.style.backgroundPosition = ele?.style.backgroundPosition;
      img.src = ele?.style.backgroundImage.split('"')[1];
      img.style.background = ele?.style.backgroundColor;
      img.className = 'background';
      img.crossOrigin = 'anonymous';
      if (backgroundImg.current) delete[backgroundImg.current];
      backgroundImg.current = img;
    
  }, [activeScene, editingScene, editable, currentState]);

  const [currentScene, setCurrentScene] = useState();

  const isParticipantExist = (_layer) => {
    if (activeZone !== 'participant') return true;
    const res = participants?.filter(_ => _.identity === _layer.id);
    if (res && res?.length > 0) return true;
    return false;
  };

  const updateLayer = ({ id, data }) => {
    const topZIndex = getTopZIndex();
    layersRef.doc(id).update(data);

    if (data.z >= topZIndex) {
      updateTopZIndex(data.z + 1);
    }
    
    setEdited(true);
  };

  const removeLayer = (id) => {
    layersRef.doc(id).delete();
    setEdited(true);
  };

  const updateTopZIndex = (index) => {
    scenesRef.doc(editable && currentState ? 'editing_scene' : sceneId).update({
      topZIndex: index
    });
  };

  const getTopZIndex = () => {
    let _z = -1;
    zoneLayers?.map(_ => {
      _z = Math.max(_z, _?.z)
    });
    return _z;
  };

  const createTextLayer = (zone='foreground') => {
    const id = nanoid();
    const topZIndex = getTopZIndex();
    // updateTopZIndex(topZIndex + 1);

    scenesRef.doc(editable && currentState ? 'editing_scene' : conference.activeScene).collection(layersCollection).doc(id).set({
      id,
      type: "text",
      text: "New Text Layer",
      x: 0,
      y: 0,
      // z: zoneLayers?.length ? zoneLayers?.length : 0,
      z: topZIndex + 1,
      width: 200,
      height: 50,
      textColor: 'white',
      backgroundColor: 'black',
      zone,
    });
    setEdited(true);
    setActiveLayer(null);
    return id;
  };

  const createPPTLayer = ({src = []}) => {
    const id = nanoid();
    const topZIndex = getTopZIndex();
    // updateTopZIndex(topZIndex + 1);

    scenesRef.doc(editable && currentState ? 'editing_scene' : conference.activeScene).collection(layersCollection).doc(id).set({
      id,
      type: "PPT",
      name: "",
      src: src,
      currentSlide: 0,
      x: 0,
      y: 0,
      // z: zoneLayers?.length ? zoneLayers?.length : 0,
      z: topZIndex + 1,
      width: 500,
      height: 400,
      backgroundColor: 'black',
      zone: targetZone?targetZone:'foreground',
    });
    setEdited(true);
    setActiveLayer(null);
    return id;
  };

  const createImageLayer = ({ src = null, height = 100, width = 100, isCustomRatio }) => {
    const id = nanoid();
    const topZIndex = getTopZIndex();
    // updateTopZIndex(topZIndex + 1);

    scenesRef.doc(editable && currentState ? 'editing_scene' : conference.activeScene).collection(layersCollection).doc(id).set({
      id,
      type: "image",
      src,
      x: 0,
      y: 0,
      // z: zoneLayers?.length ? zoneLayers?.length : 0,
      z: topZIndex + 1,
      height,
      width,
      isCustomRatio,
      zone: targetZone?targetZone:'foreground',
    });
    setEdited(true);
    return id;
  };

  const addImageAndLayer = async ({ img, isCustomRatio }) => {
    const file = img?.[0];
    if (!file) return;
    const val = await firebaseUpload(firebase)({
      name: file.name,
      file,
    });

    const url = await val.ref.getDownloadURL();

    const imgObj = new Image();
    const objUrl = window.URL.createObjectURL(file);
    imgObj.onload = function () {
      const maxWidth = 500;
      const maxHeight = 500;
      let width = this.width;
      let height = this.height;

      if (width > maxWidth) {
        let ratio = maxWidth / width;
        height = height * ratio;
        width = width * ratio;
      }

      if (height > maxHeight) {
        let ratio = maxHeight / height;
        height = height * ratio;
        width = width * ratio;
      }

      createImageLayer({
        src: url,
        height: Math.round(height),
        width: Math.round(width),
        isCustomRatio
      });
    };
    imgObj.src = objUrl;
    setEdited(true);
    setImgModalOpen(false);
  };

  const createVideoLayer = ({src = null, isCustomRatio}) => {
    const id = nanoid();
    const topZIndex = getTopZIndex();
    // updateTopZIndex(topZIndex + 1);

    scenesRef.doc(editable && currentState ? 'editing_scene' : conference.activeScene).collection(layersCollection).doc(id).set({
      id,
      type: "video",
      src,
      x: 0,
      y: 0,
      // z: zoneLayers?.length ? zoneLayers?.length : 0,
      z: topZIndex + 1,
      volume: 1,
      muted: true,
      currentTime: 0,
      playStatus: 0,
      height: 250,
      width: 444,
      isCustomRatio,
      zone: targetZone?targetZone:'foreground',
    });
    setEdited(true);
    return id;
  };

  const createTimerLayer = (zone='foreground') => {
    const id = nanoid();
    const topZIndex = getTopZIndex();
    // updateTopZIndex(topZIndex + 1);

    scenesRef.doc(editable && currentState ? 'editing_scene' : conference.activeScene).collection(layersCollection).doc(id).set({
      id,
      type: "timer",
      zone,
      x: 0,
      y: 0,
      // z: zoneLayers?.length ? zoneLayers?.length : 0,
      z: topZIndex + 1,
      volume: 1,
      muted: true,
      currentTime: 0,
      playStatus: 0,
      height: 50,
      width: 250,
      backgroundColor: 'black',
      textColor: 'white',
    });
    setEdited(true);
    return id;
  };

  const addVideoAndLayer = async ({ img, isCustomRatio }) => {
    const file = img?.[0];
    if (!file) return;

    const val = await firebaseUpload(firebase)({
      name: file.name,
      file,
    });

    const url = await val.ref.getDownloadURL();

    createVideoLayer({src: url, isCustomRatio});
    setVideoModalOpen(false);
  };

  const addPPTAndLayer = async ({ img }) => {
    const file = img?.[0];
    console.log(file);
    if (!file) return;

    // const val = await firebaseUpload(firebase)({
    //   name: file.name,
    //   file,
    // });

    // const url = await val.ref.getDownloadURL();

    // curl -i -X POST -d '{"apikey": "_YOUR_API_KEY_", "file":"http://google.com/", "outputformat":"png"}' http://api.convertio.co/convert
    // curl -i -X GET http://api.convertio.co/convert/_ID_/status
    const reader = new FileReader();
    
    const getFileAsBase64 = new Promise((resolve, reject) => {
      reader.onload = (event) => {
        // Remove the metadata before the base64-encoded string.
        const startIndex = reader.result.toString().indexOf("base64,");
        const copyBase64 = reader.result.toString().substr(startIndex + 7);
        resolve(copyBase64);
        // PowerPoint.createPresentation(copyBase64);
      };
      
      try {
        reader.readAsDataURL(file);
      } catch(err) {
        reject(err);
      }
    });

    const fileInBase64 = await getFileAsBase64;

    const cId = await axios.post(`http://api.convertio.co/convert`, {
      "apikey": `6921ff2148415a331c6e9dbfdc3c66fa`,
      "input": "base64",
      "file": fileInBase64,
      "filename": file.name,
      "outputformat": "jpeg"
    }).then(res => res.data.data.id);
    
    if (cId) {
      console.log(cId);
      const getStatus = new Promise((resolve, reject) => {
        const checkStatus = async () => {
          const res = await axios.get(`http://api.convertio.co/convert/${cId}/status`);
          if (res.data.data.step === 'finish' && res.data.status === 'ok') {
            resolve(res.data.data);
          } else if (res.data.status === 'error') {
            reject();
          } else {
            console.log(res.data.step);
            setTimeout(checkStatus, 1000);
          }
        }
        checkStatus();
      });

      const convertedData = await getStatus;

      console.log(convertedData);

      const zipData = await axios.get(convertedData.output.url);
      console.log(zipData);

      // // const uploadedData = await axios.get(`http://localhost:5000/ppt/load/${convertedData.output.url}`);
      // const uploadedData = await axios.get(`https://stream.myconferencecloud.com/ppt/load/${convertedData.output.url}`);
      // console.log(uploadedData);
      
      // createPPTLayer({src: uploadedData.data});
    } else {
      alert('PPT conversion failed');
    }
    setPPTModalOpen(false);
  };

  const addNewFont = async ({ font, family, format }) => {
    const file = font[0];

    const val = await firebaseUpload(firebase)({
      name: file.name,
      file,
    });

    const url = await val.ref.getDownloadURL();

    const id = nanoid();

    // scenesRef.doc(editable && currentState ? 'editing_scene' : conference.activeScene).collection("fonts").doc(id).set({
    //   id,
    //   family: family,
    //   format: format,
    //   url
    // });

    customFontsRef.doc(id).set({
      id,
      userId: user?.uid,
      family, format, url
    });
  };

  const setActiveScene = (id) => {
    if (currentState == 0) {
      confRef.update({
        activeScene: id,
      });
    } else {
      if (!edited) {
        loadEditingScene(scenesRef, id);
        setCurrentScene(scenes?.find((scene) => id === scene.id));
      } else {
        setNextId(id);
        setOpenAlertCurrentChange(true);
      }
    }
  };

  if (confLoading || scenesLoading || layersLoading) {
    return <div className="loader">&nbsp;</div>;
  }



  return (
    <div ref={containerRef} id='layer_setting' className="h-full">
      {imgModalOpen && <UploadImageModal
        isOpen={imgModalOpen}
        onClose={() => setImgModalOpen(false)}
        onSubmit={addImageAndLayer}
      />}
      {videoModalOpen && <UploadMediaModal
        isOpen={videoModalOpen}
        onClose={() => setVideoModalOpen(false)}
        onSubmit={addVideoAndLayer}
        caption="Upload Video"
        extension="mp4"
      />}
      {PPTModalOpen && <UploadPPTModal
        isOpen={PPTModalOpen}
        onClose={() => setPPTModalOpen(false)}
        onSubmit={addPPTAndLayer}
        caption="Upload PPT"
        extension="ppt, pptx"
      />}

      <div className="flex flex-col gap-5 p-2">
        <div>
          <div className="">
            <h3 className="pb-3 text-gray-100 font-bold text-2xl leading-relaxed">
              {activeZone ? activeZone[0].toUpperCase() + activeZone.slice(1) : ""} Zone
            </h3>
          </div>
          {activeZone !== 'participant' &&
            <DropDownMenu text="Add Layer" position="relative" className="text-md w-full pr-2 flex justify-between bg-cPurple hover:bg-cPurple text-gray-100 border-none">
              <DropDownMenu.MenuItem
                className="pl-5 text-gray-100 text-md cursor-pointer hover:bg-cPurple flex items-center"
                onClick={() => createTextLayer(activeZone)}
              >
                <PlusIcon className="w-6 h-6" /> Add Text
              </DropDownMenu.MenuItem>
              <DropDownMenu.MenuItem
                className="pl-5 text-gray-100 cursor-pointer hover:bg-cPurple flex"
                onClick={() => {
                  setActiveLayer(null);
                  setTargetZone(activeZone);
                  setPPTModalOpen(true);
                }}
              >
                <PlusIcon className="w-6 h-6" /> Add PPT
              </DropDownMenu.MenuItem>
              <DropDownMenu.MenuItem
                className="pl-5 text-gray-100 cursor-pointer hover:bg-cPurple flex"
                onClick={() => {
                  setActiveLayer(null);
                  setTargetZone(activeZone);
                  setImgModalOpen(true);
                }}
              >
                <PlusIcon className="w-6 h-6" /> Add Image
              </DropDownMenu.MenuItem>
              <DropDownMenu.MenuItem
                className="pl-5 text-gray-100 cursor-pointer hover:bg-cPurple flex"
                onClick={() => {
                  setVideoModalOpen(true);
                  setTargetZone(activeZone);
                  setActiveLayer(null);
                }}
              >
                <PlusIcon className="w-6 h-6" /> Add Video
              </DropDownMenu.MenuItem>
              <DropDownMenu.MenuItem
                className="pl-5 text-gray-100 cursor-pointer hover:bg-cPurple flex"
                onClick={() => {
                  createTimerLayer(activeZone);
                  setActiveLayer(null);
                }}
              >
                <PlusIcon className="w-6 h-6" /> Add Timer
              </DropDownMenu.MenuItem>
            </DropDownMenu>
          }
          
          <DropDownMenu
            text={!activeLayer?selectLavel:zoneLayers?.find(_layer => _layer.id === activeLayer)?.type + '-' + activeLayerIndex}
            position="relative" className="mt-3 w-full pr-2 flex justify-between bg-cPurple hover:bg-cPurple text-gray-100 border-none"
          >
            {zoneLayers && participants && zoneLayers.filter(_ => isParticipantExist(_)).map((layer, idx) => (
              <DropDownMenu.MenuItem
                key={layer.id}
                className="pl-5 text-gray-100 cursor-pointer hover:bg-cPurple flex"
                onClick={() => {setActiveLayer(layer.id); setActiveLayerIndex(idx)}}
              >
                {layer.type}-{idx}
              </DropDownMenu.MenuItem>
            ))}
          </DropDownMenu>
        
        </div>
      </div>
      {1 === 0 && 
        <div className="p-2 pt-0">
          <Button
            className="border rounded border-gray-400 w-full flex justify-between"
            onClick={() => {
              if (currentZone === 'overlay')
                setCurrentZone('');
              else setCurrentZone('overlay');
            }}
          >
            Overlay
            <ChevronDownIcon
              className="h-4 w-4 text-gray-400 hover:text-gray-600 ml-px"
              style={currentZone==='overlay' ? { transform: 'rotate(180deg)'} : {}}
            />
          </Button>
          <Collapse isOpened={currentZone === 'overlay'}>
            <OverlayControl
              parentHeight={containerRef.current?.offsetHeight}
              activeScene={activeScene}
              firebase={firebase}
              scenesRef={scenesRef}
              layers={layers}
              createTextLayer={createTextLayer}
              setActiveLayer={setActiveLayer}
              setImgModalOpen={setImgModalOpen}
              setVideoModalOpen={setVideoModalOpen}
              createTimerLayer={createTimerLayer}
              activeLayer={activeLayer}
              customFonts={customFonts}
              updateLayer={updateLayer}
              removeLayer={removeLayer}
              addNewFont={addNewFont}
              setTargetZone={setTargetZone}
            />
          </Collapse>

          <Button
            className="border rounded border-gray-400 w-full flex justify-between mt-1"
            onClick={() => {
              if (currentZone === 'foreground')
                setCurrentZone('');
              else setCurrentZone('foreground');
            }}
          >
            Foreground
            <ChevronDownIcon
              className="h-4 w-4 text-gray-400 hover:text-gray-600 ml-px"
              style={currentZone==='foreground' ? { transform: 'rotate(180deg)'} : {}}
            />
          </Button>
          <Collapse isOpened={currentZone === 'foreground'}>
            <ForegroundControl
              parentHeight={containerRef.current?.offsetHeight}
              createTextLayer={createTextLayer}
              createPPTLayer={createPPTLayer}
              setActiveLayer={setActiveLayer}
              setImgModalOpen={setImgModalOpen}
              setVideoModalOpen={setVideoModalOpen}
              createTimerLayer={createTimerLayer}
              activeLayer={activeLayer}
              layers={layers}
              customFonts={customFonts}
              updateLayer={updateLayer}
              removeLayer={removeLayer}
              addNewFont={addNewFont}
              setTargetZone={setTargetZone}
            />
          </Collapse>
          <Button
            className="border rounded border-gray-400 w-full flex justify-between mt-1"
            onClick={() => {
              if (currentZone === 'participant')
                setCurrentZone('');
              else setCurrentZone('participant');
            }}
          >
            Participants
            <ChevronDownIcon
              className="h-4 w-4 text-gray-400 hover:text-gray-600 ml-px"
              style={currentZone==='participant' ? { transform: 'rotate(180deg)'} : {}}
            />
          </Button>
          <Collapse isOpened={currentZone === 'participant'}>
            <ParticipantControl
              parentHeight={containerRef.current?.offsetHeight}
              activeLayer={activeLayer}
              layers={layers}
              setActiveLayer={setActiveLayer}
              customFonts={customFonts}
              updateLayer={updateLayer}
              removeLayer={removeLayer}
              addNewFont={addNewFont}
            />
          </Collapse>

          <Button
            className="border rounded border-gray-400 w-full flex justify-between mt-1"
            onClick={() => {
              if (currentZone === 'background')
                setCurrentZone('');
              else setCurrentZone('background');
            }}
          >
            Background
            <ChevronDownIcon
              className="h-4 w-4 text-gray-400 hover:text-gray-600 ml-px"
              style={currentZone==='background' ? { transform: 'rotate(180deg)'} : {}}
            />
          </Button>
          <Collapse isOpened={currentZone === 'background'}>
            <BackgroundControl
              parentHeight={containerRef.current?.offsetHeight}
              activeScene={activeScene}
              firebase={firebase}
              scenesRef={scenesRef}
            />
          </Collapse>
        </div>
      }
      
      <div className="px-2">
        <LayerForm
          activeLayer={activeLayer}
          layers={zoneLayers}
          customFonts={customFonts}
          updateLayer={updateLayer}
          removeLayer={removeLayer}
          addNewFont={addNewFont}
        />
      </div>
      
      {activeZone === 'background' && 
        <BackgroundControl
          parentHeight={containerRef.current?.offsetHeight}
          activeScene={activeScene}
          firebase={firebase}
          scenesRef={scenesRef}
        />
      }
    </div>
  );
};

export default LayerEditor;
