import React from "react";

const TextareaField = React.forwardRef(
  ({ id, type = "text", required = false, label, ...props }, ref) => {
    return (
      <div>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="mt-1">
          <textarea
            {...props}
            ref={ref}
            id={id}
            name={id}
            type={type}
            required={required}
            className="appearance-none block w-full px-3 h-8 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm"
            style={{minHeight: '90px'}}
          ></textarea>
        </div>
      </div>
    );
  }
);

export default TextareaField;
