import React, { useContext, useState, useEffect, useMemo, useRef } from "react";
import {ReactComponent as BroadcastIcon} from "../../icons/broadcast.svg";
import Button from "../button";
import { AuthContext } from "../../helpers/context/authContext";
import { useLayerEditorContext } from "../../helpers/hooks/useLayerEditorContext";
import ConfirmStopRtmp from '../../features/confirmCurrentChangeModal';
import { ReactComponent as RecordIcon } from "../../icons/record.svg";
import { ReactComponent as RecordingIcon } from "../../icons/recording.svg";
import { ReactComponent as GearIcon } from "../../icons/gear.svg";
import { useFirebase } from "../../helpers/hooks/useFirebase";
import { useDocumentQuery } from "../../helpers/hooks/useFirebaseDocument";
import ISOSetting from "./isoSetting";
import BroadcastSetting from "./broadcastSetting";
import { gapi, loadClientAuth2 } from 'gapi-script';
import { nanoid } from "nanoid";
import UploadingStatusModal from "../../features/uploadingStatusModal";
import { useTwilioVideo } from "../../helpers/hooks/useTwilioVideo";
import { usePresence } from "../../helpers/hooks/usePresence";
import isoUpload from '../../features/isoUpload/index';
import { io } from "socket.io-client";

const ConfirmRecModal = ({isOpen=false, onOk=()=>{}, onCancel=()=>{}, caption=''}) => {
	if (!isOpen) {
		return (
			<></>
		)
	} else {
		return (
			<div className="fixed flex justify-center">
				<div className="fixed top-0 left-0 w-full h-full z-3000 bg-gray-800 opacity-70"></div>
				<div className="mt-60 z-3000 bg-white w-80 p-0 rounded-md shadow">
					<div className="bg-gray-700 h-8 rounded-t text-white pl-2 pt-1">
					</div>
					<div className="p-2 text-center">
						<h2>{caption}</h2>
						<div className="flex pl-5 pr-5 justify-around mt-3">
							<Button className="w-20 bg-gray-100" onClick={onOk}>OK</Button>
							<Button className="w-20" onClick={onCancel}>Cancel</Button>
						</div>
					</div>
				</div>
			</div>
		)
	}
};

const Broadcast = ({conferenceId}) => {
	const [isOpen, setIsOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const [confirmStopRtmpModalOpen, setConfirmStopRtmpModalOpen] = useState(false);
	const [isLocalRecIntent, setIsLocalRecIntent] = useState(false);
	const [isLiveIntent, setIsLiveIntent] = useState(false);
	const [isISOSettingOpen, setIsISOSettingOpen] = useState(false);
	const [isUploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
	const localRecordingChunks = useRef([]);
  const participantStreams = useRef([]);
  const [mainProgress, setMainProgress] = useState(0);
  const [isUploadingISO, setUploadingISO] = useState(false);
  const [childProgress, setChildProgress] = useState(0);
  const [isoError, setISOError] = useState('');

	const broadcastingAnimation = useRef();
	const broadcastBtnRef = useRef();
	const recordingAnimation = useRef();
	const recordBtnRef = useRef();

	// const [isVirtualBackgroundModal, setIsVirtualBackgroundModal] = useState(false);

	// const {
  //   virtualBackground,
  // } = useContext(LocalTracksContext);

	const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);

  const docRef = firestore.collection("conferences").doc(conferenceId);
	// const virtualBackgroundRef = firestore.collection('virtualBackgrounds');
  // const [virtualBackgrounds, virtualBackgroundsLoading] = useCollectionQuery(virtualBackgroundRef, {
  //   idField: "id",
  // });
  const [conference] = useDocumentQuery(docRef);

	const { 
		RTMPUrl, 
		// broadcastSocket.current, localRecordingSocket.current,
    RTMPKey, setRTMPUrl, setRTMPKey, setSceneLock, isEditing, setRecording,
		isISOSetting, setIsISOSetting, dropboxAccessToken, setDropboxAccessToken,
		isoDestination, setISODestination, setGoogleAccessToken, googleAccessToken,
	}
	= useLayerEditorContext();

	const socketOptions = {
    secure: true,
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    timeout:150000000,
    pingTimeout: 15000,
    pingInterval: 45000,
    query: {framespersecond: 25, audioBitrate: 25}
  };

	// const socketUrl = "http://localhost:5000/";
	const socketUrl = "https://restreamer.myconferencecloud.com/";
	// const socketUrl = "https://stream.myconferencecloud.com/";

	const broadcastSocket = useRef();
	const localRecordingSocket = useRef();

	const [_, participants] = useTwilioVideo({
    roomName: conferenceId,
    identity: "restreamer",
    roomParams: {
      video: false,
      audio: false,
    },
  });

	const users = usePresence({
    firebase,
    user: {
      uid: "restreamer",
      displayName: "restreamer",
    },
    confId: conferenceId,
  });

	useEffect(() => {

		return () => {
			window.localStorage.setItem('broadcast', 0);
			window.localStorage.setItem('localRecording', 0);
		}
	}, []);

	const handleRTMP = () => {
    if (!conference?.isBroadcast) {
			setIsLiveIntent(true);
    } else {
      setConfirmStopRtmpModalOpen(true);
    }
  };

	const setDestinations = (_destinations) => {
		docRef?.update({
			broadcast: _destinations
		});
	}
	
	const validParticipants = participants
		.filter((part) => part.tracks.size > 0)
		.filter((participant) => participant.identity in (users ? users : {}))
		.filter((part) => part.identity !== "restreamer");

	const initializeISO = () => {
    if (validParticipants && users) {
      let tempStream = [];
      validParticipants.forEach((p) => {

        if (participantStreams?.current?.filter(_p => _p.id === p.identity).length === 0) {
          const videoTemp = document.createElement('video');
          videoTemp.id = p.identity;
          videoTemp.style.width = '800px';
          videoTemp.style.height = '600px';
          videoTemp.style.objectFit = 'cover';
          Array.from(p.tracks.values()).forEach(t => {
            if (t.track) {
              t.track.attach(videoTemp);
            } else {
              t.on('subscribed', (_t) => {
                _t.attach(videoTemp);
              })
            }
          });
          document.body.append(videoTemp);
          videoTemp.autoplay = true;
          videoTemp.style.display = 'none';
          const _stream = videoTemp.captureStream(25);
          tempStream.push({
            id: p.identity,
            name: users[p.identity].name,
            stream: _stream,
            recorder: null,
            chunks: [],
          });
        }
      });
      participantStreams.current = [...participantStreams.current, ...tempStream];
    }
  };

	const uploadRecord = async (recordUrl, fileName) => {
		const metadata = {
			contentType: "video/mp4"
		};
		
		const fileRef = firebase.storage().ref().child(`${new Date().getTime()}-${fileName}`);
		const task = fileRef.put(recordUrl, metadata);
		const _promise = new Promise((resolve, reject) => {
			task.on('state_changed' , 

				function progress(snapshot){
					let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					setProgress(percentage);
				},
				function error(err){

				},
				async function complete(data){
					const url = await fileRef.getDownloadURL();
					setProgress(0);
					resolve(url);
				}
			);
		})
		let res;
		await _promise.then(data => res = data);
		
		const url = res;
		const recordingRef = firestore.collection("conferences").doc(conferenceId).collection('recordings');
		const id = nanoid();
		recordingRef.doc(id).set({
			id,
			name: fileName,
			url,
			createdAt: new Date().getTime(),
			description: ''
		});
	};

	const saveLocalRecord = async () => {
		if (!localRecordingChunks.current?.length) return;
    var blob = new Blob(localRecordingChunks.current, {
      type: "video/mp4"
    });
    const fileName = `test-${new Date().getTime()}.mp4`
    
    setUploading(true);
    await uploadRecord(blob, fileName);
    setUploading(false);
    // window.URL.revokeObjectURL(url);
    localRecordingChunks.current = [];
  };

	const handleLocalRecording = () => {
		if (conference.isLocalRecording) {
			recordingAnimation.current?.cancel();

			docRef.update({
				isLocalRecording: false
			});
			saveLocalRecord();

			if (isISOSetting) {
				const today = new Date();
				const folder1 = 'conference_cloud';
				const folder2 = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
				const folder3 = `${conference?.name}_${today.getTime()}`;
	
				const uploadingToDestinationPromise = isoUpload({
					participantStreams: participantStreams.current,
					isoDestination,
					folder1,
					folder2,
					folder3,
					mainProgress,
					setMainProgress,
					setChildProgress,
					dropboxAccessToken,
					googleAccessToken,
					setISOError,
					gapi,
					loadClientAuth2,
				});
	
				setUploadingISO(true);
	
				Promise.all(uploadingToDestinationPromise).then(() => {
					console.log(isoError, 'uploading success1');
					setUploadingISO(false);
					setMainProgress(0);
					setChildProgress(0);
				}).catch (err => {
					console.error(err);
					setISOError("Check your AccessToken.")
					setUploadingISO(false);
					setMainProgress(0);
					setChildProgress(0);
				});
			}
			
			stopLocalRecording();
		} else {
			setIsLocalRecIntent(true);
		}
	};

	const onLocalRecording = () => {
		recordingAnimation.current = recordBtnRef.current.animate([{
			color: 'red'
		}], {
			duration: 1000,
			iterations: Infinity,
		});

		docRef.update({
			isLocalRecording: true
		});
		let base = window.location.href.split('producer')[0];
		if (base[base.length - 1] !== '/') base += '/';

		localRecordingSocket.current = io.connect(socketUrl, socketOptions);
		console.log(localRecordingSocket.current);
		
		try {
			localRecordingSocket.current.connect();
			localRecordingSocket.current.on('connect', () => {
				localRecordingSocket.current.emit('start_record', `${base}stream_local`);

				localRecordingSocket.current.on('local_chunks', data => {
					localRecordingChunks.current = [...localRecordingChunks.current, data];
					console.log(localRecordingChunks.current);
				});
			});
		} catch (err) {
			console.log(err);
		}

		if (isISOSetting) {
			initializeISO();
			participantStreams.current.forEach(p => {
				const _recorder = new MediaRecorder(p.stream);
				p.recorder = _recorder;
				_recorder.id = p.id;
				try {
					_recorder.start(1000);
					
					_recorder.ondataavailable = function(e) {
						if (e.data.size > 0) {
							console.log('ios recorded...');
							participantStreams.current?.filter(pt => pt.id === e.target.id)[0].chunks.push(e.data);
						}
					}
				} catch (err) {
					console.log(err);
				}
			});
		}

		setIsLocalRecIntent(false);
	}

	const onLive = () => {
		broadcastingAnimation.current = broadcastBtnRef.current.animate([{
			color: 'red'
		}], {
			duration: 1000,
			iterations: Infinity,
		});

		let base = window.location.href.split('producer')[0];
		if (base[base.length - 1] !== '/') base += '/';

		isEditing.current = false;

		docRef.update({
			isBroadcast: true
		});

		const rtmps = conference?.broadcast?.map(_d => {
			if (_d?.url[_d?.url?.length - 1] !== '/')
				return _d?.url + '/' + _d?.key;
			return _d?.url + _d?.key;
		});

		broadcastSocket.current = io.connect(socketUrl, socketOptions);

		try {
			broadcastSocket.current.connect();
		} catch (err) {console.log(err)}

		broadcastSocket.current.on('connect', (data) => {
			console.log('socket connected', rtmps);
			broadcastSocket.current.emit('config_rtmpDestination', {
				rtmps,
				conference: `${base}stream_rtmp`,
			});
		});

		broadcastSocket.current.on('config_set', (data) => {
			console.log('socket config set');
			broadcastSocket.current.emit('start_broadcast', 'start');
		});

		setIsLiveIntent(false);
	}

  const stopRTMP = () => {
		broadcastingAnimation.current?.cancel();

    setSceneLock(false);
    isEditing.current = false;
    setRecording(false);
		docRef.update({
			isBroadcast: false
		});
		// broadcastSocket.current.emit('broadcast_stop', 1);
		// broadcastSocket.current.emit('broadcast_stop', 1);
		// broadcastSocket.current.emit('broadcast_stop', 1);
		console.log('stop broadcast msg sent');
		try {
			broadcastSocket.current.disconnect();
			broadcastSocket.current = null;
		} catch (err) {
			console.log(err);
		}
		// stopStreaming();
		
    setConfirmStopRtmpModalOpen(false);
  };

	const stopLocalRecording = () => {
		// localRecordingSocket.current.emit('recording_stop', 1);
		// localRecordingSocket.current.emit('recording_stop', 1);
		// localRecordingSocket.current.emit('recording_stop', 1);
		docRef.update({
			isLocalRecording: false
		});
		try {
			localRecordingSocket.current.disconnect();
			localRecordingSocket.current = null;
		} catch (err) {
			console.log(err);
		}
		console.log('stop recording msg sent');
	}

	const handleISOChecked = (e) => {
		setIsISOSetting(!isISOSetting);
	};

	const handleISODestinationChange = async (_destination) => {
		setISODestination(_destination);
	};

	const handleDropboxAccessToken = (token) => {
		setDropboxAccessToken(token);
	}

	return (
		<div className="relative flex items-center ml-10">
			<ConfirmRecModal
				isOpen={isLocalRecIntent}
				onOk={onLocalRecording}
				onCancel={() => setIsLocalRecIntent(false)}
				caption={'Closing the window will result in the stream stopping.'}
			/>
			<ConfirmRecModal
				isOpen={isLiveIntent}
				onOk={onLive}
				onCancel={() => setIsLiveIntent(false)}
				caption={'Closing the window will result in the stream stopping.'}
			/>
			<ConfirmStopRtmp
				isOpen={confirmStopRtmpModalOpen}
				caption={"Are you sure you want to end the broadcast?"}
				onClose={() => setConfirmStopRtmpModalOpen(false)}
				onSubmit={stopRTMP}
			/>
			<UploadingStatusModal
        isOpen={isUploading}
        caption="Don't leave this page until uploading finished"
        progress={progress}
        onClose={() => setUploading(false)}
      />	
			<UploadingStatusModal
				isOpen={isUploadingISO}
				caption="Uploading ISO"
				error={isoError}
				progress={(mainProgress + 1) * (childProgress === 0 ? 1 : childProgress) / validParticipants?.length * 100}
				mask={false}
				onClose={() => setUploadingISO(false)}
			/>
			
			<div className="border border-gellow rounded flex items-center mr-2 pl-2">
				<BroadcastIcon
					className="w-5 h-5 cursor-pointer text-gellow hover:text-yellow-400"
					onClick={() => {
						setIsOpen(!isOpen);
						setIsISOSettingOpen(false);
					}}
				/>
				
				<button
					ref={broadcastBtnRef}
					className={`${conference?.isBroadcast ? "bg-red-400" : ""} w-20 h-8 py-1 custom-bg-1 border-none text-gellow hover:bg-gray-700`}
					onClick={handleRTMP}
				>
					Go Live
				</button>
			</div>
			<div className="border border-gellow rounded flex items-center">
				<GearIcon
					className="w-4 h-4 cursor-pointer text-gellow hover:text-yellow-400 ml-2"
					onClick={() => {
						setIsISOSettingOpen(!isISOSettingOpen);
						setIsOpen(false);
					}}
				/>
					{/* {!conference?.isLocalRecording && <RecordIcon className="w-full h-8 cursor-pointer" onClick={handleLocalRecording}/>}
					{conference?.isLocalRecording && <RecordingIcon className="w-full h-8 cursor-pointer" onClick={handleLocalRecording}/>} */}
					<button
						ref={recordBtnRef}
						className="w-20 text-gellow h-8 cursor-pointer text-center hover:bg-gray-700"
						onClick={handleLocalRecording}
					>
						RECORD
					</button>
			</div>
			
			{/* <Button className="pl-2 pr-2 ml-2" onClick={() => setIsVirtualBackgroundModal(true)}>Virtual Background</Button> */}
			
			{isOpen && 
				<BroadcastSetting
					destinations={conference?.broadcast}
					setDestinations={setDestinations}
					user={user}
					RTMPUrl={RTMPUrl}
					RTMPKey={RTMPKey}
					setRTMPKey={setRTMPKey}
					setRTMPUrl={setRTMPUrl}
					setIsOpen={setIsOpen}
				/>
			}
		
			{isISOSettingOpen && 
				<ISOSetting
					isISOSetting={isISOSetting}
					setIsISOSettingOpen={setIsISOSettingOpen}
					handleISOChecked={handleISOChecked}
					isoDestination={isoDestination}
					handleISODestinationChange={handleISODestinationChange}
					dropboxAccessToken={dropboxAccessToken}
					handleDropboxAccessToken={handleDropboxAccessToken}
					setGoogleAccessToken={setGoogleAccessToken}
				/>
			}
		</div>
	)
};

export default Broadcast;
