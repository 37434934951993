import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import Button from "../components/button";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useCollectionQuery } from "../helpers/hooks/useFirebaseCollection";
import { ReactComponent as UserAddIcon } from "../icons/user-add.svg";

const AddAllGuests = () => {
  const { conferenceId } = useParams();
  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);

  const queueRef = firestore
    .collection("conferences")
    .doc(conferenceId)
    .collection("participants")
    .where("canJoin", "==", false)
    .where("deny", "==", false);

  const [data, loading] = useCollectionQuery(queueRef, { idField: "id" });

  const handleAcceptAll = () => {
    if (!loading && data?.length) {
      data.forEach((participant) => {
        firestore
          .collection("conferences")
          .doc(conferenceId)
          .collection("participants")
          .doc(participant.id)
          .update({
            canJoin: true,
          });
      });
    }
  };

  return (
    <button
      className="flex items-center mb-1 text-gray-100 text-sm"
      disabled={loading || !data || !data?.length}
      onClick={handleAcceptAll}
    >
      <UserAddIcon className="h-4 w-4 mr-2 text-gray-700" /> Add All Guests
    </button>
  );
};

export default AddAllGuests;
