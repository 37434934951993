import { Resizable } from "re-resizable";
import React, { useRef, useState } from "react";
import { install } from "resize-observer";
import Draggable from './draggable';
import Button from "../components/button";
import { ReactComponent as SortAscendingIcon } from "../icons/sort-ascending.svg";
import { ReactComponent as SortDescendingIcon } from "../icons/sort-descending.svg";
// TODO
// - Ratio resize?
install();

const ratio = 1920 / 1080;

const scaleDimensions = ({ viewWidth, viewHeight, width, height, isCustomRatio }) => {
  const widthPercentage = viewWidth / 1920;
  const heightPercentage = viewHeight / 1080;
  const computedHeight = heightPercentage * (height > 0 ? height : 500);
  const computedWidth = isCustomRatio ? widthPercentage * (width > 0 ? width : 500) : computedHeight * ratio;

  return {
    width: computedWidth,
    height: computedHeight,
  };
};

const deScaleDimensions = ({ viewWidth, viewHeight, width, height }) => {
  const widthPercentage = viewWidth / 1920;
  const heightPercentage = viewHeight / 1080;

  // const computedWidth = width / widthPercentage;
  // const computedHeight = height / heightPercentage;
  const computedHeight = height / heightPercentage;
  const computedWidth = computedHeight * ratio;

  return {
    width: computedWidth,
    height: computedHeight,
  };
};

const ImageLayer = ({
  onDrag,
  src,
  id,
  x = 0,
  y = 0,
  z = 0,
  onResize,
  height: givenHeight,
  width: givenWidth,
  selected,
  hovered,
  onClick,
  editable,
  viewWidth,
  viewHeight,
  isCustomRatio,
  handleBringForward,
  handleBringBack,
  isBeingReposition,
  setIsBeingReposition,
  zoneIndex,
  hoveredLayer,
  zone,
}) => {
  const ref = useRef();
  const { width, height } = scaleDimensions({
    viewWidth,
    viewHeight,
    width: givenWidth,
    height: givenHeight,
    isCustomRatio,
  });

  const isInHoveredLayer = () => {
		if (z > hoveredLayer?.z && hoveredLayer?.zone === zone) {
		  if (hoveredLayer?.x < x && hoveredLayer?.x + hoveredLayer?.width > x
			&& hoveredLayer?.y < y && hoveredLayer?.y + hoveredLayer?.height > y) {
			  return true;
			}
		  if (hoveredLayer?.x < x + givenWidth && hoveredLayer?.x + hoveredLayer?.width > x + givenWidth
			&& hoveredLayer?.y < y && hoveredLayer?.y + hoveredLayer?.height > y) {
			  return true;
			}
		  if (hoveredLayer?.x < x && hoveredLayer?.x + hoveredLayer?.width > x
			&& hoveredLayer?.y < y + givenHeight && hoveredLayer?.y + hoveredLayer?.height > y + givenHeight) {
			  return true;
			}
		  if (hoveredLayer?.x < x + givenWidth && hoveredLayer?.x + hoveredLayer?.width > x + givenWidth
			&& hoveredLayer?.y < y + givenHeight && hoveredLayer?.y + hoveredLayer?.height > y + givenHeight) {
			  return true;
			}
		}
		return false;
	  };
  
  const baseZ = isInHoveredLayer() ? 1500 : 0;

  const style = {
    position: "absolute",
    zIndex: isBeingReposition === id ? 2500 : hovered ? 2000 : selected ? 1500 + baseZ :  isInHoveredLayer() ? 2000 + z : 250 * zoneIndex + z,
  };

  return (
    <Draggable
      id={id}
      onDrag={onDrag(id)}
      x={x}
      y={y}
      disabled={!editable}
      viewHeight={viewHeight}
      viewWidth={viewWidth}
      style={style}
      setIsBeingReposition={setIsBeingReposition}
    >
      <Resizable
        style={style}
        ref={ref}
        bounds="parent"
        handleClasses="handle"
        handleWrapperClass="re-handle"
        className={`inline-block absolute ${
          (hovered || isBeingReposition === id) ? "border-2 border-yellow-500 " : selected ? "border-2 border-red-500" : ""
        }`}
        enable={{
          top: false,
          right: true && editable,
          bottom: true && editable,
          bottomLeft: false,
          bottomRight: true && editable,
          topRight: false,
          topLeft: false,
        }}
        size={{ width, height }}
        lockAspectRatio={!isCustomRatio}
        onResizeStart={(e) => {
          e.stopPropagation();
          setIsBeingReposition(id);
        }}
        onResizeStop={(e, direction, ref, d) => {
          const deScale = deScaleDimensions({
            viewWidth,
            viewHeight,
            width: width + d.width,
            height: height + d.height,
          });
          const countedW =(width + d.width) / viewWidth * 1920;
          const countedH = (height + d.height) / viewHeight * 1080;
          onResize({
            id,
            width: isCustomRatio ? countedW : deScale.width,
            height: isCustomRatio ? countedH : deScale.height,
          });
          setIsBeingReposition(null);
        }}
        onClick={() => {
          onClick();
        }}
      >
        {src ? (
          <div
            // style={style}
            className="absolute w-full h-full cursor-move"
          >
            <div className="absolute w-full h-full">
              <img
                src={src}
                style={{ width: 'calc(100% - 2px)', height: 'calc(100% - 2px)', zIndex: z }}
                className="inline-block object-fill"
                draggable={false}
                alt="uploaded stream content"
              />
            </div>
          </div>
        ) : (
          <div>No Image</div>
        )}
        {/* {selected && <div className="absolute flex flex-col top-0 right-0 w-16 h-8">
          <div>
            <Button
              className="w-8"
              onClick={(e) => {
                e.stopPropagation();
                handleBringForward({id, z});
              }}
            >
              <SortAscendingIcon className="w-4 h-4" />
            </Button>
            <Button
              className="w-8"
              onClick={(e) => {
                e.stopPropagation();
                handleBringBack({id, z});
              }}
            >
              <SortDescendingIcon className="w-4 h-4" />
            </Button>
          </div>
          <span className="text-red-500 font-bold">level: {z}</span>
        </div>} */}
      </Resizable>
    </Draggable>
  );
};

export default ImageLayer;