
import Modal from "../components/modal";

const TextChangeModal = ({isOpen, onClose, onTextEdit, text, ...props}) => {

	const handleText = (e) => {
		onTextEdit(e.target.value);
	}
	return (
		<Modal isOpen={isOpen} onClose={onClose} className="w-1/4">
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">Edit Text</h2>
      </div>
			<div className="p-2">
				<textarea
          className="p-0 m-0 focus:outline-none h-full w-full"
          value={text}
          // onChange={(e) => setValue(e.target.value)}
          onChange={handleText}
          onClick={(e) => e.stopPropagation()}
          style={{
            lineBreak: 'anywhere',
            fontFamily: props.fontFamily,
            fontSize: `${props.fontSize}px`,
            fontWeight: props.fontWeight,
            fontStyle: props.fontStyle,
            textDecoration: props.textDecoration,
            textAlign: props.textAlign,
            minHeight: '100px',
          }}
        />
			</div>
		</Modal>
	);
};

export default TextChangeModal;
