import React, { useContext, useState, useMemo } from "react";
import { nanoid } from "nanoid";
import {ReactComponent as MovieIcon} from "../../icons/movie.svg";
import {ReactComponent as CloseIcon} from "../../icons/x-circle.svg";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { ReactComponent as EditIcon } from "../../icons/pencil.svg";
import { ReactComponent as ChevronDownIcon } from "../../icons/chevron-down.svg";
import DropDownMenu from "../dropDownMenu";
import Button from "../button";
import SaveScreenModal from "../../features/saveSceneModal";
import { useFirebase } from "../../helpers/hooks/useFirebase";
import { useTwilioVideo } from "../../helpers/hooks/useTwilioVideo";
import { useLayerEditorContext } from "../../helpers/hooks/useLayerEditorContext";
import { useCollectionQuery } from "../../helpers/hooks/useFirebaseCollection";
import { useDocumentQuery } from "../../helpers/hooks/useFirebaseDocument";
import InputField from "../forms/inputField";
import StreamBackgroundModal from "../../features/streamBackgroundModal";
import { AuthContext } from "../../helpers/context/authContext";
import { deleteFile, firebaseUpload } from "../../helpers/firebaseUpload";
import DeleteSceneModal from "../../features/deleteSceneModal";
import SaveLayoutModal from "./saveLayoutModal";
import LoadLayoutModal from "./loadLayoutModal";

const Scenes = ({conferenceId}) => {
	const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);

  const confRef = firestore.collection("conferences").doc(conferenceId);
  const scenesRef = confRef.collection("scenes");
	const [isEditOpen, setIsEditOpen] = useState(false);
  const [sceneModalOpen, setSceneModalOpen] = useState(false);
  const [currentScene, setCurrentScene] = useState();
	const [nextId, setNextId] = useState(-1);
  const [openAlertCurrentChange, setOpenAlertCurrentChange] = useState(false);
	const [isRenameModal, setIsRenameModal] = useState(false);
  const [bgModalOpen, setBgModalOpen] = useState(false);
  const [deletingImages, setDeletingImages] = useState([]);
  const [currentBackground ,setCurrentBackground] = useState();
  const [saveLayoutModal, setSaveLayoutModal] = useState(false);
  const [loadLayoutModal, setLoadLayoutModal] = useState(false);
  const [layouts, setLayouts] = useState([]);
	const [
    confirmDeleteSceneModalOpen,
    setConfirmDeleteSceneModalOpen,
  ] = useState(false);
	const [scenes, scenesLoading] = useCollectionQuery(scenesRef, {
    idField: "id",
  });
	const [conference, confLoading] = useDocumentQuery(confRef);
	const activeScene = scenes?.find(({ id }) => id === conference?.activeScene);
  const editingScene = scenes?.find(({ id }) => id === 'editing_scene');

  const { user, hostStatus, setHost } = useContext(AuthContext);
	const [editable, setEditable] = useState(() => {
    if (hostStatus) {
      if (user)
        return hostStatus[user?.uid];
      else return false;
    } return false;
  });

	const { currentState, loadEditingScene, edited, setEdited, layersCollection } = useLayerEditorContext();

  const layersRef = scenesRef?.doc(editable && currentState ? 'editing_scene' : activeScene?.id).collection(layersCollection);

  // const [layers, layersLoading] = useCollectionQuery(layersRef, {
  //   idField: "id",
  // });
	// console.log(activeScene, layersRef, layers);
	const [_, participants] = useTwilioVideo({
    roomName: conferenceId,
    identity: "canvas_rtmp",
    roomParams: {
      video: false,
      audio: false,
    },
  });
	
	const setActiveScene = (id) => {
    if (currentState === 0) {
      confRef.update({
        activeScene: id,
      });
    } else {
      if (!edited) {
        loadEditingScene(scenesRef, id);
        setCurrentScene(scenes?.find((scene) => id === scene.id));
      } else {
        setNextId(id);
        setOpenAlertCurrentChange(true);
      }
    }
  };

	const createNewScene = ({ switchOnCreate = false, name = "" }) => {
    const id = nanoid();

    scenesRef.doc(id).set({
      name,
      layers: [],
      topZIndex: 1,
    });

    participants.map((participant) => {
      scenesRef.doc(id).collection(layersCollection).doc(participant.identity).set({
        id: participant.identity,
        type: "stream",
        x: 0,
        y: 0,
        z: 0,
        height: 250,
        width: 444,
        zone: "participant",
      });
    });
    if (switchOnCreate) {
      setActiveScene(id);
    }

    return id;
  };
	
	const submitNewScene = ({ name }) => {
    createNewScene({ name, switchOnCreate: true });
    setSceneModalOpen(false);
  };

  const saveLayout = async ({ name }) => {
    const snapshot = await scenesRef?.doc(editable && currentState ? 'editing_scene' : activeScene?.id).collection(layersCollection).get();
    let temp = [];
    snapshot.forEach(doc => {
      temp.push(doc.data());
    });
    const id = nanoid();
    const _sceneData = await scenesRef?.doc(editable && currentState ? 'editing_scene' : activeScene?.id).get();
    const background = _sceneData.data().background;
    confRef.collection("savedLayouts").doc(id).set({
      id,
      name,
      background,
      data: temp
    });
    setSaveLayoutModal(false);
  };

  const loadLayout = async ({id}) => {
    console.log(id, layouts);
    const _layout = layouts.find((item) => id === item.id);
    console.log(_layout);
    const snapshot = await scenesRef?.doc(editable && currentState ? 'editing_scene' : activeScene?.id).collection(layersCollection).get();
    let temp = [];
    snapshot.forEach(doc => {
      temp.push(doc.data());
    });
    if (_layout.background) {
      await scenesRef?.doc(editable && currentState ? 'editing_scene' : activeScene?.id).update({
        background: _layout.background
      })
    } else {
      await scenesRef?.doc(editable && currentState ? 'editing_scene' : activeScene?.id).update({
        background: { color: 'white'}
      });
    }
    for (let _data of temp) {
      await scenesRef?.doc(editable && currentState ? 'editing_scene' : activeScene?.id).collection(layersCollection).doc(_data.id).delete();
    }
    for (let _data of _layout.data) {
      scenesRef?.doc(editable && currentState ? 'editing_scene' : activeScene?.id).collection(layersCollection).doc(_data.id).set({
        ..._data
      });
    }
    setLoadLayoutModal(false);
  };

  const handleLoadLayout = async (e) => {
    const _layouts = await confRef.collection("savedLayouts").get();
    let temp = [];
    _layouts.forEach(doc => {
      temp.push(doc.data());
    });
    setLayouts(temp);
    setLoadLayoutModal(true);
  };

	const updateBackground = async ({ color, image }) => {
    let url;

    if (image) {
      const file = image[0];
      try {
        if (activeScene?.background?.image) {
          if (editable && currentState) {
            let temp = JSON.parse(JSON.stringify(deletingImages));
            temp.push(editingScene?.background?.image);
            setDeletingImages(temp);
          } else {
            await deleteFile(firebase)({ name: activeScene?.background?.image });
          }
        }
      } catch (err) {
        console.log(err);
      }
      const val = await firebaseUpload(firebase)({
        name: file.name,
        file,
      });

      url = await val.ref.getDownloadURL();
    }
    setCurrentBackground({
      background: {
        color,
        image: url ?? "",
      },
    });
    scenesRef.doc(editable && currentState ? 'editing_scene' : activeScene?.id).update({
      background: {
        color: color ? color : '',
        image: url ?? "",
      },
    });
    setEdited(true);
    setBgModalOpen(false);
  };
	
	const handleSceneDelete = () => {
    console.log({
      currentState,
      currentScene,
      conference
    })
    // return;
    if (currentState) {
      scenesRef.doc(currentScene.id).delete();
      if (scenes.length > 1) {
        let newScene = scenes.filter((scene) => scene.id != currentScene.id)[0];
        setCurrentScene(newScene);
        loadEditingScene(scenesRef, newScene.id);
      } else setCurrentScene(null);
    } else {
      scenesRef.doc(conference.activeScene).delete();
    }
    setConfirmDeleteSceneModalOpen(false);
  };
	
	const RenameModal = ({isOpen, onClose}) => {
		const [name, setName] = useState(activeScene?.name);

		const handleChangeName = (e) => {
			setName(e.target.value);
		};

		const handleConfirmName = () => {
			if (name && name.length > 0) scenesRef?.doc(activeScene?.id).update({ name });
			onClose();
		};

		if (isOpen) {
			return (
				<div className="absolute right-0 top-20 z-20 custom-bg-2 p-3 rounded-md shadow-md">
					<InputField
						fullWidth
						label="Scene Name: "
            labelStyle="text-gray-100"
						value={name}
						onChange={handleChangeName}
						placeholder="RTMP server url"
					/>
					<div className="w-full pt-2">
						<Button className="float-right bg-gray-500 text-gray-100 hover:bg-gray-400" onClick={handleConfirmName}>OK</Button>
					</div>
					<CloseIcon className="w-5 h-5 absolute right-0 top-0 cursor-pointer hover:text-red-400" onClick={() => onClose()} />
				</div>
			)
		} else {
			return (<></>)
		}
	};

	return (
		<div className="relative flex items-center ml-10">
			<SaveScreenModal
				isOpen={sceneModalOpen}
				onClose={() => setSceneModalOpen(false)}
				onSubmit={submitNewScene}
			/>
      <SaveLayoutModal
        isOpen={saveLayoutModal}
        onClose={() => setSaveLayoutModal(false)}
        onSubmit={saveLayout}
      />
      <LoadLayoutModal
        isOpen={loadLayoutModal}
        onClose={() => setLoadLayoutModal(false)}
        layouts={layouts}
        onSubmit={loadLayout}
      />
			<RenameModal isOpen={isRenameModal} onClose={() => setIsRenameModal(false)} />
			<StreamBackgroundModal
        isOpen={bgModalOpen}
        onClose={() => setBgModalOpen(false)}
        onSubmit={updateBackground}
        initialColor={conference?.background?.color}
      />
			<DeleteSceneModal
				isOpen={confirmDeleteSceneModalOpen}
				onClose={() => setConfirmDeleteSceneModalOpen(false)}
				onSubmit={handleSceneDelete}
				name={activeScene?.name ?? "Name Missing"}
			/>
			{/* <MovieIcon
				className="w-5 h-5 cursor-pointer hover:text-yellow-400"
			/> */}
			<DropDownMenu
				className="flex custom-bg-1 border-none text-gray-100 ml-2 items-center"
        childCls="custom-bg-2 shadow-lg rounded"
        icon="screen"
				text={
					currentState === 0 ?
						(activeScene ? activeScene.name : "None") :
						(currentState === 1 && currentScene ? currentScene.name : "None")
				}
			>
				{scenes?.map((scene) => {
					if (scene.id !== 'editing_scene') {
						return (
							<DropDownMenu.MenuItem
								key={scene.id}
                className="px-2 py-1 text-gray-100 cursor-pointer"
								onClick={() => setActiveScene(scene.id)}
							>
								{scene.name}
							</DropDownMenu.MenuItem>
						)}})}
			</DropDownMenu>
      
      <button
        className="flex custom-bg-1 border-none text-gray-100 ml-2 items-center hover:custom-bg-1 focus:ring-2 focus:ring-yellow-500 text-sm rounded p-2 pr-4 h-8 -mb-1"
        onClick={() => setSceneModalOpen(true)}
      >
        <PlusIcon className="h-4 w-4" /> Add Scene
      </button>
			
			<button
        className="flex custom-bg-1 border-none text-gray-100 ml-2 items-center hover:custom-bg-1 focus:ring-2 focus:ring-yellow-500 text-sm rounded p-2 h-8 -mb-1"
        onClick={() => setIsEditOpen(!isEditOpen)}
      >
				<EditIcon className="h-4 w-4" /> Edit Scene
        <ChevronDownIcon className="h-4 w-4 hover:text-gray-600 ml-px" />
			</button>
			{isEditOpen && <div className="flex gap-2 custom-bg-2 pt-3 pb-3 items-center rounded-lg absolute top-10 right-0 pl-5 pr-5 shadow-md">
				<div className="flex flex-col">
					<button className="mt-2 custom-bg-2 hover:custom-bg-2 border-none text-gray-100" onClick={() => setIsRenameModal(!isRenameModal)}>Rename Scene</button>
					<button className="mt-2 custom-bg-2 hover:custom-bg-2 border-none text-gray-100" onClick={() => setBgModalOpen(true)}>Edit Background</button>
					<button className="mt-2 custom-bg-2 hover:custom-bg-2 border-none text-gray-100" onClick={() => setSaveLayoutModal(!saveLayoutModal)}>Save as Layout</ button>
					<button className="mt-2 custom-bg-2 hover:custom-bg-2 border-none text-gray-100" onClick={handleLoadLayout}>Load Layout</button>
					<button className="mt-2 custom-bg-2 hover:custom-bg-2 border-none text-gray-100" onClick={() => setConfirmDeleteSceneModalOpen(true)}>Delete Scene</button>
				</div>
				<CloseIcon className="w-5 h-5 absolute right-0 top-0 cursor-pointer custom-bg-3 rounded-xl hover:text-red-400" onClick={() => setIsEditOpen(false)} />
			</div>}
		</div>
	)
};

export default Scenes;
