import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useFirebase } from "../helpers/hooks/useFirebase";
import { useDocumentQuery } from "../helpers/hooks/useFirebaseDocument";
import { ReactComponent as CheckedCircleIcon } from "../icons/check-circle.svg";
import { ReactComponent as LockClosedIcon } from "../icons/lock-closed.svg";
import ccLogo from "../images/CC_logo.png";

const RequestToJoin = ({ user, auth }) => {
  const { register, handleSubmit } = useForm();
  const firebase = useFirebase();
  const firestore = useMemo(() => firebase.firestore(), [firebase]);
  const { conferenceId } = useParams();

  const confRef = firestore.collection("conferences").doc(conferenceId);

  const [confData, confDataLoading, confDataError] = useDocumentQuery(confRef);

  const participantsRef = confRef.collection("participants");

  const [partData] = useDocumentQuery(participantsRef.doc(user?.uid));

  const [submitted, setSubmitted] = useState(false);

  const onRequest = async ({ name = "" }) => {
    setSubmitted(true);
    console.log('hello0', name);
    if ((!partData || partData?.actionTime === 0) && name !== '') {
      console.log('hello1', name);
      user?.updateProfile({ displayName: name });

      // FIXME: this needs to be moved to a cloud function with security
      //  constraints
      const ifExists = await participantsRef.doc(user?.uid).get();
      console.log('hello2', name);
      if (ifExists.exists) {
        participantsRef.doc(user?.uid).update({
          name,
          created: new Date(),
          canJoin: false,
          deny: false,
          actionTime: new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000,
        });
      } else {
        participantsRef.doc(user?.uid).set({
          name,
          created: new Date(),
          canJoin: false,
          deny: false,
          actionTime: new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000,
        });
      }
      
    }
  };

  if (confDataLoading) return null;

  if (confDataError) {
    console.log(confDataError);
  }

  if (confData?.locked || partData?.deny) {
    return (
      <div className="geo-bg h-full w-full flex flex-col items-center justify-center">
        <Helmet>
          <title>Room Locked</title>
        </Helmet>
        <div className="bg-white rounded-lg shadow-md p-2 w-96 flex flex-col justify-center items-center gap-2">
          <LockClosedIcon className="w-24 h-24 text-red-600 mr-4" />
          <h2 className="text-xl font-medium">This conference is locked.</h2>
        </div>
      </div>
    );
  }

  if (submitted || (partData?.canJoin === false && partData?.actionTime > 0)) {
    return (
      <div className="geo-bg h-full w-full flex flex-col items-center justify-center">
        <Helmet>
          <title>Request to Join</title>
        </Helmet>
        <div className="bg-white rounded-lg shadow-md p-2 w-96 flex flex-col justify-center items-center gap-2">
          <div className="bg-gray-50 rounded-lg w-full flex flex-col justify-center items-center mb-4 p-2">
            <CheckedCircleIcon className="w-24 h-24 text-gray-700 mr-4" />
            <h2 className="text-xl font-medium">Request Submitted</h2>
          </div>
          <p>Waiting for host to accept...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="geo-bg h-full w-full flex flex-col items-center justify-center">
      <Helmet>
        <title>Request to Join</title>
      </Helmet>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-32 w-auto"
          src={ccLogo}
          alt="Conference Cloud Logo"
        ></img>
      </div>
      <div className="bg-white rounded-lg shadow-md p-2 w-96">
        <h2 className="mb-6 text-center text-3xl font-extrabold text-gray-900">
          Request to Join
        </h2>
        {!user?.displayName || user?.displayName === '' ? (
          <form onSubmit={handleSubmit(onRequest)}>
            <div>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  What's your name?
                </label>
                <div className="mt-1">
                  <input
                    ref={register}
                    id="name"
                    name="name"
                    type="text"
                    autoComplete="user-name"
                    placeholder="Type your name here..."
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              Request Access
            </button>
          </form>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <p className="mb-4 text-lg text-gray-700">
              Hi, {user?.displayName}.
              <span
                className="text-sm text-blue-400 ml-2 cursor-pointer hover:text-blue-500"
                onClick={() => auth.signOut()}
              >
                (Not you?)
              </span>
            </p>
            <button
              type="submit"
              onClick={() => onRequest({name: user?.displayName})}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              Request Access
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestToJoin;
