import React from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "../components/button";
import Modal from "../components/modal";
import InputField from "../components/forms/inputField";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const CreateConferenceModal = ({
  isOpen,
  onClose,
  onSubmit,
  title = "Create Conference",
  name = "",
  rtmpUrl = "",
  scheduledDate,
}) => {
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      name,
      // rtmpUrl,
      // scheduledDate: scheduledDate ? scheduledDate : new Date(),
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-1/4">
      <div className="custom-bg-1 w-full p-2 rounded-t-md flex items-center">
        <h2 className="font-medium text-xl text-gray-100">{title}</h2>
      </div>
      <div className="p-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-2 p-4"
        >
          <InputField
            id="name"
            label="Name"
            className="w-full"
            labelStyle="text-gray-100"
            ref={register({ required: true })}
            required
          />
          {/* <InputField
            id="rtmpUrl"
            label="RTMP URL"
            ref={register({ required: true })}
            required
          /> */}
          {/* <div>
            <div className="text-sm font-medium text-gray-700 mb-2">
              Scheduled Date
            </div>
            <Controller
              as={
                <Datetime
                  minDate={new Date()}
                  inputProps={{
                    className:
                      "appearance-none block w-full px-3 h-8 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm",
                  }}
                />
              }
              name="scheduledDate"
              control={control}
              register={register({ required: true })}
              onChange={(val) => val}
            />
          </div> */}
          <div className="flex flex-row-reverse mt-4">
            <Button className="rounded bg-gray-500 text-gray-100" type="submit">Submit</Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateConferenceModal;
