import React from 'react';
import { createContext } from "react";
import { useFirebaseInit } from "../hooks/useFirebaseInit";

export const FirebaseContext = createContext({ fireInstance: undefined });
FirebaseContext.displayName = "Firebase Context";

const FirebaseProvider = ({ children, config }) => {
  const [fireInstance, loading] = useFirebaseInit(config);

  return (
    <FirebaseContext.Provider value={{ fireInstance }}>
      {!loading && children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
